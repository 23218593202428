/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import path from 'path';
import '@marriott/global-styles/dist/marriot.global.css';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';
import '../styles/common.scss';
import { canUseDOM } from '@marriott/mi-ui-library';

import { defineComponentMapping } from '../import-components';
//import { useSessionDataStore } from '@marriott/mi-homepage-components-store';

import { useClientEnvVarsStore, usePersistentGlobalStore } from '@marriott/mi-store-utils';

import { getProcessEnvs, CustomModelClient, UserSessionProvider } from '@marriott/mi-headless-utils';

path.resolve('./next.config.js');
declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);
if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  console.log('App.tsx props', props);
  const { Component, pageProps } = props;
  const { resolvedUrl, model, isAuthorMode, apolloEnvVars } = pageProps;
  //const { setSessionData, setHeadersData, setEnvVariablesData } = useSessionDataStore();
  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;
  // useEffect(() => {
  //   setSessionData(pageProps.sessionData);
  //   setHeadersData(pageProps.headersData);
  //   setEnvVariablesData({
  //     submitSearchUrl: process.env.NEXT_PUBLIC_SUBMIT_SEARCH_URL,
  //     availabilitySearchUrl: process.env.NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
  //     googleMapApiKey: process.env.GOOGLE_MAP_API_KEY,
  //   });
  // }, []);
  useEffect(() => {
    updateGlobalData('sessionData', pageProps?.sessionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);
  // const { setEnvVars, envVarsObject } = useClientEnvVarsStore();
  const {
    NEXT_PUBLIC_UAT_AUTH_TOKEN,
    APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW,
    SESSION_GET_CALL_URL_CLIENT,
    GOOGLE_MAP_API_KEY,
  } = getProcessEnvs();
  const envVars = apolloEnvVars ? apolloEnvVars : {};
  envVars['NEXT_PUBLIC_SUBMIT_SEARCH_URL'] = process.env['NEXT_PUBLIC_SUBMIT_SEARCH_URL'];
  envVars['NEXT_PUBLIC_AVAILABILITY_SEARCH_URL'] = process.env['NEXT_PUBLIC_AVAILABILITY_SEARCH_URL'];
  //Any product specific page data apart from common data like session, datalayer
  //can be returned from this function and the same is passed as props to the page Component
  const serverEnv = pageProps?.serverENV ? JSON.parse(pageProps.serverENV) : {};
  console.log('new env', process.env['GOOGLE_MAP_API_KEY'], process.env.GOOGLE_MAP_API_KEY);
  envVars['NEXT_PUBLIC_UAT_AUTH_TOKEN'] = NEXT_PUBLIC_UAT_AUTH_TOKEN
    ? NEXT_PUBLIC_UAT_AUTH_TOKEN
    : serverEnv['NEXT_PUBLIC_UAT_AUTH_TOKEN'];
  envVars['APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW'] = APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW
    ? APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW
    : serverEnv['APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW'];
  envVars['SESSION_GET_CALL_URL_CLIENT'] = SESSION_GET_CALL_URL_CLIENT ?? process.env['SESSION_GET_CALL_URL_CLIENT'];
  envVars['GOOGLE_MAP_API_KEY'] = process.env.GOOGLE_MAP_API_KEY;
  //if (!Object.keys(envVarsObject).length) {
  setEnvVars(envVars);
  // }
  defineComponentMapping(allowedComponents, resolvedUrl, isAuthorMode, pageProps);
  return (
    <UserSessionProvider pageProps={pageProps}>
      <Component {...pageProps} />
    </UserSessionProvider>
  );
};

export default App;
