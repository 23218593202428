/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BrandFilter } from '@marriott/mi-shop-components';
// import { BrandFilter } from 'libs/mi-rnb-components/src/organisms/SearchResults/BrandFilter';
import { StyledSearchAllFilters } from './PropertiesListFilter.styles';
import clsx from 'clsx';
import { Button, CheckboxControlsList, Modal, RadiobuttonControlsList } from '@marriott/mi-ui-library';
import { useEffect, useRef, useState } from 'react';
import {
  BrandProps,
  CheckboxControlsListProps,
  ControlsProps,
  PropertiesFilterProps,
  availableBrandsType,
  brandCategoriesDataType,
  brandCategoryTitleMappingType,
  brandFilterProps,
} from './PropertiesListFilter.types';
import { INTERNAL_CTA_TYPE } from 'libs/mi-offers-components/src/constants/CommonConstants';
import { ShowAvailabilityFilter } from './availabilityFilters/availabilityFilter';
import { usePersistentGlobalStore } from '@marriott/mi-store-utils';

export const PropertiesListFilter: React.FC<PropertiesFilterProps> = props => {
  const model = props?.model;
  const { isStateEnabled, isCountryEnabled } = props?.destinationMetaData ?? {};
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const [isNewFacetsLoading, setIsNewFacetsLoading] = useState<boolean>(props?.isNewFacetsLoading);
  const [filterCategoryModal, setFilterCategoryModal] = useState<string>('All Filters');
  const [filterCategoryHeader, setFilterCategoryHeader] = useState<string>(model?.allFilters ?? '');
  //brand
  const [selectedBrands, setSelectedBrands] = useState<BrandProps[]>([]);
  // const [prevSelectedBrands, setPrevSelectedBrands] = useState<BrandProps[]>([]);
  //amenities
  const [selectedAmenities, setSelectedAmenities] = useState<ControlsProps[]>(props?.selectedAmenities);
  // const [prevSelectedAmenities, setPrevSelectedAmenities] = useState<ControlsProps[]>([]);
  //transportation
  const [selectedTransportaion, setSelectedTransportaion] = useState<ControlsProps[]>([]);
  // const [prevSelectedTransportaion, setPrevSelectedTransportaion] = useState<ControlsProps[]>([]);
  // hotel type
  const [selectedHotelType, setSelectedHotelType] = useState<ControlsProps[]>(props?.selectedHotelType);
  // const [prevSelectedHotelType, setPrevSelectedHotelType] = useState<ControlsProps[]>([]);
  //events
  const [selectedEvents, setSelectedEvents] = useState<ControlsProps[]>([]);
  // const [prevSelectedEvents, setPrevSelectedEvents] = useState<ControlsProps[]>([]);
  //activities
  const [selectedActivities, setSelectedActivities] = useState<ControlsProps[]>(props?.selectedActivities);
  // const [prevSelectedActivities, setPrevSelectedActivities] = useState<ControlsProps[]>([]);
  //prices
  const [selectedPrices, setSelectedPrices] = useState<ControlsProps[]>([
    {
      label: model?.anyPriceLabel,
      disabled: false,
    },
  ]);
  // const [prevSelectedPrices, setPrevSelectedPrices] = useState<ControlsProps[]>([]);
  //city
  const [selectedCity, setSelectedCity] = useState<ControlsProps[]>([]);
  // const [prevSelectedCity, setPrevSelectedCity] = useState<ControlsProps[]>([]);
  //state
  const [selectedState, setSelectedState] = useState<ControlsProps[]>([]);
  // const [prevSelectedState, setPrevSelectedState] = useState<ControlsProps[]>([]);
  //availability
  const [includeUnavailableProperties, setIncludeUnavailableProperties] = useState<boolean>(
    props?.includeUnavailableProperties
  );

  const globalData = usePersistentGlobalStore((state: any) => state.globalData);
  const ariesOffer = globalData?.sessionData?.cacheData?.data?.AriesOffer;

  const [isCategoryFilterModal, setIsCategoryFilterModal] = useState<boolean>(false);
  const isFilterSelected = props?.brandFacets.concat(
    props?.amentiesFacets,
    props?.transportaionFacets,
    props?.hotelTypeFacets,
    props?.eventsFacets,
    props?.activitiesFacets,
    props?.priceDimension,
    props?.cityFacets,
    props?.stateFacets
  );
  if (!includeUnavailableProperties) {
    isFilterSelected?.concat(['includeUnavailablePropertiesFilter']);
  }
  const brandCategoriesData: brandCategoriesDataType = [];

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const containerRef: any = useRef(null);

  const handleMouseDown = (e: any) => {
    setIsDragging(true);

    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e: any) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const distance = x - startX;
    containerRef.current.scrollLeft = scrollLeft - distance;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    setSelectedBrands(props?.selectedBrands);
    setSelectedAmenities(props?.selectedAmenities);
    setSelectedTransportaion(props?.selectedTransportaion);
    setSelectedHotelType(props?.selectedHotelType);
    setSelectedEvents(props?.selectedEvents);
    setSelectedActivities(props?.selectedActivities);
    setSelectedPrices(props?.selectedPrices);
    setSelectedCity(props?.selectedCity);
    setSelectedState(props?.selectedState);
    setIncludeUnavailableProperties(props?.includeUnavailableProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.selectedBrands?.length,
    props?.selectedAmenities?.length,
    props?.selectedTransportaion?.length,
    props?.selectedHotelType?.length,
    props?.selectedEvents?.length,
    props?.selectedActivities?.length,
    props?.selectedPrices?.length,
    props?.selectedCity?.length,
    props?.selectedState?.length,
    props?.includeUnavailableProperties,
  ]);

  useEffect(() => {
    setIsNewFacetsLoading(props?.isNewFacetsLoading);
  }, [props?.isNewFacetsLoading]);

  const getBrandCategorySelectAllLabel = (category: string): string => {
    switch (category) {
      case 'luxuryBrands':
        return 'storefrontPropertySelectAllLuxuryBrands';

      case 'premiumBrands':
        return 'storefrontPropertySelectAllPremiumBrands';

      case 'selectBrands':
        return 'storefrontPropertySelectAllSelectBrands';

      case 'longerStayBrands':
        return 'storefrontPropertySelectAllLongerStaysBrands';

      default:
        return '';
    }
  };
  const brandCategoryTitleMapping: brandCategoryTitleMappingType = {
    luxuryBrands: model?.storefrontPropertyBrandFilterLuxury,
    premiumBrands: model?.storefrontPropertyBrandFilterPremium,
    selectBrands: model?.storefrontPropertyBrandFilterSelect,
    longerStayBrands: model?.storefrontPropertyBrandFilterLongerStay,
  };
  const authoredBrandsList: any = model?.brands;
  const getBrandCategoriesData = (brandCategory: string) => {
    const brandsList = authoredBrandsList?.[brandCategory].map((brand: { code: string; title: string }) => {
      const isDisabled = props?.availableBrands?.some((item: availableBrandsType) => {
        return item.code === brand.code;
      });
      return {
        code: brand?.code,
        brandTagTitle: brand?.title,
        description: '',
        brandTagId: brand?.code,
        isDisabled: !isDisabled,
      };
    });
    const key = brandCategory as keyof typeof brandCategoryTitleMapping; // to allow indexing on object
    const category = {
      categoryTitle: `${brandCategoryTitleMapping[key]} ${model?.storefrontPropertyBrandFilter}`,
      categorySelectLabel: model?.[getBrandCategorySelectAllLabel(key)],
      categoryClearLabel: `${model?.storefrontPropertyFilterClear} ${brandCategoryTitleMapping[key]} ${model?.storefrontPropertyBrandFilter}`,
      brands: brandsList,
    };
    brandCategoriesData.push(category);
  };
  getBrandCategoriesData('luxuryBrands');
  getBrandCategoriesData('premiumBrands');
  getBrandCategoriesData('selectBrands');
  getBrandCategoriesData('longerStayBrands');

  const handleClearBtn = () => {
    setIsNewFacetsLoading(true);
    setSelectedBrands([]);
    setSelectedAmenities([]);
    setSelectedTransportaion([]);
    setSelectedHotelType([]);
    setSelectedEvents([]);
    setSelectedActivities([]);
    setSelectedCity([]);
    setSelectedState([]);
    setSelectedPrices([
      {
        label: model?.anyPriceLabel,
        disabled: false,
      },
    ]);
    setIncludeUnavailableProperties(true);
    props.handleDynamicBrands([]);
    props?.handleDynamicAmenities([]);
    props?.handleDynamicTransportaion([]);
    props?.handleDynamicHotelTypes([]);
    props?.handleDynamicActivities([]);
    props?.handleDynamicEvents([]);
    props?.handleDynamicPrices([
      {
        label: model?.anyPriceLabel,
        disabled: false,
      },
    ]);
    props?.handleDynamicCities([]);
    props?.handleDynamicStates([]);
    props?.handleDynamicIncludeUnavailableProperties(true);
    props?.handleClearAllFilters();
  };
  const getControlsData = (currentFilterData: any, filterName?: string) => {
    if (filterName == 'price') {
      const desiredKeys = ['count', 'end', 'start'];
      const updatedPriceOptions = currentFilterData?.map((obj: any) => {
        const newPriceObj: any = {};
        desiredKeys?.forEach(key => {
          newPriceObj[key] = obj[key];
        });
        return newPriceObj;
      });
      const modifiedPriceOptions = updatedPriceOptions?.map((item: any) => ({
        count: item.count,
        label:
          item?.end == 'overflow'
            ? `${parseFloat(item.start)}+ USD`
            : `${parseFloat(item.start)} - ${parseFloat(item.end)} USD`,
      }));
      modifiedPriceOptions?.unshift({
        label: model?.anyPriceLabel,
      });
      return modifiedPriceOptions;
    } else {
      const desiredKeys = ['code', 'count', 'label', 'description'];
      const updatedFilterOptions = currentFilterData?.map((obj: any) => {
        const newFilterOptionsObj: any = {};
        desiredKeys?.forEach(key => {
          newFilterOptionsObj[key] = obj[key];
        });
        const modifiedLabel = obj?.label ?? obj?.description ?? obj?.code;
        return { ...newFilterOptionsObj, label: modifiedLabel };
      });
      return updatedFilterOptions;
    }
  };

  const handleBrandFilter = (selectedBrandAr: any) => {
    setIsNewFacetsLoading(true);
    setSelectedBrands(selectedBrandAr);
    // setPrevSelectedBrands(selectedBrandAr);
    props?.handleDynamicBrands(selectedBrandAr);
  };
  //props received by BrandFilter
  const BrandFilterProps: brandFilterProps = {
    title: `${model?.storefrontPropertyBrandFilter}`,
    brandCategories: brandCategoriesData,
    showSeparator: false,
    // defaultSelected includes all those brands which are selected and highlited for filtering the properties.
    defaultSelected: selectedBrands,
    onChange: handleBrandFilter,
    isHotelBrandSelectedFlag: true,
  };
  //handle amenities filter
  const handleAmenitiesChange = (selectedAmenitiesAr: any) => {
    setIsNewFacetsLoading(true);
    setSelectedAmenities(selectedAmenitiesAr);
    // setPrevSelectedAmenities(selectedAmenitiesAr);
    props?.handleDynamicAmenities(selectedAmenitiesAr);
  };
  const AmenitiesFilterProps: CheckboxControlsListProps = {
    title: `${model?.amenitiesLabel}`,
    controls: getControlsData(props?.availabledAmenities),
    enableShowMoreToggle: true,
    defaultSelected: selectedAmenities,
    onChange: handleAmenitiesChange, //array of changed value
    showMoreCount: 8,
    showMoreLabel: `${model?.showMoreLabel ?? 'Show More'}`,
    showLessLabel: `${model?.showLessLabel ?? 'Show Less'}`,
    name: 'Amenities',
    showSeparator: true,
  };
  //handle transportaion filter
  const handleTransportationChange = (selectedTransportationAr: any) => {
    setIsNewFacetsLoading(true);
    setSelectedTransportaion(selectedTransportationAr);
    // setPrevSelectedTransportaion(selectedTransportationAr);
    props?.handleDynamicTransportaion(selectedTransportationAr);
  };
  const TransportationFilterProps: CheckboxControlsListProps = {
    title: `${model?.transportationLabel}`,
    controls: getControlsData(props?.availableTransportaion),
    enableShowMoreToggle: true,
    defaultSelected: selectedTransportaion,
    onChange: handleTransportationChange, //array of changed value
    showMoreCount: 8,
    showMoreLabel: `${model?.showMoreLabel ?? 'Show More'}`,
    showLessLabel: `${model?.showLessLabel ?? 'Show Less'}`,
    name: 'Transportation',
    showSeparator: true,
  };
  //handle hotel type filter
  const handleHotelTypeChange = (selectedHotelTypeAr: any) => {
    setIsNewFacetsLoading(true);
    setSelectedHotelType(selectedHotelTypeAr);
    // setPrevSelectedHotelType(selectedHotelTypeAr);
    props?.handleDynamicHotelTypes(selectedHotelTypeAr);
  };
  const HotelTypeFilterProps: CheckboxControlsListProps = {
    title: `${model?.hotelTypeSearchLabel}`,
    controls: getControlsData(props?.availableHotelType),
    enableShowMoreToggle: true,
    defaultSelected: selectedHotelType,
    onChange: handleHotelTypeChange, //array of changed value
    showMoreCount: 8,
    showMoreLabel: `${model?.showMoreLabel ?? 'Show More'}`,
    showLessLabel: `${model?.showLessLabel ?? 'Show Less'}`,
    name: 'HotelType',
    showSeparator: true,
  };
  //handle events filter
  const handleEventsChange = (selectedEventsAr: any) => {
    setIsNewFacetsLoading(true);
    setSelectedEvents(selectedEventsAr);
    // setPrevSelectedEvents(selectedEventsAr);
    props?.handleDynamicEvents(selectedEventsAr);
  };
  const EventsFilterProps: CheckboxControlsListProps = {
    title: `${model?.eventsLabel}`,
    controls: getControlsData(props?.availablEvents),
    enableShowMoreToggle: true,
    defaultSelected: selectedEvents,
    onChange: handleEventsChange, //array of changed value
    showMoreCount: 8,
    showMoreLabel: `${model?.showMoreLabel ?? 'Show More'}`,
    showLessLabel: `${model?.showLessLabel ?? 'Show Less'}`,
    name: 'Events',
    showSeparator: true,
  };
  //handle activities filter
  const handleActivitiesChange = (selectedActivitiesAr: any) => {
    setIsNewFacetsLoading(true);
    setSelectedActivities(selectedActivitiesAr);
    // setPrevSelectedActivities(selectedActivitiesAr);
    props?.handleDynamicActivities(selectedActivitiesAr);
  };
  const ActivitiesFilterProps: CheckboxControlsListProps = {
    title: `${model?.activitiesLabel}`,
    controls: getControlsData(props?.availablActivities),
    enableShowMoreToggle: true,
    defaultSelected: selectedActivities,
    onChange: handleActivitiesChange, //array of changed value
    showMoreCount: 8,
    showMoreLabel: `${model?.showMoreLabel ?? 'Show More'}`,
    showLessLabel: `${model?.showLessLabel ?? 'Show Less'}`,
    name: 'Activities',
    showSeparator: true,
  };
  //handle price filter
  const handlePricesChange = (selectedPrice: any) => {
    setIsNewFacetsLoading(true);
    setSelectedPrices(selectedPrice);
    // setPrevSelectedPrices(selectedPrice);
    props?.handleDynamicPrices(selectedPrice);
  };
  const PriceFilterProps: any = {
    title: `${model?.priceLabel}`,
    controls: getControlsData(props?.availablPrices, 'price'),
    enableShowMoreToggle: false,
    defaultSelected: selectedPrices,
    onChange: handlePricesChange,
    name: 'price',
    showSeparator: true,
  };
  //handle city filter
  const handleCityChange = (selectedCitiesAr: any) => {
    setIsNewFacetsLoading(true);
    setSelectedCity(selectedCitiesAr);
    // setPrevSelectedCity(selectedCitiesAr);
    props?.handleDynamicCities(selectedCitiesAr);
  };
  const CityFilterProps: CheckboxControlsListProps = {
    title: `${model?.cityLabel}`,
    controls: getControlsData(props?.availableCity, 'city'),
    enableShowMoreToggle: true,
    defaultSelected: selectedCity,
    onChange: handleCityChange, //array of changed value
    showMoreCount: 8,
    showMoreLabel: `${model?.showMoreLabel ?? 'Show More'}`,
    showLessLabel: `${model?.showLessLabel ?? 'Show Less'}`,
    name: 'filterCity',
    showSeparator: true,
  };
  //handle state filter
  const handleStateChange = (selectedStateAr: any) => {
    setIsNewFacetsLoading(true);
    setSelectedState(selectedStateAr);
    // setPrevSelectedState(selectedStateAr);
    props?.handleDynamicStates(selectedStateAr);
  };
  const StateFilterProps: CheckboxControlsListProps = {
    title: `${model?.stateLabel ?? 'State'}`,
    controls: getControlsData(props?.availablState),
    enableShowMoreToggle: true,
    defaultSelected: selectedState,
    onChange: handleStateChange, //array of changed value
    showMoreCount: 8,
    showMoreLabel: `${model?.showMoreLabel ?? 'Show More'}`,
    showLessLabel: `${model?.showLessLabel ?? 'Show Less'}`,
    name: 'filterState',
    showSeparator: true,
  };
  //handle availability
  const handleAvailabilityChange = (val: boolean) => {
    setIsNewFacetsLoading(true);
    setIncludeUnavailableProperties(!val);
    // setIncludeUnavailablePropertiesPrevState(val);
    props?.handleDynamicIncludeUnavailableProperties(!val);
  };
  const toggleModal = () => {
    setPopupOpenState(!popupOpenState);
    props?.handleDynamicBrands(ariesOffer?.['selectedBrands'] ?? []);
    props?.handleDynamicAmenities(ariesOffer?.['selectedAmenities'] ?? []);
    props?.handleDynamicTransportaion(ariesOffer?.['selectedTransportaion'] ?? []);
    props?.handleDynamicHotelTypes(ariesOffer?.['selectedHotelType'] ?? []);
    props?.handleDynamicEvents(ariesOffer?.['selectedEvents'] ?? []);
    props?.handleDynamicActivities(ariesOffer?.['selectedActivities'] ?? []);
    props?.handleDynamicPrices(
      ariesOffer?.['selectedPrices']?.length
        ? ariesOffer?.['selectedPrices']
        : [
            {
              label: `${model?.anyPriceLabel}`,
              disabled: false,
            },
          ]
    );
    props?.handleDynamicCities(ariesOffer?.['selectedCity'] ?? []);
    props?.handleDynamicStates(ariesOffer?.['selectedState'] ?? []);
    props?.handleDynamicIncludeUnavailableProperties(ariesOffer?.['includeUnavailableProperties'] ?? true);
    setSelectedCity(ariesOffer?.['selectedCity'] ?? []);
    setSelectedBrands(ariesOffer?.['selectedBrands'] ?? []);
    setSelectedAmenities(ariesOffer?.['selectedAmenities'] ?? []);
    setSelectedTransportaion(ariesOffer?.['selectedTransportaion'] ?? []);
    setSelectedHotelType(ariesOffer?.['selectedHotelType'] ?? []);
    setSelectedEvents(ariesOffer?.['selectedEvents'] ?? []);
    setSelectedActivities(ariesOffer?.['selectedActivities'] ?? []);
    setSelectedPrices(
      ariesOffer?.['selectedPrices']?.length
        ? ariesOffer?.['selectedPrices']
        : [
            {
              label: `${model?.anyPriceLabel}`,
              disabled: false,
            },
          ]
    );
    setSelectedState(ariesOffer?.['selectedState'] ?? []);
    setIncludeUnavailableProperties(
      ariesOffer?.['includeUnavailableProperties'] === true
        ? true
        : ariesOffer?.['includeUnavailableProperties'] === false
        ? false
        : true
    );
  };
  const filterLabel = {
    availabilityLabel: model?.availabilityLabel,
    showAvailableHotelsOnlyLabel: model?.showAvailableHotelsOnlyLabel,
  };
  const getModalData = (): any => {
    if (filterCategoryModal === 'All Filters') {
      return (
        <>
          <ShowAvailabilityFilter
            handleAvailabilityChange={handleAvailabilityChange}
            isSliderActive={!includeUnavailableProperties}
            labels={filterLabel}
          />
          {PriceFilterProps.controls?.length > 0 ? <RadiobuttonControlsList {...PriceFilterProps} /> : null}
          {AmenitiesFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...AmenitiesFilterProps} /> : null}
          <BrandFilter {...BrandFilterProps} />

          {TransportationFilterProps?.controls?.length > 0 ? (
            <CheckboxControlsList {...TransportationFilterProps} />
          ) : null}
          {HotelTypeFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...HotelTypeFilterProps} /> : null}
          {EventsFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...EventsFilterProps} /> : null}
          {ActivitiesFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...ActivitiesFilterProps} /> : null}
          {isCountryEnabled && StateFilterProps.controls?.length > 0 ? (
            <CheckboxControlsList {...StateFilterProps} />
          ) : null}
          {(isCountryEnabled || isStateEnabled) && CityFilterProps.controls?.length > 0 ? (
            <CheckboxControlsList {...CityFilterProps} />
          ) : null}
        </>
      );
    } else if (filterCategoryModal === 'Price') {
      return <>{PriceFilterProps.controls?.length > 0 ? <RadiobuttonControlsList {...PriceFilterProps} /> : null}</>;
    } else if (filterCategoryModal === 'Amenities') {
      return (
        <>{AmenitiesFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...AmenitiesFilterProps} /> : null}</>
      );
    } else if (filterCategoryModal === 'Brands') {
      return <BrandFilter {...BrandFilterProps} />;
    }
  };

  return (
    <StyledSearchAllFilters className="offer-filters">
      <div
        className="filter-btns-wrapper"
        id="filter-btns-wrapper"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <Button
          callback={() => {
            setFilterCategoryModal('All Filters');
            setFilterCategoryHeader(model?.allFilters);
            toggleModal();
          }}
          className={clsx(
            `button custom_click_track button--medium button-secondary m-button-primary-inverse t-label-alt-m filter-option-button ${
              isFilterSelected?.length && 'active'
            }`
          )}
          custom_click_track_value={`${model?.id}|${model?.storefrontPropertyBrandFilter}-${model?.storefrontPropertyFilters}|${INTERNAL_CTA_TYPE}`}
          trackingProperties={model?.trackingProperties}
        >
          <span className={`icon-s ${isFilterSelected?.length ? ' all-filter-badge' : 'icon-dining-filter'}`}>
            {' '}
            {`${isFilterSelected?.length ? isFilterSelected?.length : ''}`}
          </span>
          <span className="all-filter-text"> {model?.allFilters}</span>
        </Button>
        <Button
          callback={(): void => {
            setFilterCategoryModal('Price');
            setFilterCategoryHeader(model?.priceLabel);
            toggleModal();
          }}
          className={clsx(
            'button custom_click_track button--medium button-secondary m-button-primary-inverse t-label-alt-m filter-option-button',
            props?.priceDimension?.length ? 'active' : ''
          )}
          custom_click_track_value={`${model?.id}|${model?.storefrontPropertyBrandFilter}-${model?.storefrontPropertyFilters}|${INTERNAL_CTA_TYPE}`}
        >
          {model?.priceLabel}
        </Button>
        <Button
          callback={(): void => {
            setFilterCategoryModal('Amenities');
            setFilterCategoryHeader(model?.amenitiesLabel);
            toggleModal();
          }}
          className={clsx(
            'button custom_click_track button--medium button-secondary m-button-primary-inverse t-label-alt-m filter-option-button',
            // props?.selectedAmenities?.length > 0 ? 'active' : ''
            props?.amentiesFacets?.length > 0 ? 'active' : ''
          )}
          custom_click_track_value={`${model?.id}|${model?.storefrontPropertyBrandFilter}-${model?.storefrontPropertyFilters}|${INTERNAL_CTA_TYPE}`}
        >
          {model?.amenitiesLabel}
        </Button>
        <Button
          callback={(): void => {
            setFilterCategoryModal('Brands');
            setFilterCategoryHeader(model?.storefrontPropertyBrandFilter);
            toggleModal();
          }}
          className={clsx(
            'button custom_click_track button--medium button-secondary m-button-primary-inverse t-label-alt-m filter-option-button',
            props?.selectedBrands?.length > 0 ? 'active' : ''
          )}
          custom_click_track_value={`${model?.id}|${model?.storefrontPropertyBrandFilter}-${model?.storefrontPropertyFilters}|${INTERNAL_CTA_TYPE}`}
        >
          {model?.storefrontPropertyBrandFilter}
        </Button>
      </div>
      {popupOpenState && (
        <Modal
          className={`custom-scrollbar modal-main-container ${popupOpenState && 'close-popup-header'} ${
            isCategoryFilterModal && 'category-filter-modal'
          }`}
          show={true}
          handleBlur={true}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          disableScrollOnBody={true}
          allFilterPopUp={true}
          onClose={(): void => {
            setPopupOpenState(false);
          }}
        >
          {isNewFacetsLoading && (
            <div
              className="loader-container d-flex align-items-center justify-content-center"
              data-testid="facets-spinner"
            >
              <div className="m-spinner-m"></div>
            </div>
          )}
          <Modal.Header
            className="modal-header t-subtitle-xl"
            labelText={filterCategoryHeader}
            popupHeaderOnCLoseFunc={(): void => {
              setIsCategoryFilterModal(false);
              setPopupOpenState(false);
            }}
            closeIconClickTrackValue={`${model?.id}|${model?.close ?? 'CloseModal'}|${INTERNAL_CTA_TYPE}`}
          />
          <Modal.Body className="all-filters custom-scrollbar">
            <div>{getModalData()}</div>
          </Modal.Body>
          <Modal.Footer
            clearClickHandler={handleClearBtn}
            setPopUpOpenState={setPopupOpenState}
            applyBtnClickHandler={() => {
              props?.handleApplyFilter(
                selectedBrands,
                selectedAmenities,
                selectedTransportaion,
                selectedHotelType,
                selectedEvents,
                selectedActivities,
                selectedPrices,
                selectedCity,
                selectedState,
                includeUnavailableProperties
              );
            }}
            clearButtonLabel={model?.clearFilters}
            applyLabel={model?.storefrontPropertyFilterApply}
            className="modal-footer"
            clickTrackingLoc={'SEARCH_FILTER_MODAL'}
            applyBtnClickTrackValue={`${model?.id}|${model?.storefrontPropertyFilterApply}|${INTERNAL_CTA_TYPE}`}
            clearBtnClickTrackValue={`${model?.id}|${model?.clearFilters}|${INTERNAL_CTA_TYPE}`}
          />
        </Modal>
      )}
    </StyledSearchAllFilters>
  );
};
