import React, { FC, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';

// import { Types, Button, Heading, Image, Icon } from '../../index';
import { Button } from '../../atoms/Button';
import { Heading } from '../../atoms/Heading';
import { Image } from '../../atoms/Image';
import { Icon } from '../../atoms/Icon';
import { Eyebrow } from '../../atoms/Eyebrow';
import { headingType, tags, size, ImageAspectRatio } from '../../utils/enums/enums';
import { EyebrowEnum } from '../../atoms/Eyebrow/Eyebrow.types';

import { HeroProps } from './Hero.types';
import { StyledHero } from './Hero.styles';
import { useDocumentDirection } from '../../hooks/useDocumentDirection';

export const Hero: FC<HeroProps> = ({
  variation,
  eyebrowType,
  assetType,
  eyebrowText,
  iconFontPath,
  iconAltText,
  header,
  headerTag = tags.h2,
  description,
  primaryCtaText,
  primaryCtaLink,
  primaryCtaOpenInNewTab = true,
  secondaryCtaText,
  secondaryCtaLink,
  secondaryCtaOpenInNewTab = true,
  iconText,
  iconPath,
  linkUrl,
  loadingType = 'lazy',
  styleclass,
  trackingProperties,
  dynamicMedia,
  dynamicMediaLogo,
  ...props
}) => {
  const { SIZE_16x9, SIZE_5x2, SIZE_3x1 } = ImageAspectRatio;
  const hasPrimaryButton = primaryCtaText && primaryCtaLink && primaryCtaText.length > 0 && primaryCtaLink.length > 0;
  const hasSecondaryButton =
    secondaryCtaText && secondaryCtaLink && secondaryCtaText.length > 0 && secondaryCtaLink.length > 0;
  const isCaptionLink = linkUrl !== undefined && linkUrl.length > 0 ? true : false;
  const CardLinkPrimaryBtnRef = useRef(null);
  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': props?.componentId }
    : {};
  const [mQuery, setMQuery] = useState(true);
  const dir = useDocumentDirection();

  useEffect(() => {
    handleScreenResize();
    window.addEventListener('resize', handleScreenResize);
  }, []);

  const handleScreenResize = () => {
    const mobileSize = window.matchMedia('(max-width: 767px)');
    const tabletSize = window.matchMedia('(min-width:768px)');
    if (mobileSize.matches) {
      setMQuery(true);
    } else if (tabletSize.matches) {
      setMQuery(false);
    }
  };

  const hasStandard = styleclass?.includes('standard');
  const hasColorScheme = styleclass?.split(' ').some(cls => cls.startsWith('color-scheme'));

  return (
    <StyledHero
      data-component-name="m-ui-library-Hero"
      data-testid="ui-library-Hero"
      scrimGradient={styleclass}
      dir={dir}
      className={clsx('row', 'hero', 'm-container-fullbleed', {
        [styleclass]: hasStandard || hasColorScheme,
        inverse: !hasStandard && !hasColorScheme,
      })}
    >
      <div
        className={clsx(
          'hero_item',
          { hero_16x9: variation === SIZE_16x9 },
          { hero_5x2: variation === SIZE_5x2 },
          { hero_3x1: variation === SIZE_3x1 },
          { ...customAttributes }
        )}
      >
        <div className={clsx('d-sm-block')}>
          <Image
            customClass="feature-img"
            altText={dynamicMedia?.altText}
            renditions={dynamicMedia?.renditions}
            dynamic={dynamicMedia?.dynamic}
            defaultImageURL={dynamicMedia?.assetPath}
            loading={loadingType}
          />
        </div>
        <div
          className={`hero__cnt-sec ${
            !(header || eyebrowText || description || primaryCtaText || secondaryCtaText) ? '' : 'gradient'
          }`}
        >
          <div className="container-sm hero__cnt-ctr">
            <div className="row">
              <div className="hero_eyebrow_content col-12 col-sm-6 col-lg-6">
                <div className={`${eyebrowType === 'eyebrow' ? 'd-block isEyebrow' : 'd-none'}`}>
                  {eyebrowText && <Eyebrow text={eyebrowText} variation={EyebrowEnum.Overlay} />}
                </div>
                <div
                  className={`${
                    eyebrowType === 'logooverlay' && assetType === 'image' ? 'd-block isImageOverlay' : 'd-none'
                  }`}
                >
                  <Image
                    customClass="logooverlay-img"
                    altText={dynamicMediaLogo?.altText}
                    renditions={dynamicMediaLogo?.renditions}
                    dynamic={dynamicMediaLogo?.dynamic}
                    defaultImageURL={dynamicMediaLogo?.assetPath}
                    loading={loadingType}
                  />
                </div>
                <div
                  className={`${
                    eyebrowType === 'logooverlay' && assetType === 'iconfont' ? 'd-block isIconOverlay' : 'd-none'
                  }`}
                >
                  <Icon
                    iconClass={clsx(
                      'icon_size',
                      iconFontPath?.includes('portfolio') ? iconFontPath : `brand-logo-${iconFontPath}`
                    )}
                    ariaLabel={iconAltText}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="hero_content col-12 col-sm-6 col-lg-6">
                <Heading
                  customClass={`hero_head ${mQuery === true ? 'm-ellipsis-3lines' : 'm-ellipsis-2lines'}`}
                  titleText={header}
                  variation={headingType.title}
                  fontSize={size.medium}
                  element={headerTag}
                />

                {description && <p className={clsx('hero_desc t-font-m m-ellipsis-2lines')}> {description} </p>}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-12">
                <div className="hero_cta-wrapper hero_cta-center">
                  {hasPrimaryButton && (
                    <Button
                      isLink
                      href={primaryCtaLink}
                      className={`mb-2 hero_cta m-button-primary ${mQuery === true ? 'm-button-s' : 'm-button-m'} ${
                        primaryCtaOpenInNewTab === true ? 'm-button-external' : ''
                      }`}
                      target={primaryCtaOpenInNewTab === true ? '_blank' : ''}
                      trackingProperties={trackingProperties}
                      ref={CardLinkPrimaryBtnRef}
                    >
                      {primaryCtaText}
                    </Button>
                  )}
                  {hasSecondaryButton && (
                    <Button
                      isLink
                      href={secondaryCtaLink}
                      className={`hero_cta hero_sec-cta m-button-secondary ${
                        mQuery === true ? 'm-button-s' : 'm-button-m'
                      } ${secondaryCtaOpenInNewTab === true ? 'm-button-external' : ''}`}
                      target={secondaryCtaOpenInNewTab === true ? '_blank' : ''}
                      custom_click_track_value={`${header}|${secondaryCtaText}|${
                        secondaryCtaOpenInNewTab === true ? 'external' : 'internal'
                      }`}
                    >
                      {secondaryCtaText}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {iconText && (
          <div className="hero_iconText-wrapper">
            <div className={`t-label-inverse-xs`}>
              <Icon
                iconClass={`${isCaptionLink === true ? 'pe-auto' : 'pe-none'} ${iconPath}`}
                ariaLabel="aria-label"
              />
              <Button
                isLink={true}
                href={linkUrl}
                className={`pl-2 ${isCaptionLink === true ? 'pe-auto' : 'pe-none'}`}
                target="_blank"
                custom_click_track_value={`${header}|${iconText}|external`}
              >
                {iconText}
              </Button>
            </div>
          </div>
        )}
      </div>
    </StyledHero>
  );
};
