/* eslint-disable @typescript-eslint/no-explicit-any */
import { isLocalDev } from '@marriott/mi-apollo-client-utils';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET,
  LOCALE_SUBDIRECTORY_MAPPING,
  MILES_TO_METERS,
  KILOMETERS_TO_MILES,
} from '../constants/CommonConstants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getContentUrl = (context: any, contentRoot: string): string => {
  const req = context?.req;
  const resolvedUrlString = context?.resolvedUrl ?? req.url;
  const isAuthorMode = resolvedUrlString.includes('isAuthorMode');
  let uri = resolvedUrlString;
  if (uri && isLocalDev && !isAuthorMode) {
    uri = `${contentRoot}${uri}`;
  } else if (typeof uri == 'undefined') {
    uri = null;
  }
  return uri;
};

export const getWCMModeFlagValue = () => {
  if (typeof window !== 'undefined') {
    const pathName = window?.location?.href;
    const paramWcmMode = pathName.includes('?') ? pathName.split('?') : '';
    const isWCM = paramWcmMode.includes('wcmmode=disabled');
    return isWCM;
  } else {
    return false;
  }
};

export const processSiteMapLinks = (data: any): any => {
  const siteMapLinks = {
    header: '',
    linkItems: [],
  };
  const linktItemArr = data.map((obj: any) => ({
    code: obj.code,
    linkLabel: obj.title,
    linkURL: obj.uri,
    openInNewTab: true,
  }));
  siteMapLinks.linkItems = linktItemArr;

  const finalSitemapArr = [];
  const linkGridCount = 3;
  const linksPerGrid = Math.ceil(data.length / linkGridCount);

  const linkItems = data.reduce((resultArray: any, item: any, index: number) => {
    const arrayIndex = Math.floor(index / linksPerGrid);
    if (!resultArray[arrayIndex]) {
      resultArray[arrayIndex] = []; // start a new array
    }
    resultArray[arrayIndex].push({ code: item.code, linkLabel: item.title, linkURL: item.uri, openInNewTab: true });
    return resultArray;
  }, []);
  console.log('linkItems', linkItems);
  for (let index = 0; index < linkGridCount; index++) {
    const obj = {
      header: '',
      linkItems: linkItems[index],
    };
    finalSitemapArr.push(obj);
  }
  console.log('finalSitemapArr ', finalSitemapArr);

  return finalSitemapArr;
};

export const getSubDirectoryLocale = (currentLocale: string): string => {
  const subDirectory = LOCALE_SUBDIRECTORY_MAPPING[currentLocale];
  return subDirectory || '';
};

export const getCtaUrl = (node: any, subDirectoryContextPath: any) => {
  const brandId = node?.basicInformation?.brand?.id || node?.property?.basicInformation?.brand?.id;
  const seoNickname = node?.seoNickname || node?.property?.seoNickname;
  const subDirectoryVal = subDirectoryContextPath || (brandId === 'RZ' ? 'en' : 'en-us');

  return brandId === 'RZ'
    ? `https://www.ritzcarlton.com/${subDirectoryVal}/hotels/${seoNickname}/overview/`
    : `/${subDirectoryVal}/hotels/${seoNickname}/overview/`;
};

export const useGetBreakpoint = (): string | undefined => {
  if (typeof window !== 'undefined' && window?.screen && window?.screen?.width) {
    return window.innerWidth > BREAKPOINT_DESKTOP
      ? 'desktop'
      : window.innerWidth > BREAKPOINT_TABLET
      ? 'tablet'
      : 'mobile';
  } else {
    return undefined;
  }
};

export const getUrlParam = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const deepLinkParam = ['selectedCity', 'selectedStateProvince', 'selectedCountry'];
  const deepLinkParamVal = [];
  let onloadInput = '';

  for (let i = 0; i < deepLinkParam.length; i++) {
    const value = urlParams
      .get(deepLinkParam[i])
      ?.replace('.', ' ')
      ?.replace(/\w+/g, function (w) {
        return (
          w[0].toUpperCase() +
          (deepLinkParam[i] === 'selectedCountry' || deepLinkParam[i] === 'selectedStateProvince'
            ? w.slice(1)
            : w.slice(1)?.toLowerCase())
        );
      });
    value && deepLinkParamVal.push(value);
  }
  for (let i = 0; i < deepLinkParamVal.length; i++) {
    onloadInput += `${deepLinkParamVal[i] ?? ''}${deepLinkParamVal[i + 1] ? ', ' : ''}`;
  }
  return onloadInput;
};

export const processAcceptLang = (locale: string) => {
  return locale ? (locale?.includes(',') ? locale?.split(',')[0] : locale?.replace('_', '-')) : locale;
};

export const milesToMeters = (miles: string) => {
  return parseFloat(miles) * MILES_TO_METERS;
};

export const isValidNumberString = (value: any) => {
  // Check if the value is a string
  if (typeof value !== 'string') {
    return false;
  }
  // Convert the string to a number
  const number = parseFloat(value);
  // Check if the conversion result is a valid number
  return !isNaN(number) && isFinite(number);
};

export const getDestinationRedirectUrl = (req: any, currentLocaleVal: string, destinationRedirect: string): string => {
  return `https://${req?.headers['x-host']}${getSubDirectoryLocale(currentLocaleVal)}${destinationRedirect}.mi`;
};
export const getDestinationRedirects = (req: any, destinationvalues: any, currentLocale: string, res: any) => {
  const initialUrl = req?.url?.replace(/^\/en/, '');
  const keyToFind = initialUrl?.split('?')[0];
  if (destinationvalues && keyToFind in destinationvalues) {
    const Destinationredirect = destinationvalues[keyToFind as keyof typeof destinationvalues];
    const redirectUrl = getDestinationRedirectUrl(req, currentLocale, Destinationredirect);
    res?.writeHead(301, {
      Location: redirectUrl,
    });
    res.end();
  }
};
