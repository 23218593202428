/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { StyledHtmlSitemap } from './HtmlSitemap.styles';

import { BreadCrumb } from './BreadCrumb';
import { SitemapListView } from './SitemapListView';
import { SitemapGroupView } from './SitemapGroupView';
import { processSiteMapListLinks, processSiteMapGrpLinks, processBreadcrumbLinks } from '../../utils/SitemapUtils';
import { NO_OF_LIST_GRID } from '../../constants/SitemapConstants';
import { SitemapModelMockJson } from './__mock__/HtmlSitemap.mock';

// Use named rather than default exports.
export const HotelSitemap: FC<any> = (props: any) => {
  console.log('HTML Sitemap props ', props);
  const { model, isAuthorMode } = props;
  const modProps = !isAuthorMode ? model : SitemapModelMockJson.model;
  let hotelSitemapArr: any = [];
  let sitemapGrpViewArr: any = [];

  const headingTitle = modProps?.headingTitle;

  const LinkBannerModel = {
    model: {
      componentId: 'qzwqevcs9unjjtwm',
      isAuthor: true,
      merchandisingCategory: 'nonCobrand',
      hideColumDividers: true,
      linkBannerHeader: '',
      impressionTrack: 'false',
      impressionCount: 'false',
      noOfColumns: NO_OF_LIST_GRID,
      columnDetails: [],
      wcmMode: 'edit',
      styleclass: 'mb-3',
      path: '/content/mi-aem-common-spa/us/en/linkbanner/jcr:content/root/responsivegrid/linkbanner',
      cqType: 'mi-aem-common-spa/components/content/linkbanner',
      trackingProperties: {},
    },
    cqPath: '/content/mi-aem-common-spa/us/en/linkbanner/jcr:content/root/responsivegrid/linkbanner',
    className: 'aem-GridColumn aem-GridColumn--default--12',
    ':type': 'mi-aem-common-spa/components/content/linkbanner',
    allowedComponents: {
      applicable: false,
      components: [
        {
          path: '/content/mi-aem-common-spa/us/en/card-layered/jcr:content/root/mi-aem-common-spa/components/content/linkbanner',
          title: 'Link Banner',
        },
      ],
    },
  };

  hotelSitemapArr =
    modProps?.renderMode === 'listing' && modProps?.listingMap?.length
      ? processSiteMapListLinks(modProps?.listingMap, modProps?.listItemPrefix, LinkBannerModel?.model?.noOfColumns)
      : [];
  sitemapGrpViewArr =
    modProps?.renderMode === 'grouping' && modProps?.groupingMap?.length ? processSiteMapGrpLinks(modProps) : [];

  LinkBannerModel.model.columnDetails = hotelSitemapArr;
  LinkBannerModel.model.trackingProperties = modProps?.trackingProperties;

  const breadcrumbLinks = modProps?.breadcrumbs?.length ? processBreadcrumbLinks(modProps?.breadcrumbs) : [];

  return (
    <StyledHtmlSitemap>
      <BreadCrumb navlinks={breadcrumbLinks} subTitle={headingTitle} />
      {hotelSitemapArr && modProps?.renderMode === 'listing' && (
        <SitemapListView {...LinkBannerModel} subTitle={headingTitle} />
      )}
      {sitemapGrpViewArr && modProps?.renderMode === 'grouping' && <SitemapGroupView groupData={sitemapGrpViewArr} />}
    </StyledHtmlSitemap>
  );
};
