/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { StyledSection } from './styles';
import clsx from 'clsx';

const StaticWrapperReact: FC = (props: any) => {
  const { referencePath } = props;
  return (
    <StyledSection data-component-name="o-common-static-staticwrapper">
      <section
        dangerouslySetInnerHTML={{ __html: referencePath }}
        className={`${AuthoringUtils.isInEditor() ? 'authorBorder' : ''}`}
      />
    </StyledSection>
  );
};

export const StaticWrapperConfig = {
  emptyLabel: 'staticWrapper',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/common/staticwrapper`,
};

export const StaticWrapperEditable = (props: any) => {
  return (
    <EditableComponent
      config={StaticWrapperConfig}
      {...props}
      isInEditor={!props.cqPath.includes('experiencefragment')}
    >
      <StaticWrapperReact />
    </EditableComponent>
  );
};

export const StaticWrapper = (props: any) => {
  return props?.model?.styleclass ? (
    <div
      className={clsx(
        props?.model?.styleclass,
        props?.model?.styleclass.includes('fullbleed') ? 'm-container-fullbleed' : ''
      )}
    >
      <StaticWrapperEditable {...props} />
    </div>
  ) : (
    <StaticWrapperEditable {...props} />
  );
};
