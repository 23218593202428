import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledContainer = styled.div`
  .cardWrapper {
    .container {
      padding: 0 0.5rem;
    }
    .icon-arrow-right-external {
      position: initial;
    }
    .chevronContainer[class*='icon-arrow-']::before {
      color: ${baseVariables.color['base20']};
    }
    .carousal_header--cta {
      button {
        background-color: transparent;
        color: inherit;
      }
    }
    .glide__slides_authoring {
      @media only screen and (min-width: 767px) {
        flex-wrap: nowrap !important;
      }
    }
  }

  .m-badge-overlay-merch {
    background-color: rgb(255, 153, 98);
  }

  .t-overline-overlay {
    font-weight: 500;
    color: ${baseVariables.color['base20']};
  }

  .feature-header {
    line-height: 1;
  }

  .mt-2 {
    font-weight: 700;
    text-transform: none;
    color: ${baseVariables.color['base20']};
  }

  t-subtitle-l {
    display: block;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    max-width: 100%;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    -webkit-line-clamp: 2;
  }

  .hideCarousel {
    display: none;
  }
`;

export const StyledOfferCardsDiv = styled.div``;
export const StyledOfferCardsDivPreviewMode = styled.div`
  pointer-events: none;
`;
