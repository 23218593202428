/* eslint-disable @typescript-eslint/no-explicit-any */

import { createContext, Dispatch, useContext, SetStateAction } from 'react';

export const NavigationContext = createContext<{
  offset: number;
  navRef: any;
  isInverse: boolean;
  isSignInDrawerOpen: boolean;
  setIsSignInDrawerOpen: Dispatch<SetStateAction<boolean>>;
  isMainMenuOpen: boolean;
  setIsMainMenuOpen: Dispatch<SetStateAction<boolean>>;
  isLanguageSelectorOpen: boolean;
  setIsLanguageSelectorOpen: Dispatch<SetStateAction<boolean>>;
  isMyTripsModalOpen: boolean;
  setIsMyTripsModalOpen: Dispatch<SetStateAction<boolean>>;
}>({
  offset: 0,
  navRef: null,
  isInverse: false,
  isSignInDrawerOpen: false,
  setIsSignInDrawerOpen: () => false,
  isMainMenuOpen: false,
  setIsMainMenuOpen: () => false,
  isLanguageSelectorOpen: false,
  setIsLanguageSelectorOpen: () => false,
  isMyTripsModalOpen: false,
  setIsMyTripsModalOpen: () => false,
});

export const useNavContext = () => {
  return useContext(NavigationContext);
};
