/* eslint-disable @nx/enforce-module-boundaries */
// Styles for OfferPropertiesList go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { mediaQuery, font } from '../../../utils/styles';

export const StyledPropertiesListWrapper = styled.div`
  .sort-by-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .displayNone {
    display: none;
  }
  .page-card-count {
    padding-top: ${toRem(20)};
    padding-bottom: ${toRem(4)};
    font-weight: 500;
    line-height: ${toRem(20)};
  }
  .property-card {
    @media ${baseVariables.mediaQuery.xl} {
      height: ${toRem(285)};
      .property-card__img-container,
      .property-card__img-container--img {
        height: ${toRem(285)};
      }
    }
    @media ${mediaQuery.mobileTablet} {
      height: 14rem;
      .property-card__img-container,
      .property-card__img-container--img {
        height: 14rem;
      }
    }
    .property-card__details-container {
      height: unset;
      .property-card__details-container_top .property-card__title {
        button {
          text-align: left;
        }
      }
      .property-card__title a {
        color: ${baseVariables.color.neutral00};
      }
    }
    .rate-unavailable-text {
      font-size: ${toRem(17)};
    }

    @media ${mediaQuery.mobileOnly} {
      height: unset;
    }
  }
  .no-hotels-found {
    border: 0.0625rem solid rgb(208, 2, 27);
    border-radius: 0.5rem;
    background-color: rgb(252, 235, 237);
    margin-top: ${toRem(16)};
    margin-bottom: ${toRem(25)};
    .m-message-inline.col-10 {
      flex: unset;
      max-width: 100%;
      border: none;
      background-color: rgb(252, 235, 237);
      padding: ${toRem(12)} ${toRem(20)};
    }
    .m-message-content-wrap {
      &:before {
        color: rgb(208, 2, 27);
      }
      color: rgb(208, 2, 27);
    }
  }
  .many-hotels-found {
    border: 0.0625rem solid rgb(185, 70, 0);
    color: rgb(185, 70, 0);
    border-radius: 0.5rem;
    background-color: rgb(255, 255, 255);
    margin-top: ${toRem(16)};
    margin-bottom: ${toRem(25)};
    .m-message-inline.col-10 {
      flex: unset;
      max-width: 100%;
      border: none;
      background-color: rgb(255, 255, 255);
      padding: ${toRem(12)} ${toRem(20)};
    }
    .m-alert-inline-sub-content {
      color: rgb(185, 70, 0);
    }
    .m-message-content-wrap {
      &:before {
        color: rgb(208, 2, 27);
      }
      color: rgb(208, 2, 27);
    }
  }
  .propertiesPreviewMode {
    pointer-events: none;
  }
  .properties-list-preview-message {
    font-family: ${font.Swiss721BTMedium};
    line-height: 1.25;
    font-size: ${toRem(23)};
    padding: ${toRem(20)} ${toRem(0)} ${toRem(24)};
  }
  .link-header {
    font-weight: 500;
  }
  .price-section {
    display: flex;
    flex-direction: row;
    -webkit-box-align: baseline;
    align-items: baseline;
    -webkit-box-pack: start;
    justify-content: flex-start;
    line-height: 1.25rem;
  }
  .points-currency-label {
    text-transform: capitalize;
    margin-left: 0.375rem;
  }
  .currency-section {
    margin-top: -0.15rem;
    display: flex;
    flex-direction: row;
    -webkit-box-align: baseline;
    align-items: baseline;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
  .m-price-currency-s {
    font-weight: 700;
    font-size: 0.75rem;
  }
  .currency-label {
    text-transform: capitalize;
    margin-left: 0.125rem;
  }
  .points-section.non-saver {
    align-items: flex-end;
    flex-direction: column;
  }
  .points-section.points-saver {
    align-items: flex-start;
  }
  .points-saver-label {
    font-size: 12px;
    margin-bottom: -0.125rem;
  }
  .price-section .points-value.points-saver {
    color: rgb(71, 125, 0);
  }
  .offer-properties-list .property-card__img-container--logo {
    .brand-logo-RS::before {
      font-size: ${toRem(25)};
    }
    .brand-logo-SN::before {
      font-size: ${toRem(60)};
      @media ${mediaQuery.mobileOnly} {
        font-size: ${toRem(55)};
      }
    }
  }
`;
