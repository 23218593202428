import { FC } from 'react';
import { baseVariables, toRem, Link, Types, Accordion } from '@marriott/mi-ui-library';

import { getClickTrackValue } from '../../utils/TrackingPropertyUtils';
import { LinkFeature } from './LinkFeature';
import { FooterAEMModel, DestinationItemsModel } from './Footer.types';
import { DEFAULT_TRACKING_VALUES } from '../../utils/constants';

// added styled components for components that wouldn't override
import {
  StyledText,
  StyledP,
  DestinationWrapper,
  LinkWrapper,
  DestinationOnTopWrapper,
  MarriottWrapper,
  LineHeightWrapper,
} from './Footer.styles';

import { parseBoolean } from './helper';

export const ExpandedFooter: FC<{ model: FooterAEMModel }> = ({ model }) => {
  const trackingPropertiesObject = {
    trackingProperties: {
      trackingContentPosition: model?.trackingProperties?.trackingContentPosition,
      clickTrack: model?.trackingProperties?.clickTrack,
      trackingOfferType: model?.trackingProperties?.trackingOfferType,
      impressionCount: model?.trackingProperties?.impressionCount,
      impressionTrack: model?.trackingProperties?.impressionTrack,
      trackingDescription: model?.trackingProperties?.trackingDescription,
      trackingTag: model?.trackingProperties?.trackingTag,
    },
    fallbacks: {
      position: model?.trackingProperties?.location ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
      description: model?.trackingProperties?.trackingDescription ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
    },
  };

  const ColumnLayout = ({
    values,
    maxColumns,
    maxValuesPerColumn,
  }: {
    values: DestinationItemsModel[];
    maxColumns: number;
    maxValuesPerColumn: number;
  }): JSX.Element => {
    // Because of this sorting, destination items must have a linkLabel, so
    // a different type is required.
    const distributeValues = (values: DestinationItemsModel[], maxColumns: number, maxValuesPerColumn: number) => {
      const columns = [];
      for (let i = 0; i < Math.min(maxColumns, Math.ceil(values?.length / maxValuesPerColumn)); i++) {
        columns.push(values?.slice(i * maxValuesPerColumn, (i + 1) * maxValuesPerColumn));
      }
      return columns;
    };

    const columns = distributeValues(values, maxColumns, maxValuesPerColumn);
    return (
      <MarriottWrapper className="d-flex flex-wrap row m-0">
        {columns.map((column, index) => {
          return (
            <div key={index} className="col-12 col-md-3">
              {column.map((value: DestinationItemsModel, i: number) => (
                <div className="mb-1" key={i}>
                  <LinkWrapper>
                    <Link
                      target={parseBoolean(value.openInaNewTab) ? '_blank' : '_self'}
                      linkHref={value.linkURL}
                      text={value.linkLabel}
                      linkClassName="t-font-alt-s"
                      custom_click_track_value={getClickTrackValue(trackingPropertiesObject, value.linkLabel)}
                      trackingProperties={trackingPropertiesObject.trackingProperties}
                    />
                  </LinkWrapper>
                </div>
              ))}
            </div>
          );
        })}
      </MarriottWrapper>
    );
  };

  // TODO: These components need to  be replaced with the LinkList components and Accordion components when the platform team are finished with their tickets
  return (
    <>
      {parseBoolean(model?.resourceProperties?.destinationOnTop) && model?.destinationItems?.length > 0 && (
        <DestinationOnTopWrapper>
          <Accordion
            styleclass="inverse"
            id="destination-on-top-accordion"
            isOpen={model?.resourceProperties?.destinationState === 'expanded' ? true : false}
            headerChildren={
              <StyledText
                element={Types.tags.span}
                copyText={model?.resourceProperties?.destinationTitle}
                fontSize={Types.size.large}
                customClass={'destinationTitle'}
              />
            }
          >
            <div className="col-12 m-accordion-container inverse">
              <div className="d-flex flex-column flex-md-row row m-0">
                {model?.destinationItems?.map(destinationItem => {
                  return (
                    <div className="col-md-3" key={destinationItem.linkLabel}>
                      <LinkWrapper>
                        <Link
                          target={parseBoolean(destinationItem.openInaNewTab) ? '_blank' : '_self'}
                          linkHref={destinationItem.linkURL}
                          text={destinationItem.linkLabel}
                          linkClassName="t-font-alt-s"
                          custom_click_track_value={getClickTrackValue(
                            trackingPropertiesObject,
                            destinationItem.linkLabel
                          )}
                          trackingProperties={trackingPropertiesObject.trackingProperties}
                        />
                      </LinkWrapper>
                    </div>
                  );
                })}
              </div>
            </div>
          </Accordion>
        </DestinationOnTopWrapper>
      )}

      {/* bootstrap borders didn't work here, opted for style */}
      <MarriottWrapper
        className="d-flex row flex-md-nowrap m-0"
        style={{
          borderBottom: !parseBoolean(model?.resourceProperties?.destinationOnTop)
            ? `solid ${toRem(0.5)} ${baseVariables.color['neutral40']}`
            : 'none',
          position: 'relative',
          paddingTop: parseBoolean(model?.resourceProperties?.destinationOnTop) ? toRem(24) : 0,
        }}
      >
        <div className="flex-column inverse col-md-3">
          <StyledP className="t-font-l mb-3">{model?.resourceProperties?.ourCompanyTitle}</StyledP>
          <LineHeightWrapper className="mb-4 mb-md-5 lh-sm">
            {model?.ourCompanyItems?.map(companyItem => {
              return (
                <div key={companyItem.linkLabel}>
                  <LinkFeature item={companyItem} trackingPropertiesObject={trackingPropertiesObject} />
                </div>
              );
            })}
          </LineHeightWrapper>
        </div>
        <div className="flex-column inverse col-md-3">
          <StyledP className="t-font-l mb-3">{model?.resourceProperties?.workWithUsTitle}</StyledP>
          <LineHeightWrapper className="mb-4 mb-md-5 lh-sm">
            {model?.workwithusItems?.map(workWithUsItem => {
              return (
                <div key={workWithUsItem.linkLabel}>
                  <LinkFeature item={workWithUsItem} trackingPropertiesObject={trackingPropertiesObject} />
                </div>
              );
            })}
          </LineHeightWrapper>
        </div>
        <div className="flex-column inverse col-md-3">
          <StyledP className="t-font-l mb-3">{model?.resourceProperties?.findHelpTitle}</StyledP>
          <LineHeightWrapper className="mb-4 mb-md-5 lh-sm">
            {model?.findhelpItems?.map(findHelpItem => {
              return (
                <div key={findHelpItem.linkLabel}>
                  <LinkFeature item={findHelpItem} trackingPropertiesObject={trackingPropertiesObject} />
                </div>
              );
            })}
          </LineHeightWrapper>
        </div>
        <div className="flex-column inverse col-md-3">
          <StyledP className="t-font-l mb-3">{model?.resourceProperties?.privacyTitle}</StyledP>
          <LineHeightWrapper className="mb-4 mb-md-5 lh-sm">
            {model?.privacyItems?.map(privacyItem => {
              return (
                <div key={privacyItem.linkLabel}>
                  <LinkFeature item={privacyItem} trackingPropertiesObject={trackingPropertiesObject} />
                </div>
              );
            })}
          </LineHeightWrapper>
        </div>
      </MarriottWrapper>

      {!parseBoolean(model?.resourceProperties?.destinationOnTop) && model?.destinationItems?.length > 0 && (
        <DestinationWrapper>
          <Accordion
            styleclass="inverse"
            id="default-destination-accordion"
            isOpen={model?.resourceProperties?.destinationState === 'expanded' ? true : false}
            customClass="container p-0 py-3 m-0"
            headerChildren={
              <StyledText
                element={Types.tags.span}
                copyText={model?.resourceProperties?.destinationTitle}
                fontSize={Types.size.large}
              />
            }
          >
            <ColumnLayout values={model?.destinationItems} maxColumns={4} maxValuesPerColumn={7} />
          </Accordion>
        </DestinationWrapper>
      )}
    </>
  );
};
