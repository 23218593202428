// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const constructJsonSchema = (data: any, cardsCount: any) => {
  if (data) {
    const hotelsArray = [];
    const hotelsData = data;

    for (let i = 0, position = 1; i < Math.min(parseInt(cardsCount), hotelsData.length); i++) {
      const hotel = hotelsData[i];
      if (
        hotel.node?.property?.reviews?.stars?.count > 0 &&
        hotel.node?.property?.reviews?.numberOfReviews?.count > 0
      ) {
        const item = {
          '@type': 'ListItem',
          position: position.toString(), // Use the current position
          item: {
            '@type': 'Hotel',
            name: hotel.node.property?.basicInformation?.name ?? '',
            image:
              hotel.node?.property?.media?.photos?.length > 0
                ? hotel.node?.property?.media.photos[0].content.length > 0
                  ? hotel.node?.property?.media.photos[0].content[0]?.url ?? ''
                  : ''
                : '',
            //[removeQueryParams(hotel.imageUrl ?? '') ?? ''],
            url: `https://www.marriott.com/hotels/travel/` + hotel.node?.property?.seoNickname ?? '', //need to update with subdirectory
            address: {
              '@type': 'PostalAddress',
              streetAddress: hotel.node?.property?.contactInformation?.address?.line1 ?? '',
              addressLocality: hotel.node?.property?.contactInformation?.address?.city ?? '',
              addressRegion: hotel.node?.property?.contactInformation?.address?.stateProvince?.description ?? '',
              addressCountry: hotel.node?.property?.contactInformation?.address?.country.description ?? '',
              postalCode: hotel.node?.property?.contactInformation?.address?.postalCode ?? '',
            },
            telephone:
              hotel.node?.property?.contactInformation?.contactNumbers?.length > 0
                ? hotel.node?.property?.contactInformation?.contactNumbers[0].phoneNumber?.display
                : '',
            AggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: hotel.node?.property?.reviews?.stars.count ?? '',
              bestRating: '5',
              reviewCount: hotel.node?.property?.reviews?.numberOfReviews?.count ?? '',
            },
          },
        };
        hotelsArray.push(item);
        position++; // Increment position only when the hotel meets the criteria
      }
    }

    const jsonObject = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: hotelsArray,
    };

    return jsonObject;
  }
  return;
};
