// Imports for external libraries go here.
import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { DestinationOverviewProps } from './DestinationOverview.types';
import { tags, TextComponent } from '@marriott/mi-ui-library';
import { destinationOverviewMockData } from './__mock__/DestinationOverview.mock';

// Use named rather than default exports.
export const DestinationOverviewWrapper: FC<DestinationOverviewProps> = props => {
  const { model, isAuthorMode } = props;
  const { trackingProperties } = model;
  const destinationOverviewProps = !isAuthorMode ? model : destinationOverviewMockData;

  const clickTrackingProperties = {
    trackingContentPosition: trackingProperties?.trackingContentPosition,
    trackingOfferType: trackingProperties?.trackingOfferType,
    atCCeVar48: trackingProperties?.atCCeVar48,
    trackingDescription: trackingProperties?.trackingDescription,
    trackingTag: trackingProperties?.trackingTag,
    isCoBrand: false,
    clickTrack: trackingProperties?.clickTrack,
    impressionTrack: true,
    impressionCount: true,
    impressionEventType: trackingProperties?.impressionEventType,
    merchandisingCategory: trackingProperties?.merchandisingCategory,
    additionalTrackingVariables: trackingProperties?.additionalTrackingVariables,
    location: trackingProperties?.location,
    payloadType: trackingProperties?.payloadType,
    compName: trackingProperties?.compName,
    enableScrollingBehavior: trackingProperties?.enableScrollingBehavior,
    cardLocation: model?.id ?? trackingProperties?.cardLocation,
    leftArrowDesc: trackingProperties?.leftArrowDesc,
    rightArrowDesc: trackingProperties?.rightArrowDesc,
    description: model?.primaryCtaLabel ?? trackingProperties?.description,
  };
  return (
    <div>
      <TextComponent
        componentId={destinationOverviewProps?.componentId}
        styleclass={destinationOverviewProps?.styleclass ?? ''}
        primaryCtaLabel={destinationOverviewProps?.primaryCtaLabel}
        primaryCtaLink={destinationOverviewProps?.primaryCtaLink}
        primaryCtaType={destinationOverviewProps?.primaryCtaType}
        header={destinationOverviewProps?.header}
        headerFontSize={destinationOverviewProps?.headerFontSize}
        openInNewTabPrimary={destinationOverviewProps?.openInNewTabPrimary}
        headerTag={destinationOverviewProps?.headerTag ?? tags.h1}
        contentAlignment={destinationOverviewProps?.contentAlignment}
        descriptionText={destinationOverviewProps?.descriptionText}
        showLessDesc={destinationOverviewProps?.showLessDesc}
        descriptionFontSize={destinationOverviewProps?.descriptionFontSize}
        trackingProperties={clickTrackingProperties}
      />
    </div>
  );
};
