/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import {
  AEMReactCompMap,
  VanilaCardCarouselContainer as CardCarouselContainerMolecule,
  updateAEMCQKeys,
  useDocumentDirection,
} from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { FC, lazy, Suspense, useEffect, ReactElement } from 'react';
import { CardCarouselProps, CarouselContainerProps } from './CardCarouselContainer.types';

export const CardCarouselContainerConfig = {
  emptyLabel: 'CardCarousalContainer',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/cardcarouselcontainer`,
};

export const CardCarouselContainerComp: FC<CardCarouselProps> = (props: CardCarouselProps) => {
  const {
    headerText,
    subHeaderText,
    ctaLabel,
    ctaLink,
    eyebrow,
    ctaType,
    cardCount,
    openInaNewTab,
    totalNumberOfCards,
    trackingProperties,
    styleclass,
    componentId,
    pagePath,
    itemPath,
    isAuthorMode,
    variation,
    enableTabletBreakpoint,
    isRTL = false,
  } = props;

  let { noOfCards, noOfCardsTablet } = props;
  const updatedProps = updateAEMCQKeys(props);
  const cqItems = updatedProps['cqItems'];
  const dir = useDocumentDirection();

  const isCombo = (): boolean => {
    return variation === 'combo';
  };

  if (isCombo()) {
    noOfCards = 2;
    noOfCardsTablet = 2;
  }

  const mapper = AEMReactCompMap(props?.allowedComponents);

  useEffect(() => {
    const pageWcmMode = document.getElementsByTagName('html')[0];
    const updateStyleComponent = document.getElementById(`${componentId}__slides`);
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const currWcmMode = pageWcmMode?.getAttribute('class');
          if (currWcmMode?.includes('Edit')) {
            updateStyleComponent?.classList.add('glide__slides_authoring');
          } else if (currWcmMode?.includes('Preview')) {
            updateStyleComponent?.classList.remove('glide__slides_authoring');
          }
        }
      });
    });
    observer.observe(pageWcmMode, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  // This function is used to render the component in authoring mode authorCardWrapper
  const authorCardWrapper = (index: number) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
        columnCount="12"
        gridClassNames={''}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // This function is used to render the component in end-user mode
  const PublishCardWrapper = (cardName: string, jsonData: any): ReactElement | null => {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
          const item = cardItems[itemKey];
          const key = item?.componentId;
          const itemType = item[':type']?.split('/').pop();
          if (Object.prototype.hasOwnProperty.call(mapper, itemType)) {
            const innerComp = mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then(module => ({
                default: module[`${innerComp}`],
              }))
            );

            return (
              <li key={key}>
                <Suspense fallback={<></>}>
                  <Component {...item} />
                </Suspense>
              </li>
            );
          }
          return null;
        }
      }
    }
    return null;
  };

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  return (
    <CardCarouselContainerMolecule
      componentId={componentId}
      subHeaderText={subHeaderText}
      styleclass={styleclass}
      variation={variation}
      eyebrow={eyebrow}
      cardCount={cardCount}
      trackingProperties={trackingProperties}
      ctaLabel={ctaLabel}
      headerText={headerText}
      ctaLink={ctaLink}
      openInaNewTab={openInaNewTab}
      ctaType={ctaType}
      isCombo={isCombo}
      noOfCards={noOfCards}
      noOfCardsTablet={noOfCardsTablet}
      enableTabletBreakpoint={enableTabletBreakpoint}
      customAttributes={customAttributes}
      isDirectionRightToLeft={dir === 'rtl' || false}
    >
      {isAuthorMode && Array.from({ length: totalNumberOfCards?.length }, (_, i) => authorCardWrapper(i))}
      {!isAuthorMode && totalNumberOfCards?.map((cardName: string) => PublishCardWrapper(cardName, cqItems))}
    </CardCarouselContainerMolecule>
  );
};

export const CarouselEditableComponent = (props: any) => {
  const { model } = props;
  const cqType = model?.cqType || model[':type'];
  return (
    <EditableComponent config={CardCarouselContainerConfig} {...props}>
      <CardCarouselContainerComp {...props} componentName={props?.model?.cqType?.split('/').pop()} />
    </EditableComponent>
  );
};

export const CardCarouselContainer = (props: CarouselContainerProps) => {
  const { model } = props;

  if (model.variation === 'combo' && !model.styleclass?.includes('fullbleed')) {
    model.styleclass += ' fullbleed';
  }
  return (
    <div
      className={clsx({
        standard: model.styleclass?.includes('standard'),
        inverse: model.styleclass?.includes('inverse'),
        alternate: model.styleclass?.includes('alternate'),
        'm-container-fullbleed': model.styleclass?.includes('fullbleed'),
      })}
      data-testid="card-carousel"
      data-component-name="o-common-static-cardcarouselcontainer"
    >
      <div className={clsx('container', { 'p-0': !model.styleclass?.includes('fullbleed') })}>
        <CarouselEditableComponent {...props} />
      </div>
    </div>
  );
};
