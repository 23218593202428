/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { DATALAYER_CONSTANT } from '../../constants/lib/constants';
import { logger } from '../../utils/src/logger';
import { KeysRequest } from '../../utils/src/dataLayer/index.types';
import Cookies from 'js-cookie';
import { addSubDirectoryPrefix } from '../../utils/src/subDirectoryFunctions';
import { DE_LOCALE, ES_LOCALE, FR_LOCALE, PT_BR_LOCALE, RU_LOCALE } from '../constants';

const { log } = logger({})('AvailabilityCalendar/helper');

export const useGetBreakpoint = (): string | undefined => {
  if (typeof window !== 'undefined' && window?.screen && window?.screen?.width) {
    return window.innerWidth > 991 ? 'desktop' : window.innerWidth > 767 ? 'tablet' : 'mobile';
  } else {
    return undefined;
  }
};

export const getClassForBreakpoint = (
  breakpoint: string | undefined,
  desktopClass: string,
  tabletClass: string,
  mobileClass: string
): string => {
  if (!breakpoint) return desktopClass;
  if (breakpoint === 'tablet') {
    return tabletClass;
  } else if (breakpoint === 'mobile') {
    return mobileClass;
  } else return desktopClass;
};

export async function updateDataLayerOnInteractions(
  resolvedUrl: string,
  headersData: any,
  query: any,
  datalayerParams: any,
  currentLocale: string,
  sessionID: string,
  propertyId?: string,
  fusionSearchId?: string,
  isDTT?: boolean,
  isBulgariDomain = false
) {
  const {
    useridtoken,
    mi_site,
    mi_visitor,
    remembermeflag = false,
    remembermeuserid = '',
    remembermealternateid = '',
    referer,
  } = headersData;
  const getPageURI = resolvedUrl ? resolvedUrl.split('?')[0] : '';
  const getPageReferrer = referer ? referer.split('?')[0] : '';
  const MOCK_SESSION_ID = '825167AA-9F54-5B1B-95C0-53EEC4E22391';
  const absolutePageURL = `${getPageURI}.mi`;
  const channelValue = datalayerParams?.NEXT_PUBLIC_DATALAYER_SITENAME?.split('.')[0];
  const datalayerUrl = isBulgariDomain
    ? datalayerParams?.MI_DATALAYER_APP_URL
    : addSubDirectoryPrefix(datalayerParams?.MI_DATALAYER_APP_URL);

  const getHeaders = (): string => {
    let cookieValues = '';
    if (mi_site) {
      cookieValues = cookieValues + ` MI_SITE=${mi_site};`;
    }
    if (mi_visitor) {
      cookieValues = cookieValues + ` MI_VISITOR=${mi_visitor};`;
    }
    if (remembermeuserid) {
      cookieValues = cookieValues + ` RememberMeUserID=${encodeURIComponent(remembermeuserid)};`;
    }
    if (remembermealternateid) {
      cookieValues = cookieValues + ` RememberMeAlternateID=${encodeURIComponent(remembermealternateid)};`;
    }
    if (useridtoken) {
      cookieValues = cookieValues + ` UserIdToken=${encodeURIComponent(useridtoken)};`;
    }
    return cookieValues;
  };

  const headersKey: Record<string, string | undefined> = {};

  // creating headers for datalayer call
  if (headersData && Object.keys(headersData).length > 0) {
    DATALAYER_CONSTANT.headerKeys.forEach(name => {
      if (name && headersData[name as KeysRequest['keys']]) {
        headersKey[name] = headersData[name as KeysRequest['keys']];
      }
    });
  }

  const reqBody = {
    context: {
      localeKey: currentLocale ?? 'en_US',
      programFlag: '',
      siteName: datalayerParams?.NEXT_PUBLIC_DATALAYER_SITENAME,
      mobileAuthEnabled: 'false',
      productSiteId: datalayerParams?.NEXT_PUBLIC_DATALAYER_PRODUCT,
      channel: channelValue,
      pageContent: [],
      pageURI: getPageURI,
      referer: getPageReferrer,
      absolutePageURL: absolutePageURL,
      applicationName: datalayerParams?.NEXT_PUBLIC_DATALAYER_APPLICATION,
      products: datalayerParams?.NEXT_PUBLIC_DATALAYER_PRODUCT,
      template: datalayerParams?.NEXT_PUBLIC_DATALAYER_TEMPLATE,
      propertyId: propertyId,
      marshaCode: propertyId,
      seoQueryParams: query,
      fusionSearchId: fusionSearchId,
      isDtt: isDTT,
    },
    sessionToken: sessionID ?? MOCK_SESSION_ID,
    sourceURI: absolutePageURL,
    variation: datalayerParams?.NEXT_PUBLIC_DATALAYER_VARIATION,
  };

  // datalayer call
  const dataLayer = await axios
    .post(datalayerUrl, reqBody, {
      headers: {
        // please make sure these coookies are in single line. Else there will be `TypeError [ERR_INVALID_CHAR]`
        cookie: `sessionID=${sessionID}; RememberMeFlag=${remembermeflag}; ${getHeaders()}`,
        ...headersKey,
      },
    })
    .catch(err => {
      log.error(
        `[${resolvedUrl}] [DataLayer] Aries API Failed: ${err?.response?.status} [message]: ${err?.response?.data}`
      );
    });

  const datalayerResponse = dataLayer && dataLayer?.data?.component?.data?.dataProperties;
  if (datalayerResponse?.length && window?.dataLayer !== undefined) {
    for (const [key] of Object.entries(window.dataLayer)) {
      datalayerResponse.forEach((entry: any) => {
        if (window && window.dataLayer) {
          if (key === entry.key) {
            window.dataLayer[key] = entry.value;
          } else {
            window.dataLayer[entry.key] = entry.value;
          }
        }
      });
    }
  }
}

/**
 * Function to determine the screen size's
 */

export function getScreenCategory() {
  let screenCategory = '';
  if (window.innerWidth < 240) {
    screenCategory = 'XXS';
  } else if (window.innerWidth >= 240 && window.innerWidth < 320) {
    screenCategory = 'XS';
  } else if (window.innerWidth >= 320 && window.innerWidth < 480) {
    screenCategory = 'S';
  } else if (window.innerWidth >= 480 && window.innerWidth < 560) {
    screenCategory = 'SM';
  } else if (window.innerWidth >= 560 && window.innerWidth < 672) {
    screenCategory = 'M';
  } else if (window.innerWidth >= 672 && window.innerWidth < 768) {
    screenCategory = 'MML';
  } else if (window.innerWidth >= 768 && window.innerWidth < 864) {
    screenCategory = 'ML';
  } else if (window.innerWidth >= 864 && window.innerWidth < 960) {
    screenCategory = 'MLL';
  } else if (window.innerWidth >= 960 && window.innerWidth < 1024) {
    screenCategory = 'L';
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
    screenCategory = 'XL';
  } else if (window.innerWidth >= 1200 && window.innerWidth < 1440) {
    screenCategory = 'XXL';
  } else if (window.innerWidth >= 1440) {
    screenCategory = 'XXXL';
  } else {
    screenCategory = 'Unknown';
  }
  return screenCategory;
}

/**
 * updating and adding the datalayer attributes
 * */

export function updateDataLayerObj(dataLayer: Record<string, unknown>, datalayerProperties: any) {
  const deviceOrientation = datalayerProperties?.NEXT_PUBLIC_ORIENTATION_LANDSCAPE;
  const screenCategory = getScreenCategory();
  let pathType = '';
  if (Cookies.get('newSearch')) {
    pathType = datalayerProperties?.NEXT_PUBLIC_PATHTYPE_NEW ?? '';
  } else {
    pathType = datalayerProperties?.NEXT_PUBLIC_PATHTYPE_LEGACY ?? '';
  }
  dataLayer['ram_tile_names'] = datalayerProperties?.NEXT_PUBLIC_RAM_TILE_NAME;
  dataLayer['page_type'] = window.location?.href;
  dataLayer['ensightenEnabled'] = true;
  dataLayer['previous_page'] = window?.document?.referrer;
  dataLayer['search_multiRate_or_single'] = datalayerProperties?.NEXT_PUBLIC_SEARCH_SINGLE_RATE ?? '';
  dataLayer['search_res_path_type'] = pathType;
  dataLayer[
    'page_breakpt_orientation'
  ] = `${datalayerProperties?.NEXT_PUBLIC_PAGE_BREAKPT}${screenCategory} - ${window.innerWidth}w: ${deviceOrientation}`;
}

/**
 * Client side datalayer call to update specific attributes of datalayer
 * @param dataLayer | datalayer object
 * @param datalayerProperties | datalayer properties received from page context
 */
export function updateDatalayerObjForAdvSearch(dataLayer: Record<string, unknown>, datalayerProperties: any) {
  const screenCategory = getScreenCategory();
  const deviceOrientation = datalayerProperties?.NEXT_PUBLIC_ORIENTATION_LANDSCAPE;
  const tabSelected = localStorage?.getItem('advanceSearchPageTab');
  const page_url_query_string = dataLayer['page_url_query_string'];

  dataLayer[
    'page_breakpt_orientation'
  ] = `${datalayerProperties?.NEXT_PUBLIC_PAGE_BREAKPT}${screenCategory} - ${window?.innerWidth}w: ${deviceOrientation}`;
  dataLayer['ram_tile_names'] = datalayerProperties?.NEXT_PUBLIC_RAM_TILE_NAME;
  dataLayer['page_type'] = window?.location?.href;
  dataLayer['ensightenEnabled'] = true;
  dataLayer['page_data_layer_ready'] = true;
  delete dataLayer['env_api_tile_error_info'];
  delete dataLayer['page_url_query_string'];
  delete dataLayer['invokeFlushErrors'];
  delete dataLayer['search_currency_type'];
  if (tabSelected === 'Meetings') {
    dataLayer['page_url_query_string'] = page_url_query_string;
  }
  dataLayer['previous_page'] = window?.document?.referrer;
  dataLayer['isRewardRedemption'] = dataLayer['search_is_rewards_redemption'];
  delete dataLayer['ue'];
  delete dataLayer['res_context'];
  delete dataLayer['search_availability_search'];
  delete dataLayer['search_criteria_changed'];
  delete dataLayer['search_date_type'];
  /**
   * Remove the attributes if they are null or empty
   */
  const deleteIfNullOrEmpty = (key: string) => {
    if (dataLayer[key] === null || dataLayer[key] === 'null' || dataLayer[key] === '') {
      delete dataLayer[key];
    }
  };

  deleteIfNullOrEmpty('mr_prof_address_state');
  deleteIfNullOrEmpty('promoIDsEligible');
  deleteIfNullOrEmpty('mr_id_alternate');
  deleteIfNullOrEmpty('mr_prof_address_state_abbr');
  deleteIfNullOrEmpty('promoIDsRegistered');
  deleteIfNullOrEmpty('promo_special_offer_id');
  deleteIfNullOrEmpty('search_is_weekend_stay');
  deleteIfNullOrEmpty('search_date_check_in_day_of_week');
}

export const removePeriods = (date: string, replaceAll?: boolean) => {
  if (date) {
    if (replaceAll) {
      return date?.replaceAll('.', '');
    } else {
      return date?.replace('.,', ',');
    }
  } else {
    return '';
  }
};

export const getCustomFormattedDate = (date: any, currentLocale: string) => {
  switch (currentLocale) {
    case FR_LOCALE:
      return removePeriods(date, true);
    case DE_LOCALE:
      return removePeriods(date, false);
    case ES_LOCALE:
    case PT_BR_LOCALE:
    case RU_LOCALE:
      if (date?.charAt(0) !== ' ') {
        return date?.charAt(0)?.toUpperCase() + date?.slice(1);
      } else {
        return date?.charAt(1)?.toUpperCase() + date?.slice(2);
      }

    default:
      return date;
  }
};
