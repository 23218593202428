/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CardCarouselResortsProps } from './CardCarouselResorts.types';
import { StyledOfferCardsDiv } from './CardResortsWrapper.styles';
import { CardResortsWrapper } from './CardResortsWrapper';

export const CardCarouselResortsWrapperConfig = {
  emptyLabel: 'CardCarouselResorts',
  isEmpty: false,
  resourceType: `mi-aem-leisure-spa/components/cardCarouselResorts`,
};

export const CardCarouselResortsWrapper: React.FC<CardCarouselResortsProps> = props => {
  return <CardResortsWrapper {...props} />;
};

export const CardCarouselResorts = (props: any) => {
  return !props.model.isAuthor ? (
    <StyledOfferCardsDiv>
      <CardCarouselResortsWrapper {...props} />
    </StyledOfferCardsDiv>
  ) : (
    <EditableComponent config={CardCarouselResortsWrapperConfig} {...props}>
      <StyledOfferCardsDiv data-testid={'cardCarouselResort'}>
        <CardCarouselResortsWrapper {...props} />
      </StyledOfferCardsDiv>
    </EditableComponent>
  );
};
