/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import React, { useState, useEffect } from 'react';
import { StyledSortByFilterContainerDiv } from './index.styles';
import clsx from 'clsx';
// import { ApplyFiltersComponent } from '../../atoms/applyButton';
import {
  BREAKPOINT_DESKTOP_1023,
  BREAKPOINT_TABLET,
} from '../../../../../../mi-offers-components/src/constants/CommonConstants';
import { useWindowSize } from '@marriott/mi-ui-library';
import { usePersistentGlobalStore } from '@marriott/mi-store-utils';
import { getSortByVal } from '../../utils/helper';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortByFilterComponent: React.FC<any> = props => {
  const sortByLabel = props?.sortByLabel;
  const clickTrackingLoc = 'Properties Results';
  const [isSortAccordianOpen, setIsSortAccordianOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalData = usePersistentGlobalStore((state: any) => state.globalData);
  const ariesOffer = globalData?.sessionData?.cacheData?.data?.AriesOffer ?? {};
  const sortBySessionVal = ariesOffer?.['sortBy'];
  const { isStateEnabled, isCountryEnabled } = props?.destinationMetaData ?? {};
  const sortBy = getSortByVal(sortBySessionVal, { isStateEnabled: isStateEnabled, isCountryEnabled: isCountryEnabled });
  // const [currSortByOption, setCurrSortByOption] = useState<string | undefined>(props?.sortOptions[0]?.sortByOptionCode);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [activeSortByOptions, setActiveSortByOptions] = useState(
    Array.isArray(props.sortOptions)
      ? props?.sortOptions?.filter((key: any) => key.sortByOptionCode === (sortBy ?? props?.currSortByOption))[0]
      : null
  );

  const { width } = useWindowSize();

  useEffect(() => {
    setActiveSortByOptions(
      props?.sortOptions?.filter((key: any) => key.sortByOptionCode === (sortBy ?? props?.currSortByOption))[0]
    );
  }, [props?.currSortByOption, sortBySessionVal]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function expandSortByAccordian(event: any): void {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      setIsSortAccordianOpen(!isSortAccordianOpen);
    }
  }

  return (
    <StyledSortByFilterContainerDiv className={clsx('sort-list-container-de')}>
      <div
        className={clsx(
          width && width > BREAKPOINT_DESKTOP_1023
            ? `t-subtitle-l`
            : width && width > BREAKPOINT_TABLET
            ? `t-subtitle-l`
            : `t-label-alt-xs`,
          'sort-by-section'
        )}
      >
        <div className="sort-by-label">{sortByLabel}:</div>
        <div className={clsx('sort-by-options')} onClick={(e): void => expandSortByAccordian(e)}>
          <div
            className="current-state custom_click_track"
            role="button"
            tabIndex={0}
            aria-label={''}
            {...{ custom_click_track_value: `${clickTrackingLoc}| Sort-by options dropdown |internal` }}
            onKeyDown={(e): void => expandSortByAccordian(e)}
          >
            <div className="current-sort-option">{activeSortByOptions?.sortByOptionTitle}</div>
            <div className="arrow-container">
              <span className="icon-arrow-down"></span>
            </div>
          </div>
          {isSortAccordianOpen ? (
            <>
              <div className={clsx('color-scheme1', 'expanded-container', 'hotel-category')}>
                {props?.isMobileState ? (
                  <div className="t-label-alt-xs sort-options-mobile-title">
                    <h5 className="mb-0 header-heading">{sortByLabel}</h5>
                    <button
                      className="color-scheme3 t-font-s popup-close custom_click_track"
                      tabIndex={0}
                      onClick={(): void => setIsSortAccordianOpen(false)}
                      onKeyDown={(): void => setIsSortAccordianOpen(false)}
                      {...{ custom_click_track_value: `${clickTrackingLoc}| Close Button |internal` }}
                      aria-label="Close pop up"
                    >
                      <div className="icon-clear"></div>
                    </button>
                  </div>
                ) : (
                  <div className="t-label-xs sort-options-title">{sortByLabel}</div>
                )}
                <div className="sort-options-item-container">
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {props.sortOptions?.map((item: any, idx: number) => (
                    <div
                      className={`sort-option custom_click_track`}
                      key={idx}
                      role="menu"
                      tabIndex={0}
                      onBlur={(): void => {
                        if (idx === props.sortOptions.length - 1) {
                          setIsSortAccordianOpen(false);
                        }
                      }}
                      onClick={(e): void => props.handleSortBy(e, item.sortByOptionCode)}
                      onKeyDown={(e): void => props.handleSortBy(e, item.sortByOptionCode)}
                      {...{
                        custom_click_track_value: `${clickTrackingLoc}| Sort-by option: ${item?.sortByOptionTitle} |internal`,
                      }}
                    >
                      <div
                        className={
                          'sort-option-radio ' +
                          ((sortBy ?? props?.currSortByOption) === item?.sortByOptionCode ? 'active ' : '')
                        }
                      ></div>
                      <div className="t-font-s sort-option-label">{item.sortByOptionTitle}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={(window.screen.width < 767 ? 'color-scheme2' : '') + ' expanded-container-parent'}></div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </StyledSortByFilterContainerDiv>
  );
};
