export const CarouselUXLMockJson = {
  searchPropertiesByKeyword: {
    total: 3,
    status: [
      {
        code: 'SUCCESS',
        httpStatus: 200,
        messages: [],
      },
    ],
    edges: [
      {
        node: {
          id: 'ABINE',
          basicInformation: {
            bookable: true,
            name: 'Courtyard Abilene Northeast',
            legalName: 'Courtyard® by Marriott® Abilene Northeast',
            brand: {
              id: 'CY',
              name: 'Courtyard',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Abilene',
              stateProvince: {
                code: 'TX',
                label: null,
                description: 'Texas',
              },
              country: {
                code: '1',
                label: null,
                description: 'USA',
              },
            },
          },
          media: {
            id: 'ABINE',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'The Bistro Seating',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'The Bistro Seating 28212',
                  },
                },
              ],
            },
          },
          seoNickname: 'abine-courtyard-abilene-northeast',
        },
      },
      {
        node: {
          id: 'ABITS',
          basicInformation: {
            bookable: true,
            name: 'TownePlace Suites Abilene Northeast',
            legalName: 'TownePlace Suites by Marriott® Abilene Northeast',
            brand: {
              id: 'TP',
              name: 'TownePlace Suites',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Abilene',
              stateProvince: {
                code: 'TX',
                label: null,
                description: 'Texas',
              },
              country: {
                code: '1',
                label: null,
                description: 'USA',
              },
            },
          },
          media: {
            id: 'ABITS',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'Communal Lounge',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'Hotel Lobby-16703',
                  },
                },
              ],
            },
          },
          seoNickname: 'abits-towneplace-suites-abilene-northeast',
        },
      },
      {
        node: {
          id: 'AK',
          basicInformation: {
            bookable: true,
            legalName: 'The Buenaventura Golf & Beach Resort Panama, Autograph Collection®',
            name: 'The Buenaventura Golf & Beach Resort Panama, Autograph Collection',
            brand: {
              id: 'CY',
              name: 'Courtyard',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Riviera Pacifica',
              country: {
                __typename: 'Lookup',
                code: 'PA',
                description: 'Panama',
                label: null,
              },
              stateProvince: null,
            },
          },
          media: {
            id: 'PTYKP',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'The Buenaventura Golf & Beach Resort Panama',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/mg-acybg-exterior-cropped-56296:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/mg-acybg-exterior-cropped-56296:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/mg-acybg-exterior-cropped-56296:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'The Buenaventura Golf & Beach Resort Panama',
                  },
                },
              ],
            },
          },
          seoNickname: 'ptykp-the-buenaventura-golf-and-beach-resort-panama-autograph-collection',
        },
      },
    ],
  },
  searchLowestAvailableRatesByGeolocation: {
    total: 3,
    status: [
      {
        code: 'SUCCESS',
        httpStatus: 200,
        messages: [],
      },
    ],
    edges: [
      {
        node: {
          id: 'ABINE',
          basicInformation: {
            bookable: true,
            name: 'Courtyard Abilene Northeast',
            legalName: 'Courtyard® by Marriott® Abilene Northeast',
            brand: {
              id: 'CY',
              name: 'Courtyard',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Abilene',
              stateProvince: {
                code: 'TX',
                label: null,
                description: 'Texas',
              },
              country: {
                code: '1',
                label: null,
                description: 'USA',
              },
            },
          },
          media: {
            id: 'ABINE',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'The Bistro Seating',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'The Bistro Seating 28212',
                  },
                },
              ],
            },
          },
          seoNickname: 'abine-courtyard-abilene-northeast',
        },
      },
      {
        node: {
          id: 'ABITS',
          basicInformation: {
            bookable: true,
            name: 'TownePlace Suites Abilene Northeast',
            legalName: 'TownePlace Suites by Marriott® Abilene Northeast',
            brand: {
              id: 'TP',
              name: 'TownePlace Suites',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Abilene',
              stateProvince: {
                code: 'TX',
                label: null,
                description: 'Texas',
              },
              country: {
                code: '1',
                label: null,
                description: 'USA',
              },
            },
          },
          media: {
            id: 'ABITS',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'Communal Lounge',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'Hotel Lobby-16703',
                  },
                },
              ],
            },
          },
          seoNickname: 'abits-towneplace-suites-abilene-northeast',
        },
      },
      {
        node: {
          id: 'AK',
          basicInformation: {
            bookable: true,
            legalName: 'The Buenaventura Golf & Beach Resort Panama, Autograph Collection®',
            name: 'The Buenaventura Golf & Beach Resort Panama, Autograph Collection',
            brand: {
              id: 'CY',
              name: 'Courtyard',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Riviera Pacifica',
              country: {
                __typename: 'Lookup',
                code: 'PA',
                description: 'Panama',
                label: null,
              },
              stateProvince: null,
            },
          },
          media: {
            id: 'PTYKP',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'The Buenaventura Golf & Beach Resort Panama',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/mg-acybg-exterior-cropped-56296:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/mg-acybg-exterior-cropped-56296:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/mg-acybg-exterior-cropped-56296:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'The Buenaventura Golf & Beach Resort Panama',
                  },
                },
              ],
            },
          },
          seoNickname: 'ptykp-the-buenaventura-golf-and-beach-resort-panama-autograph-collection',
        },
      },
    ],
  },
};
export const CarouselUXLMockJsonDTT = {
  search: {
    properties: {
      searchByGeolocation: {
        total: 222,
        edges: [
          {
            node: {
              id: 'NYCES',
              basicInformation: {
                name: 'Courtyard New York Manhattan/Fifth Avenue',
                legalName: 'Courtyard® by Marriott® New York Manhattan/Fifth Avenue',
                brand: {
                  id: 'CY',
                  name: 'Courtyard',
                  type: 'Marriott',
                  photos: [
                    {
                      content: [
                        {
                          index: 1,
                          name: 'cy-cy-lobby-pod-29210',
                          url: 'https://cache.marriott.com/is/image/marriotts7prod/cy-cy-lobby-pod-29210:Feature-Hor',
                          alternateText: 'Two women huddled at corner table working together',
                        },
                        {
                          index: 2,
                          name: 'cy-cy-business-breakfast-12963',
                          url: 'https://cache.marriott.com/is/image/marriotts7prod/cy-cy-business-breakfast-12963:Feature-Hor',
                          alternateText: 'Man eating breakfast while working.',
                        },
                        {
                          index: 3,
                          name: 'cy-main03-0001',
                          url: 'https://cache.marriott.com/content/dam/marriott-renditions/CY/cy-main03-0001-hor-feat.jpg',
                          alternateText: 'Courtyard Hotel Photo',
                        },
                      ],
                      type: {
                        code: 'primary',
                      },
                    },
                  ],
                },
                resort: false,
                isAdultsOnly: false,
                newLobby: false,
                hasRenovatedRooms: false,
                isFullyRenovated: false,
                isRecentlyRenovated: false,
                newProperty: false,
                bookable: true,
              },
              contactInformation: {
                address: {
                  city: 'New York',
                  stateProvince: {
                    code: 'NY',
                    label: null,
                    description: 'New York',
                  },
                  country: {
                    code: '1',
                    label: null,
                    description: 'USA',
                  },
                },
              },
              media: {
                id: 'NYCES',
                primaryImage: {
                  total: 1,
                  edges: [
                    {
                      node: {
                        caption: 'Queen/Queen Guest Room',
                        imageUrls: {
                          featureVertical: '',
                          classicVertical: '',
                          square: '/content/dam/marriott-renditions/NYCES/nyces-guestroom-0031-sq.jpg',
                        },
                        isPrimary: true,
                        title: 'nyces-guestroom-0031',
                      },
                    },
                  ],
                },
              },
              seoNickname: 'nyces-courtyard-new-york-manhattan-fifth-avenue',
            },
          },
        ],
      },
      searchByKeyword: {
        total: 45,
        edges: [
          {
            node: {
              id: 'MSPOS',
              basicInformation: {
                name: 'Sheraton Bloomington Hotel',
                legalName: 'Sheraton Bloomington Hotel',
                brand: {
                  id: 'SI',
                  name: 'Sheraton',
                  type: 'SPG',
                  photos: [
                    {
                      content: [
                        {
                          index: 1,
                          name: 'si_main01',
                          url: 'https://cache.marriott.com/is/image/marriotts7prod/si-main-001-hor-clsc:Feature-Hor',
                          alternateText: 'Sheraton Hotel photography',
                        },
                        {
                          index: 2,
                          name: 'si_main02',
                          url: 'https://cache.marriott.com/content/dam/marriott-renditions/SI/si-main-002-hor-feat.jpg',
                          alternateText: 'Sheraton Hotel photography',
                        },
                        {
                          index: 3,
                          name: 'si_main03',
                          url: 'https://cache.marriott.com/content/dam/marriott-renditions/SI/si-main-003-hor-feat.jpg',
                          alternateText: 'Sheraton Hotel photography',
                        },
                      ],
                      type: {
                        code: 'primary',
                      },
                    },
                  ],
                },
                resort: false,
                isAdultsOnly: false,
                newLobby: false,
                hasRenovatedRooms: false,
                isFullyRenovated: false,
                isRecentlyRenovated: false,
                newProperty: false,
                bookable: true,
              },
              contactInformation: {
                address: {
                  city: 'Bloomington',
                  stateProvince: {
                    code: 'MN',
                    label: null,
                    description: 'Minnesota',
                  },
                  country: {
                    code: '1',
                    label: null,
                    description: 'USA',
                  },
                },
              },
              media: {
                id: 'MSPOS',
                primaryImage: {
                  total: 1,
                  edges: [
                    {
                      node: {
                        caption: 'Lobby Lounge',
                        imageUrls: {
                          featureVertical: '',
                          classicVertical: '',
                          square: '/content/dam/marriott-renditions/MSPOS/mspos-lobby-lounge-5680-sq.jpg',
                        },
                        isPrimary: true,
                        title: 'mspos-lobby-lounge-5680',
                      },
                    },
                  ],
                },
              },
              seoNickname: 'mspos-sheraton-bloomington-hotel',
            },
          },
        ],
        status: [
          {
            code: 'SUCCESS',
            httpStatus: 200,
            messages: [],
          },
        ],
      },
    },
  },
};
