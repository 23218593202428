/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef } from 'react';
import { StyledTag } from './Heading.styles';
import { ComponentTitleProps } from './Heading.types';
import clsx from 'clsx';
import { headingType, size, tags } from '../../utils/enums/enums';
// import { RichText } from '../../molecules/RichText';

export const Heading: FC<ComponentTitleProps> = ({
  variation,
  fontSize = size.medium,
  element,
  titleText,
  customClass,
  clickTrackingLoc,
  disableCustomClickTrack = false,
  dangerouslySet,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const updateAnchorAriaLabels = () => {
    const container = containerRef.current;
    if (!container) return;

    container.querySelectorAll('a').forEach(anchorTag => {
      const parentSpan = anchorTag.closest('span');
      if (parentSpan) {
        if (parentSpan.classList.contains('m-link-tertiary-button-external')) {
          anchorTag.setAttribute('aria-label', `${anchorTag.textContent} Clickable link Opens in new tab / window`);
        } else if (parentSpan.classList.contains('m-link-action')) {
          anchorTag.setAttribute('aria-label', `${anchorTag.textContent} Clickable link`);
        } else {
          anchorTag.removeAttribute('aria-label');
        }
      } else {
        const target = anchorTag.getAttribute('target');
        if (target === '_blank') {
          anchorTag.setAttribute('aria-label', `${anchorTag.textContent} Clickable link Opens in new tab / window`);
        } else if (target === '_self') {
          anchorTag.setAttribute('aria-label', `${anchorTag.textContent} Clickable link`);
        } else {
          anchorTag.removeAttribute('aria-label');
        }
      }
    });
  };

  useEffect(() => {
    updateAnchorAriaLabels();

    const observer = new MutationObserver(updateAnchorAriaLabels);
    if (containerRef.current) {
      observer.observe(containerRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [dangerouslySet, titleText]);

  // Location value not valid if undefined or empty string
  const hasValidClickTrackingLoc = clickTrackingLoc && clickTrackingLoc.length > 0;
  // Title / tracking description not valid if undefined or empty string
  const hasValidTitleText = titleText && titleText.length > 0;
  // Do not print custom click track attributes if disabled, loc invalid, or desc invalid
  const doCustomClickTrack = !disableCustomClickTrack && hasValidClickTrackingLoc && hasValidTitleText;

  const titleTags: any = {
    [size.small]: tags.h3,
    [size.medium]: tags.h2,
    [size.large]: tags.h1,
  };
  const subtitleTags: any = {
    [size.medium]: tags.h6,
    [size.large]: tags.h5,
    [size.extraLarge]: tags.h4,
  };
  let Tag;
  if (element) {
    Tag = element;
  } else {
    Tag =
      variation === headingType.title
        ? titleTags[fontSize] || titleTags[size.medium]
        : subtitleTags[fontSize] || subtitleTags[size.large];
  }

  // dangerouslyset prop is used for injecting text in header tag which may hold superscript/subscript tags.
  // please use removeParentTagRTE function from utils on the text to fetch required text from the RTE text

  return dangerouslySet ? (
    <StyledTag
      ref={containerRef}
      as={Tag}
      data-component-name="a-ui-library-Heading"
      data-testid="ui-library-Heading"
      {...(doCustomClickTrack
        ? {
            custom_click_track_value: `${clickTrackingLoc}| ${titleText} tab |internal`,
          }
        : {})}
      className={clsx(doCustomClickTrack ? 'custom_click_track' : '', `t-${variation}-${fontSize}`, customClass)}
      {...props}
      dangerouslySetInnerHTML={{ __html: dangerouslySet }}
    />
  ) : (
    <Tag
      data-component-name="a-ui-library-Heading"
      data-testid="ui-library-Heading"
      {...(doCustomClickTrack
        ? {
            custom_click_track_value: `${clickTrackingLoc}| ${titleText} tab |internal`,
          }
        : {})}
      className={clsx(doCustomClickTrack ? 'custom_click_track' : '', `t-${variation}-${fontSize}`, customClass)}
      {...props}
    >
      {titleText}
    </Tag>
  );
};
