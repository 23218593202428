import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem } from '../../styles';
import { StyledCalendarDivProps, StyledCalendarFooterProps } from './Calendar.types';
import { VARIATION_VERTICAL } from './Calendar.controller';

export const StyledCalendarDiv = styled.div<StyledCalendarDivProps>`
  ${rtl`
  width:100%;
  margin:0 auto;
  position: relative;
  @media ${baseVariables.mediaQuery.md}{
    display:flex;
    justify-content:center;
  }
  .DayPicker-tooltip-value{
    cursor:none;
    pointer-events:none;
    background:${baseVariables.color['base10']};
    z-index:101;
    border-radius:${baseVariables.border['borderRadius02']};
    position:absolute;
    &:after{
      pointer-events:none;
      content: "";
      position: absolute;
      border: 0;
      bottom:  ${toRem(-8)};
      border-top:  ${toRem(11)} ${baseVariables.border['borderStyleDefault']} ${baseVariables.color['base10']};
      left: 0;
      right: 0;
      margin: 0 auto;
      border-left: ${toRem(8)} ${baseVariables.border['borderStyleDefault']} transparent;
      border-right: ${toRem(8)} ${baseVariables.border['borderStyleDefault']} transparent;
      width:${toRem(8)};
    }
  }
.DayPicker{
  .DayPicker-wrapper{
    background-color: ${baseVariables.color['merch50']};
    .DayPicker-NavBar{
      position:relative;
      display:${(props: StyledCalendarDivProps): string => (props.variation === VARIATION_VERTICAL ? 'none' : `flex`)};
      .DayPicker-NavButton{
        background:none;
        color: ${baseVariables.color['base10']};
        position:absolute;
        &.DayPicker-NavButton--interactionDisabled{
          display:block;
          color: ${baseVariables.color['neutral30']};
          cursor:unset;
        }
        &.DayPicker-NavButton--next{
          right: ${toRem(1)};
          top: ${toRem(10)};
          &:before {
          content: '\\e922';
          height: 100%;
          width: 100%;
          font-family: ${baseVariables.font['fontMiIcons']};
          font-size: ${baseVariables.font['fontXxxl']};
          }
          &:dir(rtl) {
            left: auto;
            &::before {
              content: '\\e921';
            }
          }
        }
        &.DayPicker-NavButton--prev{
          left: ${toRem(-3)};
          top: ${toRem(10)};
          &:before{
            content: '\\e921';
            height: 100%;
            width: 100%;
            font-family: ${baseVariables.font['fontMiIcons']};
            font-size: ${baseVariables.font['fontXxxl']};
          }
          &:dir(rtl) {
            margin-inline-end: 0;
            &::before {
              content: '\\e922';
            }
          }
        }
        ${(props: StyledCalendarDivProps): string =>
          props.isRtl === true
            ? `&.DayPicker-NavButton--next{
          right: auto;
          left: ${toRem(10)};
          &:before {
          content: '\\e921';   
          }
        }
        &.DayPicker-NavButton--prev{
          right: ${toRem(6)};
          margin-right: 0;
          &:before{
            content: '\\e922';   
          }
        }`
            : ``};
       
      }
    }
    .DayPicker-Months{
      display:${(props: StyledCalendarDivProps): string => (props.variation === VARIATION_VERTICAL ? 'unset' : `flex`)};
      .DayPicker-Month{
        margin:0;
        margin-top:${toRem(14)};
        margin-right:${(props: StyledCalendarDivProps): string =>
          props.variation === VARIATION_VERTICAL ? `auto` : toRem(14)};
        margin-left:auto;
        width:${(props: StyledCalendarDivProps): string =>
          props.variation === VARIATION_VERTICAL ? `calc(100vw - ${toRem(60)})` : 'auto'};
        &~.DayPicker-Month{
          margin-left: ${(props: StyledCalendarDivProps): string =>
            props.variation === VARIATION_VERTICAL ? toRem(0) : toRem(16)};
          margin-right:0;
        }
        .DayPicker-Caption{
          padding-left:${(props: StyledCalendarDivProps): string =>
            props.variation === VARIATION_VERTICAL ? toRem(0) : toRem(8)};
        }
        .DayPicker-Caption, .DayPicker-Caption>* {
          font-family: ${baseVariables.font['fontSwiss']};
          font-size: ${baseVariables.font['fontM']};
          text-align:${(props: StyledCalendarDivProps): string =>
            props.variation === VARIATION_VERTICAL ? 'left' : 'center'};
        }
        .DayPicker-Weekdays{
          border-bottom:${toRem(1)} solid ${baseVariables.color['neutral20']};
          .DayPicker-Weekday{
            padding-bottom:${toRem(8)};
            color:${baseVariables.color['neutral40']};
            font-size:${toRem(12)};
            line-height:${toRem(16)};
          }
        }
        .DayPicker-Body{
          .DayPicker-Week{
            .DayPicker-Day{
              padding:0;
              background:none;
              position:relative;
              z-index:3;
              &.DayPicker-Day--hoverRange{
                .DayPicker-Day-Cell{
                  background-color:${baseVariables.color['neutral10']};
                  border-radius:${baseVariables.border['borderRadiusNone']};
                }
                &.DayPicker-Day--fromFocus{
                  .DayPicker-Day-Cell{
                    border-top-left-radius:${baseVariables.border['borderRadius05']};
                    border-bottom-left-radius:${baseVariables.border['borderRadius05']};
                  }
                }
                &.DayPicker-Day--endHover{
                  .DayPicker-Day-Cell{
                    border-top-right-radius:${baseVariables.border['borderRadius05']};
                    border-bottom-right-radius:${baseVariables.border['borderRadius05']};
                  }
                }
              }
              &:hover,&:focus{
                background-color:none;
              }
              &.DayPicker-Day--outside{
                cursor:auto;              
                .DayPicker-Day-Cell{
                  .DayPicker-Day-value{
                    color:${baseVariables.color['neutral40']};
                  }
                }
              }
              .DayPicker-Day-Cell{
                .DayPicker-Day-value{
                 height:100%;
                 width:100%;
                 font-family: ${baseVariables.font['fontSwiss']};
                }
              }
              &.DayPicker-Day--selected:not(.DayPicker-Day--outside){
                &.DayPicker-Day--start:not(.DayPicker-Day--outside){
                  position:relative;
                  &:after{
                    content:"";
                    background-color:${baseVariables.color['neutral10']};
                    border-radius:50%;
                    border-top-right-radius:0;
                    border-bottom-right-radius:0;
                    width:50%;
                    height:100%;
                    position:absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                  }
                  .DayPicker-Day-Cell{
                    border-radius:0;
                    background-color:${baseVariables.color['neutral10']};
                    border-top-left-radius:${baseVariables.border['borderRadius05']};
                    border-bottom-left-radius:${baseVariables.border['borderRadius05']};
                    .DayPicker-Day-value{
                      background-color:${baseVariables.color['base10']};
                      color:${baseVariables.color['base20']};
                      border-radius:${baseVariables.border['borderRadius05']};
                    }
                  }
                }
                &.DayPicker-Day--end:not(.DayPicker-Day--outside){
                  &:before{
                    content:"";
                    background-color:${baseVariables.color['neutral10']};
                    border-radius:50%;
                    border-top-left-radius:0;
                    border-bottom-left-radius:0;
                    width:50%;
                    height:100%;
                    position:absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                  }
                  .DayPicker-Day-Cell{
                    border-radius:0;
                    background-color:${baseVariables.color['neutral10']};
                    border-top-right-radius:50%;
                    border-bottom-right-radius:50%;
                    .DayPicker-Day-value{
                      background-color:${baseVariables.color['base10']};
                      color:${baseVariables.color['base20']};
                      border-radius:50%;
                    }
                  }
                }
                &:not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end){
                  &.DayPicker-Day--select{
                    .DayPicker-Day-Cell{
                      .DayPicker-Day-value{
                        background-color:${baseVariables.color['base10']};
                        border-radius:50%;
                        color:${baseVariables.color['base20']};
                      }
                    }
                  }
                  &:not(.DayPicker-Day--select):not(.DayPicker-Day--outside){
                    background-color:${baseVariables.color['neutral10']};
                    border-radius:0 !important;
                    color:${baseVariables.color['base10']};
                    .DayPicker-Day-Cell{
                      .DayPicker-Day-value{
                        background-color:${baseVariables.color['neutral10']};
                        border-radius:0;
                        color:${baseVariables.color['base10']};
                      }
                    }
                  }
                }
                &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
                  .DayPicker-Day-Cell{
                    .DayPicker-Day-value{
                      color:${baseVariables.color['base20']};
                    }
                  }
                }
                &.DayPicker-Day--start{
                  border-bottom-left-radius:50%;
                  border-top-left-radius:50%;
                }
                &.DayPicker-Day--end{

                }
              }
              &.DayPicker-Day--disabled{
                .DayPicker-Day-Cell{
                  .DayPicker-Day-value{
                    color:${baseVariables.color['neutral30']};
                  }
                }
              }
              &.DayPicker-Day--outside{
                visibility: hidden;
                .DayPicker-Day-Cell{
                  .DayPicker-Day-value{
                    color: transparent;
                  }
                }
              }
              .DayPicker-Day-Cell{
                width:${toRem(40)};
                height:${toRem(40)};
                position:relative;
                z-index:2;
                margin:${(props: StyledCalendarDivProps): string =>
                  props.variation === VARIATION_VERTICAL ? `0 auto` : '0'};
              }
              &.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end){
                border-radius: 0 !important;
              
            }
            }
          }
        }
      }
    }
  }
}
 `}
`;

export const StyledFooterDiv = styled.div<StyledCalendarFooterProps>`
  background: ${(props: StyledCalendarFooterProps): string => props.backGround ?? baseVariables.color['merch50']};
`;
