/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { Container } from '@adobe/aem-react-editable-components';
import { respGridUtil } from '@marriott/mi-headless-utils';

export const ExperienceFragmentDestinationHero = (props: any) => {
  const ExperienceFragmentVariationConfig = {
    emptyLabel: 'Experience Fragment Destination Hero',
    resourceType: 'mi-aem-leisure-spa/components/experienceFragmentDestinationHero',
    isEmpty: function (props: { configured: any }) {
      return !props || !props.configured;
    },
  };
  return (
    <Container
      {...ExperienceFragmentVariationConfig}
      isInEditor
      {...props}
      isPage={false}
      model={respGridUtil(props?.model?.cqItems?.root?.[':items']?.responsivegrid)}
      className={props?.model?.appliedCssClassNames}
    />
  );
};
