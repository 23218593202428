export const DETAILS_SHOWN = 'details-visible';
export const DETAILS_HIDDEN = 'details-hidden';
export const DETAILS = 'See Details';
export const SHOW_MORE = 'Show More';
export const SHOW_LESS = 'Show Less';
export const LIST_HEADING = 'More Offers At This Property';
export const OFFER_VIEW_ALL_OFFERS =
  '/offers/submitOfferSearch.mi?destinationAddress.placeId=&includeUnknownRateCategoryForAssociate=&autoSuggestItemType=&destinationAddress.types=&destinationAddress.latitude=$lat&for-hotels-nearme=Near&suggestionsPropertyCode=&destinationAddress.stateProvinceShort=$state&isInternalSearch=true&destinationAddress.country=$country&destinationAddress.name=&poiCity=&destinationAddress.cityPopulation=&destinationAddress.countryShort=$country&vsInitialRequest=false&marriottBrands=&searchType=InCity&poiName=&destinationAddress.address=&search-countryRegion=&collapseAccordian=is-hidden&destinationAddress.locality=&destinationAddress.stateProvinceDisplayName=&singleSearch=true&destinationAddress.destinationPageDestinationAddress=&destinationAddress.cityPopulationDensity=&destinationAddress.secondaryText=&destinationAddress.postalCode=&destinationAddress.city=$city&destinationAddress.mainText=&countryName=&destinationAddress.longitude=$long&destinationAddress.stateProvince=$state&initialRequest=false&singleSearchAutoSuggest=false&destinationAddress.website=&search-locality=&js-location-nearme-values=&destinationAddress.destination=$destination&fromToDate=&fromToDate_submit=&fromDate=$from&toDate=$to&toDateDefaultFormat=&fromDateDefaultFormat=&flexibleDateSearch=false&t-start=null&t-end=null&lengthOfStay=1&keywords=';
export const DATE_FORMAT_VALUE = 'MM/DD/YYYY';
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const OFFERS_CARDS_ALLOWED_NUM = 4;
export const API_QUERY_LIMIT = 40;
export const API_FETCH_POLICY = 'cache-and-network';
export const API_SSL_CHECK_FALSE = 'FALSE';
export const API_ACCEPT_LANGUAGE = 'en-US';
export const API_ACCEPT_CAPTION_LENGTH = 'MEDIUM';
export const API_ACCEPT_ASPECT_RATIO_SQUARE = 'SQUARE';
export const API_ACCEPT_ASPECT_RATIO_WIDE = 'WIDE';
export const SERVER_SEARCH_BY_DESTINATION = 'DESTINATION';
export const IMAGE_SQUARE_COMPRESS_PARAM = '?resize=*:232';
export const IMAGE_CLASSIC_COMPRESS_PARAM = '?resize=632:*';
export const OFFER_VIEW_GRID = 'GRID_VIEW';
export const OFFER_VIEW_GALLERY = 'CAROUSEL_VIEW';
export const NO_OF_CARDS_TABLET = 2;
export const OFFER_CAROUSEL_URL = 'https://www.marriott.com/offers.mi';
export const OFFER_CAROUSEL_URL_REFERRER = 'www.marriott.com';
export const OFFER_CAROUSEL_ERROR_POLICY = 'ignore';
export const OFFER_CARDLIST_ERROR_POLICY = 'ignore';
export const OFFER_CAROUSEL_CHANNEL = 'web';
export const OFFER_RECIPE_NONE = 'none';
export const OFFER_RECIPE_PROPERTY_NON_STAY = 'popularPropertyNonStayOffers';
export const API_TIMEOUT = 2500;
export const ASPECT_RATIO_SQUARE = 'Square';
export const ASPECT_RATIO_WIDE = 'Wide';
export const CARD_LAYERED = 'cardlayered';
export const THREE_PACK_CARDS = 3;
export const FOUR_PACK_CARDS = 4;
export const TWO_PACK_CARDS = 2;
export const IMAGE_ALT_TEXT = 'Sunrise over the clouds';
export const IMPRESSION_TRACK = false;
export const CLICK_TRACK = false;
export const CARD_LAYERED_VARIATION = 'square';
export const CARD_LAYERED_WIDE = 'wide';
export const MERCHANDISING_CATEGORY = 'nonCobrand';
export const DEFAULT_API_ACCEPT_LANGUAGE = 'en-US';
export const OFFER_SEARCH_CLIENT_NAME = 'phoenix_offers';
export const MBOX_COOKIE = 'mbox';
export const ADOBE_COOKIE = 'AMCV_664516D751E565010A490D4C@AdobeOrg';
export const CARD_CONTENTS_LEFT = 'left';
export const API_ACCEPT_ASPECT_RATIO_CLASSIC = 'CLASSIC';
export const IMAGE_LEFT = 'left';
export const CARD_HORIZONTAL = 'horizontalFeatureList';
export const CARD_SQUARE = 'layeredSquare';
export const CARDSTYPE_LAYERED = 'cardlayered';
export const CARDSTYPE_VERTICAL = 'cardvertical';
export const MEMBER_EXCLUSIVE = ['A', 'G', 'M', 'P', 'S', 'T'];
export const PREVIEW_LIST_HEADING = 'Horizontal Offer Cards';
export const PREVIEW_LIST_DESC =
  'These card will appear on Single Property Offer pages, linking to other offers found at the same hotel. They may also be used across other parts of Marriott websites to showcase offers';
export const LAYERED_WIDE = 'layeredWide';
export const VARIATION_INVERSE = 'inverse';
export const RESORT_OFFERS_RECIPE = 'resortOffers';
export const DESTINATION_OFFERS_RECIPE = 'destinationOffers';
export const OFFER_RECIPE_ELITE_EXCLUSIVE = 'eliteExclusiveOffers';
export const MERCHANDISING_OFFER = 'Merchandising Offer';
export const MARRIOTT_DOMAIN_CN = 'https://www.marriott.com.cn';
