const TrackingPropertiesMock = {
  additionalTrackingVariables: '',
  enableScrollingBehavior: false,
  trackingDescription: '',
  impressionTrack: false,
  description: '',
  trackingContentPosition: '',
  atCCeVar48: '',
  rightArrowDesc: '',
  cardLocation: '',
  leftArrowDesc: '',
  clickTrack: false,
  location: '',
  merchandisingCategory: 'nonCobrand',
  impressionCount: false,
  trackingTag: '',
  trackingOfferType: '',
};

export const DynamicPropertyCardCarouselMockData = {
  dynamicpropertycardc: {
    configured: true,
    componentId: 'hello',
    localizedFragmentVariationPath:
      '/content/experience-fragments/mi-aem-leisure-spa/us/en/site/brand-ribbon/master/jcr:content',
    dataLayer: {
      'dynamicPropertyCardCarousel-7b4b2c0149': {
        '@type': 'mi-aem-leisure-spa/components/dynamicPropertyCardCarousel',
        'repo:modifyDate': '2024-07-17T14:07:03Z',
      },
    },
    styleclass: 'standard',
    styleclass: 'standard',
    trackingProperties: TrackingPropertiesMock,
    wcmMode: 'disabled',
    isAuthor: true,
    cqItems: {
      cardcarouselresorts_0: {
        amenities: [],
        componentId: 'slgllgvecfr9bkis',
        regions: [],
        dataLayer: null,
        mobileTitle: '<Amentities | Activites> Hotels in <Destination>',
        renovatedRoomsLabel: 'renovatedRoomsLabel',
        noOfCards: 3,
        cardsType: 'layeredSquare',
        ctaLabel: 'View All <Amentities | Activites> destinations',
        trackingProperties: TrackingPropertiesMock,
        variation: 'standard',
        isAuthor: false,
        subTitleText: '<Destination>',
        path: null,
        ctaType: 'tertiaryLink',
        ':type': 'mi-aem-leisure-spa/components/cardCarouselResorts',
        openInaNewTab: 'false',
        titleText: '<Amentities | Activites> Hotels in <Destination>',
        maxResortsCount: 16,
        styleclass: 'standard',
        id: 'cardcarouselresorts_0',
        ctaLink: '/hotel-search.mi?filtersApplied=true&property-types=resort&activities-on-site=beach',
        cardTheme: 'inverse',
        author: false,
        styleclass: 'standard',
        newHotelLabel: 'newHotelLabel',
        fullyRenovatedLabel: 'FULLY RENOVATED',
        recentlyRenovatedLabel: 'RECENTLY RENOVATED',
        activities: ['beach'],
        hotelType: 'resort',
        wcmMode: 'disabled',
        adultsOnlyLabel: 'ADULTS ONLY',
      },
    },
    ':itemsOrder': ['cardcarouselresorts_0'],
    path: '/content/mi-aem-leisure-spa/na/en-us/destinations/usa/texas/dallas/jcr:content/root/responsivegrid/dynamicpropertycardc',
    ':type': 'mi-aem-leisure-spa/components/dynamicPropertyCardCarousel',
    id: 'dynamicPropertyCardCarousel-7b4b2c0149',
  },
};
