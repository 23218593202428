export const SUCCESS = 'SUCCESS';
export const REDIRECT_TO_URI = 'REDIRECT_TO_URI';
export const EXPIRED_OFFER = 'EXPIRED_OFFER';
export const NOT_FOUND = 'NOT_FOUND';
export const SERVER_ERROR = 'SERVER_ERROR';
export const INVALID_OFFER = 'INVALID_OFFER';
export const ERROR_PATH = '/error.mi';
export const OFFERS_APP_DOMAIN =
  process.env['OFFERS_APP_DOMAIN'] ??
  'http://mi-offers-app-phoenix-offersdev1.dev-r.aws-use1.cloud.marriott.com/offers/details/';
export const DEFAULT_INVALID_URI =
  process.env['DEFAULT_INVALID_URI'] ??
  'en_US=www.marriott.com/offers.mi,en_GB=www.marriott.com/en-gb/offers.mi,de_DE=www.marriott.de/offers.mi,it_IT=www.marriott.it/offers.mi,ru_RU=www.marriott.com.ru/offers.mi,ko_KR=www.marriott.co.kr/offers.mi,pt_BR=www.marriott.com.br/offers.mi,zh_CN=www.marriott.com.cn/offers.mi,fr_FR=www.marriott.fr/offers.mi,ja_JP=www.marriott.co.jp/offers.mi,pt_PT=www.marriott.pt/offers.mi,es_ES=www.marriott.es/offers.mi';
export const INVALID_OFFERS_PARAM = process.env['INVALID_OFFERS_PARAM'] ?? '?invalidDealMsg=true';
export const INVALID_OSERP_PARAM = '/offers/findOffers.mi?invalidDealMsg=true';
export const UXL_ERROR_POLICY = 'ignore';
export const OFFERS_HUB_PATH = '/offers.mi';
export const INELIGIBLE_OSERP_PARAM = '/offers/findOffers.mi?notEligible=true';
export const INVALID_USER_OSERP_PARAM = '/offers/findOffers.mi?invalidUserMsg=true';
export const INVALID_USER_HUB_PARAM = '/offers.mi?invalidUserMsg=true';
export const SIGN_IN_REDIRECT_FLOW = 'signInRedirectFlow';
export const SIGN_IN_PAGE_URI = '/sign-in.mi?returnTo=';
export const SIGN_IN_URI = 'sign-in.mi';
export const INELIGIBLE_OFFERS_PARAM = '/offers.mi?ineligible=true';
export const MARRIOTT_CACHE_URL = 'https://cache.marriott.com/';
