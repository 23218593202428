/* eslint-disable @typescript-eslint/no-explicit-any */
export const updateTrackingProperties = (data: any, componentname: string) => {
  const cardsArr: any = [];
  Object.keys(data.cqItems).forEach(key => {
    const item = data.cqItems[key][':items'][componentname];
    const { header, ctaLinkText, trackingProperties } = item;
    if (item && item.trackingProperties) {
      item.trackingProperties.location = header || trackingProperties?.location;
      item.trackingProperties.cardLocation = header || trackingProperties?.cardLocation;
      item.trackingProperties.description = ctaLinkText || trackingProperties?.description;
      item.trackingProperties.trackingDescription = ctaLinkText || trackingProperties?.trackingDescription;
    }
    cardsArr.push(item);
  });
  return cardsArr;
};
