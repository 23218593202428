// Imports for external libraries go here.
import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { StyledSignInButton, AccountDrawer, StyledSignInDrawer } from './SignInDrawer.styles';
import { AccountPanel } from './AccountPanel';
import { SignInDrawerProps } from './SignInDrawer.types';
import { HeaderLogoProps, canUseDOM } from '@marriott/mi-ui-library';
import { MemberPanelProps } from './MemberPanel';
import { SignInFormProps } from './SignInForm';
import { useUserDetailsStore } from '@marriott/mi-store-utils';
import { getClickTrackValue } from '../../utils/TrackingPropertyUtils';
import { DEFAULT_TRACKING_VALUES } from '../../utils/constants';
import { useNavContext } from '../../organisms/NavigationContainer/NavigationContextProvider';

// Use named rather than default exports.
export const SignInDrawer: FC<SignInDrawerProps> = props => {
  const { logoUrl, logoImageAltText, logoType, restProps, renderUtilityLink } = props;
  const {
    joinNowLabel,
    becomeMemberLabel,
    becomeMemberUrl,
    signInLabel,
    memberNumberLabel,
    loginPasswordLabel,
    loginPasswordPlaceHolder,
    remeberMeLabel,
    signInButtonLabel,
    signInButtonUrl,
    joinNowSubHeading,
    forgotPasswordLinkLabel,
    forgotPasswordLinkPath,
    activeAccountLinkUrl,
    activateAccountLinkLabel,
    clearRememberedAccountLabel,
    clearRememberedAccountUrl,
    welcomeText,
    signInSubHeading,
    pointsLabel,
    buyPointsLinkLabel,
    buyPointsLinkpath,
    logoutLinkLabel,
    logoutLinkPath,
    warningMessage,
    credentialsWarningMessage,
    lockoutWarningMessage,
    lockedWarningMessage,
    auditWarningMessage,
    userDetailsEndpoint,
  } = restProps.resourceProperties ?? {};

  const { offset, navRef, isSignInDrawerOpen, setIsSignInDrawerOpen } = useNavContext();

  const [top, setTop] = useState<number | string>(offset);
  // TODO: When Signed In story is implemented we should be using Zustand to access data from store.
  const togglePanel = () => {
    setIsSignInDrawerOpen(prevState => {
      return !prevState;
    });
  };
  const userProfileData = useUserDetailsStore(state => state.userProfileData);
  const isRememberedUser = useUserDetailsStore(state => state.isRememberedUser);
  const isSignedInUser = useUserDetailsStore(state => state.isSignedInUser);
  const fetchUserDetails = useUserDetailsStore(state => state.fetchUserDetails);

  const memberName = userProfileData?.headerSubtext?.consumerName;

  const recognizedUserButtonText = isRememberedUser ? `${signInLabel}, ${memberName}` : memberName;

  const buttonText =
    isSignedInUser || isRememberedUser ? recognizedUserButtonText : restProps?.resourceProperties?.['cq:panelTitle'];
  const signInButtonClass = `nav---account${isSignedInUser || isRememberedUser ? '-alt-active' : ''}`;

  const signInErrorMessages = {
    warningMessage,
    credentialsWarningMessage,
    lockoutWarningMessage,
    lockedWarningMessage,
    auditWarningMessage,
    pwrdErrorMessage: restProps.pwrdErrorMessage,
    emailErrorLabel: restProps.emailErrorLabel,
    errorForUserName: restProps.errorForUserName ?? '',
    errorForPassword: restProps.errorForPassword ?? '',
  };

  const signInFormProps: SignInFormProps = {
    signInLabel,
    memberNumberLabel,
    loginPasswordLabel,
    loginPasswordPlaceHolder,
    remeberMeLabel,
    signInButtonLabel,
    signInButtonUrl,
    forgotPasswordLinkLabel,
    forgotPasswordLinkPath,
    activateAccountLinkLabel,
    activeAccountLinkUrl,
    clearRememberedAccountLabel,
    clearRememberedAccountUrl,
    welcomeText,
    signInSubHeading,
    signInErrorMessages,
    joinNowLabel,
    joinNowSubHeading,
    becomeMemberLabel,
    becomeMemberUrl,
  };
  const memberPanelProps: MemberPanelProps = {
    joinNowLabel,
    joinNowSubHeading,
    becomeMemberLabel,
    becomeMemberUrl,
    pointsLabel,
    buyPointsLinkLabel,
    buyPointsLinkpath,
    memberPanelCardLinks: restProps?.listItems,
    logoutLinkLabel,
    logoutLinkPath,
    memberBenefitsLabel: restProps.memberBenefitsLabel,
  };
  const headerLogoProps: HeaderLogoProps = {
    logoUrl,
    logoImageAltText,
    logoType,
    customClassName: 'panelLogo',
    isClickable: false,
  };

  const clickTrackValue = getClickTrackValue({
    trackingProperties: {
      trackingContentPosition: restProps?.trackingProperties?.trackingContentPosition,
    },
    url: restProps['cq:panelUrl'],
    fallbacks: {
      description: restProps?.resourceProperties?.['cq:panelTitle'],
      position: DEFAULT_TRACKING_VALUES.GLOBAL_NAV,
    },
  });

  const navBottom = navRef?.current?.getBoundingClientRect().bottom;

  useEffect(() => {
    if (canUseDOM) {
      // Populate the userDetailsStore with user information for nav components to consume.
      // TODO: This should be called higher up in the global nav, since other parts
      // of the global nav consume user details information.
      fetchUserDetails(userDetailsEndpoint);
    }
  }, []);

  useEffect(() => {
    // Getting the difference between top of window and bottom of the navbar. It's not exact, but it allows for the positioning of the signin panel at the top of each given view
    const offsetDifference = window.scrollY - navBottom;
    if (canUseDOM) {
      // TODO: fix nested if statement
      if (offsetDifference > 0) {
        setTop(0);
      } else {
        setTop('inherit');
      }
    }
  }, [navBottom]);

  return (
    <StyledSignInDrawer>
      <StyledSignInButton
        data-component-name="m-common-static-SignInDrawer"
        data-testid="common-static-SignInDrawer"
        className={clsx('t-label-inverse-xs custom_click_track', {
          'rememberedBtn p-0 d-flex align-items-center': isSignedInUser || isRememberedUser,
        })}
        onClick={togglePanel}
        data-custom_click_track_value={clickTrackValue}
      >
        {renderUtilityLink(restProps, signInButtonClass, buttonText, 'signin')}
        {(isSignedInUser || isRememberedUser) && <span className="icon-arrow-right d-none d-lg-inline-block"></span>}
      </StyledSignInButton>
      {isSignInDrawerOpen && (
        <AccountDrawer
          isOpen={isSignInDrawerOpen}
          className="standard t-background-color"
          style={{ top: -offset, height: `calc(100vh - ${-offset}px)` }}
        >
          {/* styling for the dialog */}
          <style>
            {`
          [role='dialog'] {
            top: ${top};
            height: inherit;
            text-wrap: wrap;
          }
        `}
          </style>
          <AccountPanel
            togglePanel={togglePanel}
            headerLogoProps={headerLogoProps}
            memberPanelProps={memberPanelProps}
            signInFormProps={signInFormProps}
          />
        </AccountDrawer>
      )}
    </StyledSignInDrawer>
  );
};
