/* eslint-disable @typescript-eslint/no-explicit-any */
import { milesToMeters, isValidNumberString } from '../../../utils/CommonUtils';

export const getSearchResultQuery = (
  searchData: any,
  destinationMetaData: any,
  offset: any,
  brandFacets: any,
  limit: any,
  sortBy: any,
  amentiesFacets: any,
  transportaionFacets: any,
  hotelTypeFacets: any,
  eventsFacets: any,
  activitiesFacets: any,
  priceDimension: any,
  cityFacets: any,
  stateFacets: any,
  includeUnavailableProperties: boolean
): any => {
  const sortDirection = sortBy === 'GUEST_RATINGS' || sortBy === 'NUM_REVIEWS' ? 'DESC' : 'ASC';
  const obj: any = {
    search: {
      options: {
        startDate: searchData?.startDate,
        endDate: searchData?.endDate,
        includeUnavailableProperties: includeUnavailableProperties,
        includeTaxesAndFees: false,
        numberInParty: searchData?.numberInParty,
        quantity: searchData?.quantity,
        childAges: searchData?.childAges,
        rateRequestTypes: [
          {
            type: 'STANDARD',
            value: '',
          },
        ],
        includeMandatoryFees: true,
      },
      facets: {
        terms: [
          {
            type: 'BRANDS',
            dimensions: brandFacets,
          },
          {
            type: 'AMENITIES',
            dimensions: amentiesFacets,
          },
          {
            type: 'PROPERTY_TYPES',
            dimensions: hotelTypeFacets,
          },
          {
            type: 'ACTIVITIES',
            dimensions: activitiesFacets,
          },
          // {
          //   type: 'HOTEL_SERVICE_TYPES',
          //   dimensions: hotelTypeFacets,
          // },
          {
            type: 'MEETINGS_EVENTS',
            dimensions: eventsFacets,
          },
          {
            type: 'TRANSPORTATION_TYPES',
            dimensions: transportaionFacets,
          },
        ],
        ranges: [
          {
            type: 'PRICE',
            dimensions: priceDimension,
            endpoints: ['0', '100', '200', 'overflow'],
          },
          {
            type: 'DISTANCE',
            dimensions: [],
            endpoints: ['0', '5000', '15000', '80000'],
          },
        ],
      },
      ...(!destinationMetaData?.isCountryEnabled &&
        !destinationMetaData?.isStateEnabled &&
        destinationMetaData?.radius !== null &&
        isValidNumberString(destinationMetaData.radius) && { distance: milesToMeters(destinationMetaData.radius) }),
    },
    sort: {
      fields: [
        {
          field: sortBy,
          direction: sortDirection,
        },
      ],
    },
    filter: [
      'HOTEL_MARKETING_CAPTION',
      'RESORT_FEE_DESCRIPTION',
      'DESTINATION_FEE_DESCRIPTION',
      'SURCHARGE_ORDINANCE_COST_DESCRIPTION',
    ],
    phoneFilter: ['PHONE'],
    modes: ['LOWEST_AVERAGE_NIGHTLY_RATE', 'POINTS_PER_UNIT'],
    offset: offset,
    limit: limit,
    limitPerCategory: 2,
  };

  interface filterType {
    type: string;
    dimensions: any;
  }
  interface sortType {
    field: string;
    direction: string;
  }
  if (
    !destinationMetaData?.isStateEnabled &&
    !destinationMetaData?.isCountryEnabled &&
    destinationMetaData?.latitude &&
    destinationMetaData?.longitude
  ) {
    obj.search['latitude'] = parseFloat(destinationMetaData?.latitude ?? 0);
    obj.search['longitude'] = parseFloat(destinationMetaData?.longitude ?? 0);
  } else if (destinationMetaData?.isStateEnabled || destinationMetaData?.isCountryEnabled) {
    const filterDetails: filterType[] = [
      {
        type: 'CITIES',
        dimensions: cityFacets,
      },
      {
        type: 'STATES',
        dimensions: destinationMetaData?.isStateEnabled ? [destinationMetaData?.stateCode] : stateFacets,
      },
      {
        type: 'COUNTRIES',
        dimensions: destinationMetaData?.countryCode ? [destinationMetaData?.countryCode] : [],
      },
    ];
    obj.search.facets.terms.push(...filterDetails);

    // const sortField: sortType[] = [
    //   {
    //     field: sortBy,
    //     direction: 'ASC',
    //   },
    // ];
    // obj.sort.fields.push(...sortField);
  }
  return obj;
};

export const getCommaSeparator = (numb: number, lang: string) => {
  return numb ? Number(Math.floor(numb))?.toLocaleString(lang || '') : null;
};

export const priceDimensionMapping = {
  'Any Price': [],
  '0 - 100 USD': ['1'],
  '100 - 200 USD': ['2'],
  '200+ USD': ['3'],
};

export const getSortByVal = (sortBySessionVal: any, destinationData: any): string => {
  if (sortBySessionVal) {
    return sortBySessionVal;
  } else {
    if (destinationData?.isCountryEnabled || destinationData?.isStateEnabled) {
      return 'CITY';
    } else {
      return 'DISTANCE';
    }
  }
};
