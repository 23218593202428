/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { StyledAvailabilityDiv, StyledAvailabilityFilterHeaderContainerDiv } from './availabilityFilter.styles';
import { Checkbox } from '@marriott/mi-ui-library-shop';
// import { CheckBox } from '@marriott/mi-ui-library';
import { useWindowSize } from '@marriott/mi-ui-library';
import { BREAKPOINT_DESKTOP_1023, BREAKPOINT_TABLET } from './availabilityFilterConstant';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}
type AvailabilityFilterProps = {
  handleAvailabilityChange: (val: boolean) => void;
  isSliderActive: boolean;
  labels: {
    availabilityLabel?: string;
    showAvailableHotelsOnlyLabel?: string;
  };
};

export const ShowAvailabilityFilter: React.FC<AvailabilityFilterProps> = ({
  handleAvailabilityChange,
  isSliderActive,
  labels,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clickTrackingLoc = 'Phoenix Search Results';
  const [isSliderActiveVal, setIsSliderActiveVal] = useState<boolean>(isSliderActive);
  const { width } = useWindowSize();

  useEffect(() => {
    setIsSliderActiveVal(isSliderActive);
  }, [isSliderActive]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLabelSelection = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      const val = isSliderActive;
      setIsSliderActiveVal(!val);
      handleAvailabilityChange(!val);
    }
  };

  const onSliderChange = (): void => {
    const val = isSliderActive;
    setIsSliderActiveVal(!val);
    handleAvailabilityChange(!val);
  };

  return (
    <StyledAvailabilityDiv>
      <fieldset>
        <StyledAvailabilityFilterHeaderContainerDiv className="t-font-alt-m">
          <legend>
            <h4
              className={
                (width && width > BREAKPOINT_DESKTOP_1023
                  ? `t-subtitle-l`
                  : width && width > BREAKPOINT_TABLET
                  ? `t-subtitle-l`
                  : `t-subtitle-m`) + ' filter-heading mb-0'
              }
            >
              {labels?.availabilityLabel}
            </h4>
          </legend>
        </StyledAvailabilityFilterHeaderContainerDiv>
      </fieldset>
      <div className="availability-checkbox t-font-m color-scheme1">
        <label
          className="t-font-s show-unavailable-properties-checkbox-label"
          tabIndex={-1}
          onKeyDown={(e): void => handleLabelSelection(e)}
        >
          {labels?.showAvailableHotelsOnlyLabel}
        </label>
        <Checkbox
          checkboxId="show-unavailable-properties-checkbox"
          onChange={onSliderChange}
          checkboxName="show-unavailable-properties-checkbox"
          variation="switch"
          inputClassName="custom_click_track"
          checked={isSliderActiveVal}
          {...{
            custom_click_track_value: `${clickTrackingLoc}| ${'Show available hotels only'} Selection |internal`,
            tabIndex: -1,
          }}
          children={<span className="ada-hide">{'Show available hotels only'}</span>}
          ariaLabel={'Show available hotels only'}
          setTabIndex={-1}
          onKeyDown={(e): void => handleLabelSelection(e)}
        />
      </div>
      <hr aria-hidden className="filter-separator" />
    </StyledAvailabilityDiv>
  );
};
