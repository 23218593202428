import { FC } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CarouselGallery as CarouselGalleryComp } from '@marriott/mi-ui-library';

import { DestinationHeroProps } from './DestinationHero.types';

export const DestinationHeroConfig = {
  emptyLabel: 'DestinationHero',
  isEmpty: () => true,
  resourceType: `mi-aem-leisure-spa/components/destinationshero`,
};

export const DestinationHero: FC<DestinationHeroProps> = props => {
  return (
    <>
      {props?.cqPath?.includes('experiencefragment') && props?.isAuthor ? (
        <CarouselGalleryComp {...props} imageLoading={'eager'} />
      ) : (
        <div data-testid="leisure-DestinationHero" data-component-name="o-leisure-DestinationHero">
          <EditableComponent config={DestinationHeroConfig} {...props}>
            <CarouselGalleryComp {...props} imageLoading={'eager'} />
          </EditableComponent>
        </div>
      )}
    </>
  );
};
