/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import {
  Button,
  Heading,
  Label,
  Eyebrow,
  LinksCollection,
  LinksCollectionVariations,
  EyebrowEnum,
  TypographyColorVariations,
  headingType,
  size,
} from '@marriott/mi-ui-library';
import { MEMBERPANEL_CONSTANTS, MemberPanelCardLink, MemberPanelProps } from './MemberPanel.types';
import { StyledMemberPanel } from './MemberPanel.styles';
import { UserProfileImage } from '../UserProfileImage';
import { UserType } from '../UserProfileImage/UserProfileImage.types';
import clsx from 'clsx';
import { useUserDetailsStore } from '@marriott/mi-store-utils';
import { logger } from '@marriott/shared/mi-helper-utils';
import { MEMBER_BENEFITS_LINK } from '../../Navigation/_constants';

// Use named rather than default exports.
export const MemberPanel: FC<MemberPanelProps> = props => {
  const {
    joinNowLabel,
    joinNowSubHeading,
    becomeMemberLabel,
    becomeMemberUrl,
    pointsLabel,
    buyPointsLinkLabel,
    buyPointsLinkpath,
    memberPanelCardLinks,
    logoutLinkLabel,
    logoutLinkPath,
    memberBenefitsLabel,
  } = props;

  const { userProfileData, isSignedInUser } = useUserDetailsStore(state => ({
    userProfileData: state.userProfileData,
    isSignedInUser: state.isSignedInUser,
  }));
  const { trips, userProfileSummary, headerSubtext } = userProfileData;

  const generateMemberBenefitsLink = () => {
    const membershipLevel = userProfileData?.userProfileSummary?.level;

    // some benefits have 'Elite' as part and some not, this covers both scenarios to get the user to the right url.
    const cleanedMemberStatus = membershipLevel?.split(' ')[0].toLowerCase();
    // exp: https://www.marriott.com/loyalty/member-benefits/ambassador.mi
    const cardLinkPath = `${MEMBER_BENEFITS_LINK}${cleanedMemberStatus}.mi`;
    // we expect "{} Benefits" and this constructs "Titanium Elite Benefits"
    const cardLinkLabel = memberBenefitsLabel?.replace('{}', membershipLevel ?? '');

    if (membershipLevel && memberBenefitsLabel && cardLinkLabel) {
      return getCardLinkData({
        cardLinkLabel,
        cardLinkPath,
        cardLinkId: cardLinkLabel,
      });
    }
    return null;
  };

  const { MY_TRIPS, BENEFITS } = MEMBERPANEL_CONSTANTS;
  const tripLabel = (tripsLabel: string) =>
    `${tripsLabel}${
      trips?.upcomingReservationsCount && trips?.upcomingReservationsCount > 0
        ? ' (' + trips?.upcomingReservationsCount + ')'
        : ''
    }`;

  const getCardLinkData = (cardLink: MemberPanelCardLink) => {
    return {
      id: (cardLink?.cardLinkId || cardLink?.cardLinkLabel)?.replace(' ', '_'),
      linkLabel: cardLink?.cardLinkLabel,
      linkURL: cardLink?.cardLinkPath,
    };
  };
  const getLinksCollectionData = (cardLinks: MemberPanelCardLink[] | any) => {
    let linksCollectionData: any = [];
    if (cardLinks?.length) {
      linksCollectionData = cardLinks.map((linkData: MemberPanelCardLink) => {
        switch (linkData?.cardLinkId) {
          case MY_TRIPS:
            return getCardLinkData({ ...linkData, cardLinkLabel: tripLabel(linkData?.cardLinkLabel) });
          case BENEFITS:
            return getCardLinkData({
              ...linkData,
              cardLinkLabel: `${userProfileSummary?.level} ${linkData?.cardLinkLabel}`,
            });
          default:
            return getCardLinkData(linkData);
        }
      });
    }
    const memberBenefitsLink = generateMemberBenefitsLink();
    if (memberBenefitsLink) {
      linksCollectionData.push(memberBenefitsLink);
    }
    return linksCollectionData;
  };
  const handleLogoutClick = async () => {
    try {
      const response = await fetch(logoutLinkPath);
      const { nextStateURI } = await response.json();
      if (nextStateURI) {
        window.location.href = nextStateURI;
      } else {
        window.location.reload();
      }
    } catch (e) {
      const { log } = logger({})('MemberPanel');
      log.error('Logout failed', e);
      window.location.reload();
    }
  };

  return (
    <StyledMemberPanel className={clsx({ 'd-flex flex-column align-items-center authenticated': isSignedInUser })}>
      {!isSignedInUser ? (
        <>
          <Heading
            variation={headingType.title}
            titleText={joinNowLabel}
            customClass={'heading m-0 t-title-s'}
            data-testid="join-now"
          />
          <Heading variation={headingType.subtitle} titleText={joinNowSubHeading} customClass={'subHeading mt-2'} />
          <Button
            className="m-button-m m-button-secondary pl-3 pr-3"
            buttonCopy={becomeMemberLabel}
            href={becomeMemberUrl}
            isLink={true}
          />
          <hr className={clsx('separator m-0 mt-4 mt-lg-5')} />
        </>
      ) : (
        <>
          <UserProfileImage
            userType={userProfileSummary?.membershipCode as UserType}
            userImage={headerSubtext?.image?.src}
            userImageAltText={headerSubtext?.image?.altText ?? ''}
            lifeTimeLevel={userProfileSummary?.lifeTimeLevel}
          />
          <Label
            variation={TypographyColorVariations.Alternate}
            size={size.small}
            labelText={userProfileSummary?.level ?? ''}
            customClass={' mb-0 mt-3 mt-lg-5'}
          />
          {/* TODO:Line height issue follow up with platform team */}
          <Heading
            fontSize={size.small}
            variation={headingType.title}
            titleText={headerSubtext?.consumerName}
            customClass={'mb-0 mt-1'}
            data-testid="consumername"
          />
          {/* TODO:Line height issue follow up with platform team */}
          <Heading
            fontSize={size.extraLarge}
            variation={headingType.subtitle}
            titleText={userProfileSummary?.currentPoints}
            customClass={'mb-1 mb-lg-1 mt-3 mt-lg-4 '}
            data-testid="points"
          />
          <Eyebrow variation={EyebrowEnum.Default} text={pointsLabel} />
          <Button
            buttonCopy={buyPointsLinkLabel}
            className={'m-button-s mt-3 mt-lg-4'}
            id="buypoints"
            testId="buypointsbutton"
            isLink={true}
            href={buyPointsLinkpath}
            target="_blank"
          />
          <hr className={clsx('separator align-self-stretch')} />
          <LinksCollection
            linkListData={getLinksCollectionData(memberPanelCardLinks)}
            listCollectionClassname={'listCollection p-0 align-self-start'}
            linkListClassName={'p-0 m-0  mt-4'}
            listItemClassName={'p-0 m-0 m-nav-link listClassName'}
            variation={LinksCollectionVariations?.NavLink}
          />
          <Button
            className={'m-link-tertiary-button m-0 p-0 mt-4 mb-5 align-self-start t-overline-overlay signout'}
            id={'signout'}
            callback={handleLogoutClick}
          >
            <span className="signoutLabel">{logoutLinkLabel}</span>
          </Button>
          <div id="promo-content" data-testid="promo-content"></div>
        </>
      )}
    </StyledMemberPanel>
  );
};
