import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

export const StyledLinkCollection = styled.ul`
  display: flex;
  &.vertical-align {
    flex-direction: column;
  }
  &.horizontal-align {
    display: block;
    .cl__list-item {
      display: inline-block;
      @media ${baseVariables.mediaQuery.md} {
        position: relative;
        padding-right: ${toRem(24)};
      }
    }
  }
  &.cl {
    &__list {
      &.m-accordion__list {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
      }
    }
  }
  .cl {
    &__list-item {
      &:last-child {
        .cl__item-link {
          @media ${baseVariables.mediaQuery.lg} {
            margin-bottom: ${toRem(2)};
          }
        }
      }
    }
    &__item-link {
      display: flex;
      padding: ${toRem(8)} 0;
      text-decoration: none;
      line-height: ${toRem(16)};
      max-width: max-content;
      word-break: break-word;
      align-items: center;
      @media ${baseVariables.mediaQuery.md} {
        display: block;
      }
      &.icon-arrow-right-external,
      &.icon-arrow-left-external {
        &::before {
          font-size: ${toRem(20)};
          right: -${toRem(21)};
          bottom: ${toRem(7)};
        }
      }
      &.icon-arrow-left-external:hover::before {
        transform: rotate(45deg);
      }
      &:hover {
        text-decoration: underline;
        font-weight: ${baseVariables.font.fontWeightRegular};
      }
      .icon-arrow-right,
      .icon-arrow-left,
      .icon-arrow-right-external,
      .icon-arrow-left-external {
        line-height: inherit;
        @media ${baseVariables.mediaQuery.md} {
          position: absolute;
          margin: ${toRem(-3)} 0 0 ${toRem(2)};
        }
      }
    }
  }
`;
