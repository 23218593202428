import { useState, useEffect } from 'react';

export type DocDirAttr = 'ltr' | 'rtl';

export function useDocumentDirection() {
  const [dir, setDir] = useState<DocDirAttr>('ltr');

  useEffect(() => {
    setDir((document.documentElement.getAttribute('dir') as DocDirAttr) || 'ltr');
  }, []);

  return dir;
}
