/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { LinksCollection as LinksCollectionComp } from '@marriott/mi-ui-library';
import clsx from 'clsx';

import { StyledBreadcrumb } from './Breadcrumb.styles';
import { BREADCRUMB_ALIGNMENT } from '../../constants/CommonConstants';
import breadcrumbMock from './__mock__/Breadcrumb.model.json';

export const BreadcrumbConfig = {
  emptyLabel: 'Breadcrumb',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/leisureBreadcrumb`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Breadcrumb = (props: any) => {
  const { model, isAuthorMode } = props;
  const breadcrumbProps = !isAuthorMode ? { ...model } : { ...breadcrumbMock.model };
  breadcrumbProps['linkListData'] = breadcrumbProps?.breadcrumbs ?? [];
  breadcrumbProps['alignment'] = BREADCRUMB_ALIGNMENT;
  delete breadcrumbProps['breadcrumbs'];

  return (
    <StyledBreadcrumb
      data-testid="leisure-Breadcrumb"
      data-component-name="o-leisure-Breadcrumb"
      className={clsx(breadcrumbProps?.styleclass)}
    >
      {props.cqPath?.includes('experiencefragment') ? (
        <div className="container">
          {breadcrumbProps['linkListData'].length > 0 && <LinksCollectionComp {...breadcrumbProps} />}
        </div>
      ) : (
        <div className="container">
          <EditableComponent config={BreadcrumbConfig} {...props}>
            {breadcrumbProps['linkListData'].length > 0 && <LinksCollectionComp {...breadcrumbProps} />}
          </EditableComponent>
        </div>
      )}
    </StyledBreadcrumb>
  );
};
