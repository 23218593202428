/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
/* eslint-disable @nx/enforce-module-boundaries */
import { CardCarouselContainer } from '@marriott/mi-common-static-components';

import { updateTrackingProperties } from '../../utils/DestinationUtils';
import DestinationIconblockCarouselMock from './__mock__/DestinationLinksIconCardCarousel.model.json';
import { CarouselContainerProps } from 'libs/mi-common-static-components/src/organisms/CardCarouselContainer/CardCarouselContainer.types';

export const IconBlockComponentConfig = {
  emptyLabel: 'DestinationLinksIconCardCarousel',
  isEmpty: () => true,
  resourceType: `mi-aem-leisure-spa/components/iconCardCarousel`,
};

export const CardCarousel = (props: JSX.IntrinsicAttributes & CarouselContainerProps) => {
  return <CardCarouselContainer {...props} />;
};

export const DestinationLinksIconCardCarousel = (props: any) => {
  const { isAuthorMode } = props;
  const compProps = !isAuthorMode ? { ...props } : { ...DestinationIconblockCarouselMock };
  const { componentId = '' } = compProps;
  const [iconblockItems, setIconblockItems] = useState([]);

  useEffect(() => {
    // Update trackingProperties in data
    const cardItems = updateTrackingProperties(compProps?.model, 'iconblock');
    setIconblockItems(cardItems);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {iconblockItems?.length > 0 && (
        <div
          data-testid="leisure-DestinationLinksIconCardCarousel"
          data-component-name="o-leisure-DestinationLinksIconCardCarousel"
          id={componentId}
        >
          <EditableComponent config={IconBlockComponentConfig} {...props}>
            <CardCarousel {...compProps} />
          </EditableComponent>
        </div>
      )}
    </>
  );
};
