export const destinationOverviewMockData = {
  primaryCtaLink:
    'https://publish1useast1.qa.marriott-sites2.adobecqms.net/search/findHotels.mi?destinationAddress.destination=Dallas, Texas, USA',
  contentAlignment: 'left',
  componentId: 'dest-overview-cmp3225352342',
  headerTag: 'h1',
  primaryCtaType: 'secondaryButton',
  dataLayer: {
    'destinationOverview-c01aca6bc1': {
      '@type': 'mi-aem-leisure-spa/components/destinationOverview',
      'repo:modifyDate': '2024-09-02T14:36:29Z',
    },
  },
  descriptionFontSize: 'm',
  descriptionText:
    '<p>The destination Overview component displays destination specific information from the available destination specific content fragment. The Header and CTA Label can include the name of the destination dynamically. To enable this functionality please use the format {destinationName}. The description is fetched dynamically from the content fragment. It can be overridden by authoring the description field in the dialog.</p>\r\n',
  trackingProperties: {
    additionalTrackingVariables: '',
    enableScrollingBehavior: false,
    trackingDescription: 'Search All Hotels',
    impressionTrack: false,
    description: 'destination-overview',
    trackingContentPosition: 'destination-overview',
    atCCeVar48: '',
    rightArrowDesc: '',
    cardLocation: 'destination-overview',
    leftArrowDesc: '',
    clickTrack: true,
    location: 'destination-overview',
    merchandisingCategory: 'nonCobrand',
    impressionCount: false,
    trackingTag: '',
    trackingOfferType: '',
  },
  isAuthor: '',
  path: '/content/mi-aem-leisure-spa/na/en-us/destinations/destination-page/jcr:content/root/responsivegrid/destinationoverview__1542848794',
  cqType: 'mi-aem-leisure-spa/components/destinationOverview',
  headerFontSize: 'm',
  styleclass: 'mt-2 mb-2',
  header: 'Hotels in {destinationName}',
  showLessDesc: '',
  primaryCtaLabel: 'Search All Hotels in {destinationName}',
  openInNewTabPrimary: '',
  id: 'destinationOverview-c01aca6bc1',
  wcmMode: 'disabled',
};
