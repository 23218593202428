export const SitemapModelWithBreadCrumbsMockJson = {
  model: {
    componentId: 'splbqcgjcxerqs72',
    headingTitle: 'Hotel Sitemap',
    dataLayer: {
      'htmlsitemap-561e9dd3bd': {
        '@type': 'mi-aem-leisure-spa/components/htmlsitemap',
        'repo:modifyDate': '2024-06-21T12:25:11Z',
      },
    },
    listingMap: [],
    listItemPrefix: 'Hotels In ',
    groupingMap: [
      {
        brandTierCode: 'premium',
        brands: [
          {
            brandTitle: 'Marriott Hotels \u0026 Resorts',
            properties: [
              {
                marsha: 'tiamc',
                title: 'Tirana Marriott',
                url: 'https://www.marriott.com/en-us/hotels/tiamc-tirana-marriott/overview/',
              },
            ],
            brandCode: 'MC',
          },
        ],
        brandTierTitle: 'PREMIUM HOTELS IN ALBANIA',
      },
    ],
    trackingProperties: {
      additionalTrackingVariables: '',
      enableScrollingBehavior: false,
      trackingDescription: 'Hote, Sitemap Listview',
      impressionTrack: true,
      description: '',
      trackingContentPosition: 'Tracking Content Position',
      atCCeVar48: 'eVar48',
      rightArrowDesc: '',
      cardLocation: '',
      leftArrowDesc: '',
      clickTrack: true,
      location: 'hotel-sitemap',
      merchandisingCategory: 'nonCobrand',
      impressionCount: true,
      trackingTag: '',
      trackingOfferType: 'Tracking Offer Type',
    },
    isAuthor: false,
    renderMode: 'grouping',
    path: '/content/mi-aem-leisure-spa/na/en-us/sitemap/sitemap-details/jcr:content/root/responsivegrid/htmlsitemap_15430019',
    ':type': 'mi-aem-leisure-spa/components/htmlsitemap',
    id: 'htmlsitemap-561e9dd3bd',
    enableBreadcrumb: 'true',
    subdirectory: '',
    breadcrumbs: [
      {
        title: 'Hotel Sitemap',
        url: '/en-us/hotel-sitemap',
      },
      {
        title: 'Albania Hotel Sitemap',
        url: null,
      },
    ],
    wcmMode: 'disabled',
  },
  cqPath: 'htmlsitemap_1543001972',
  className: 'aem-GridColumn aem-GridColumn--default--12',
  isAuthorMode: false,
  allowedComponents: [
    {
      path: '/content/mi-aem-leisure-spa/na/en-us/sitemap/hotel-sitemap/jcr:content/root/responsivegrid/mi-aem-common-spa/components/common/staticwrapper',
      title: 'Static Wrapper',
    },
  ],
};

export const SitemapModelMockJson = {
  model: {
    componentId: 'hotel-sitemap-comp',
    headingTitle: 'Hotel Sitemap',
    trackingDescription: 'Tracking Description',
    impressionTrack: 'true',
    listingMap: [
      {
        code: 'AL',
        title: 'Albania',
        uri: '/en-us/hotel-sitemap/al-hotel-sitemap',
      },
      {
        code: 'CH',
        title: 'Switzerland',
        uri: '/en-us/hotel-sitemap/ch-hotel-sitemap',
      },
      {
        code: 'GB',
        title: 'United Kingdom',
        uri: '/en-us/hotel-sitemap/gb-hotel-sitemap',
      },
      {
        code: 'US',
        title: 'USA',
        uri: '/en-us/hotel-sitemap/us-hotel-sitemap',
      },
    ],
    listItemPrefix: 'Hotels In',
    groupingMap: [],
    trackingContentPosition: 'Tracking Content Position',
    trackingProperties: {
      additionalTrackingVariables: '',
      enableScrollingBehavior: false,
      trackingDescription: 'Tracking Description',
      impressionTrack: true,
      description: '',
      trackingContentPosition: 'Tracking Content Position',
      atCCeVar48: 'eVar48',
      rightArrowDesc: '',
      cardLocation: '',
      leftArrowDesc: '',
      clickTrack: true,
      location: 'Location',
      merchandisingCategory: 'nonCobrand',
      impressionCount: true,
      trackingTag: '',
      trackingOfferType: 'Tracking Offer Type',
    },
    isAuthor: '',
    atCCeVar48: 'eVar48',
    renderMode: 'listing',
    path: '/content/mi-aem-leisure-spa/na/en-us/sitemap/hotel-sitemap/jcr:content/root/responsivegrid/htmlsitemap_1543001972',
    cqType: 'mi-aem-leisure-spa/components/htmlsitemap',
    location: 'Location',
    id: 'htmlsitemap-8ed1c7462e',
    merchandisingCategory: 'nonCobrand',
    impressionCount: 'true',
    enableBreadcrumb: 'true',
    trackingOfferType: 'Tracking Offer Type',
    breadcrumbs: [],
    wcmMode: 'disabled',
  },
  cqPath: 'htmlsitemap_1543001972',
  className: 'aem-GridColumn aem-GridColumn--default--12',
  isAuthorMode: true,
  allowedComponents: [
    {
      path: '/content/mi-aem-leisure-spa/na/en-us/sitemap/hotel-sitemap/jcr:content/root/responsivegrid/mi-aem-common-spa/components/common/staticwrapper',
      title: 'Static Wrapper',
    },
  ],
};

export const sitemapGrpViewTrackProps = {
  additionalTrackingVariables: '',
  enableScrollingBehavior: false,
  trackingDescription: 'Hotel Sitemap Groupview',
  impressionTrack: true,
  description: '',
  trackingContentPosition: 'Tracking Content Position',
  atCCeVar48: 'eVar48',
  rightArrowDesc: '',
  cardLocation: '',
  leftArrowDesc: '',
  clickTrack: true,
  location: 'hotel-sitemap-brandtier',
  merchandisingCategory: 'nonCobrand',
  impressionCount: true,
  trackingTag: '',
  trackingOfferType: 'Tracking Offer Type',
};

export const sitemapGrpViewMock = [
  {
    brandTierCode: 'luxury',
    brandTierTitle: 'LUXURY',
    model: {
      className: 'aem-GridColumn aem-GridColumn--default--4',
      hideColumDividers: false,
      noOfColumns: 4,
      columnDetails: [
        {
          header: 'EDITION',
          linkItems: [
            {
              code: 'nyceb',
              linkLabel: 'The New York EDITION',
              linkURL: 'https://www.marriott.com/en-us/hotels/nyceb-the-new-york-edition/overview/',
              openInNewTab: true,
            },
          ],
        },
      ],
      trackingProperties: sitemapGrpViewTrackProps,
    },
  },
];

export const linksCollectionMock = {
  id: 'hotel-sitemap-breadcrumb',
  linkListData: [],
  trackingProperties: {
    location: 'hotel-sitemap-breadcrumb',
    trackingTag: '',
    additionalTrackingVariables: '',
    trackingContentPosition: '',
    trackingDescription: '',
    trackingOfferType: '',
    merchandisingCategory: 'nonCobrand',
    atCCeVar48: '',
    clickTrack: true,
    impressionTrack: false,
    impressionCount: false,
    enableScrollingBehavior: false,
    leftArrowDesc: '',
    cardLocation: '',
    rightArrowDesc: '',
  },
};

export const breadcrumbMock = [
  {
    linkLabel: 'Hotel Sitemap',
    linkURL: '/en-us/hotel-sitemap',
    openInNewTab: false,
  },
  {
    linkLabel: 'USA Hotel Sitemap',
    linkURL: null,
    openInNewTab: false,
  },
];

export const SitemapProperties = {
  ':type': 'mi-aem-leisure-spa/components/htmlsitemap',
  title: 'HTML Sitemap',
};
