import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledSortByFilterContainerDiv = styled.div`
  ${rtl`
  &.sort-container-de{
    min-width:45%;
    .sort-by-section-de {
     .arrow-container {
      position:absolute;
      top:${toRem(15)};
      left:${toRem(345)}
     }
    }
  }
  &.sort-list-container-de {
    @media ${theme.mediaQuery.allMobiles} {
      min-width:45%;
      .sort-by-list-section-de {
        .arrow-container {
          position:absolute;
          top:${toRem(-16)};
          left: ${toRem(192)};
        }
      }
    }
  }
  .expanded-container-parent {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 2;
    top: 0px;
    left: 0;
    @media ${theme.mediaQuery.allMobiles} {
      opacity: 0.5;
      z-index: 1011;
    }
    @media ${theme.mediaQuery.allTablets} {
      background: transparent
    }
    @media ${theme.mediaQuery.desktop} {
      background: transparent
    }
    @media ${theme.mediaQuery.allTablets} {
      background: transparent
    }
  }
  .sort-by-section {
    height: ${toRem(20)};
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: 0;
    margin-top: 0 !important;
    justify-content: flex-end;
    padding-top: ${toRem(30)};
    &.sort-by-section-de {
      height:auto;
      flex-wrap:wrap;
      padding-right:${toRem(25)};
      padding-left:${toRem(22)};
      line-height:${toRem(16)};
    }
    @media ${theme.mediaQuery.allMobiles} {
      &.sort-by-list-section-de {
        height:auto;
        flex-wrap:wrap;
        padding-right:${toRem(24)};
        padding-left:${toRem(24)};
      }
    }

    .sort-by-label {
      padding-right: ${toRem(5)};
      white-space: nowrap;
    }
    .current-sort-option {
      padding-right: ${toRem(10)};
      white-space: nowrap;
    }

    .sort-by-options {
      position: relative;
      .current-state {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
      .expanded-container {
        position: absolute;
        right: 0;
        top: ${toRem(36)};
        z-index: 3;
        min-height: fit-content;
        min-width: ${toRem(332)};
        border-radius: ${toRem(8)};
        padding-bottom: ${toRem(0)};
        box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0,0,0,0.07);
        @media ${theme.mediaQuery.allTablets} {
          top: ${toRem(41)};
        }
        @media only ${theme.mediaQuery.allMobiles} {
          position: fixed;
          top: auto;
          bottom: 0;
          left: 0;
          right: 0;
          box-shadow: ${theme.color['cta-banner-shadow']};
          border-radius: 0;
          border-top-left-radius: ${toRem(14)};
          border-top-right-radius: ${toRem(14)};
          z-index: 1012;
        }
        .sort-options-item-container {
          padding: ${toRem(0)} ${toRem(24)};
          @media ${theme.mediaQuery.allMobiles} {
            padding: ${toRem(9)} ${toRem(24)} ${toRem(24)};
          }
        }
        .sort-options-mobile-title {
          padding: ${toRem(35)} ${toRem(24)};
          border-bottom: ${toRem(1)} solid ${theme.color['close-silver']};
          display: flex;
          align-items: center;
          justify-content: space-between;

          .popup-close {
            min-height: ${toRem(32)};
            min-width: ${toRem(32)};
            border-radius: ${toRem(4)};
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              cursor: pointer;
            }
            &:focus {
              cursor: pointer;
            }
          }
          @media ${theme.mediaQuery.tablet} {
            display: none;
          }
          @media ${theme.mediaQuery.minDesktop} {
            display: none;
          }
        }
        .sort-options-title {
          letter-spacing: ${toRem(1.3)};
          margin-bottom: ${toRem(18)};
          text-transform: uppercase;
          padding-top: ${toRem(24)};
          padding-left: ${toRem(24)};
          @media ${theme.mediaQuery.allMobiles} {
            display: none;
          }
        }
        .sort-option {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin: ${toRem(16)} 0;
          letter-spacing: 0;
          white-space: pre;

          .sort-option-radio {
            box-sizing: border-box;
            height: ${toRem(20.38)};
            width: ${toRem(20.38)};
            border: 1px solid ${theme.color['cod-grey']};
            border-radius: 50%;
            margin-right: ${toRem(6)};
          }
          .sort-option-radio.active {
            border: ${toRem(7)} solid ${theme.color['cocoa-brown']};
          }
        }
      }
      .map-expanded-container{
        right: unset;
        left:${toRem(37.2)};
        top:${toRem(50)};
      }
    }
    .sort-by-list{
      position:relative;
    }
  }`}
`;
