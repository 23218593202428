import { StateCreator } from 'zustand';
import { createAppStore } from './zustand';
import { produce } from 'immer';

interface EnvVarsType {
  [ENV_VAR: string]: string;
}

interface EnvVarsState {
  envVarsObject: EnvVarsType;
}

interface EnvVarsActions {
  mergeEnvVars: (envVars: EnvVarsType) => void;
  updateEnvVar: (key: string, value: string) => void;
  setEnvVars: (envVars: EnvVarsType) => void;
}

const initialState: EnvVarsState = {
  envVarsObject: {},
};

export const clientEnvVarsStore: StateCreator<EnvVarsState & EnvVarsActions> = (set, get) => {
  return {
    ...initialState,
    mergeEnvVars: (envVars: EnvVarsType) => {
      set(state => ({
        envVarsObject: {
          ...state.envVarsObject,
          ...envVars,
        },
      }));
    },
    setEnvVars: (envVars: EnvVarsType) => {
      set({ envVarsObject: { ...envVars } });
      if (!Object.keys(get().envVarsObject).length) set({ envVarsObject: { ...envVars } });
    },
    updateEnvVar: (key: string, value: string) => {
      set(produce(draft => void (draft.envVarsObject[key] = value))); // @see https://immerjs.github.io/immer/return/#inline-shortcuts-using-void
    },
  };
};

export const useClientEnvVarsStore = createAppStore(clientEnvVarsStore, {
  usePersistentStore: false,
});
