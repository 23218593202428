/* eslint-disable @typescript-eslint/no-explicit-any */
// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables as theme, baseVariables } from '@marriott/mi-ui-library';

export const StyledSearchForm = styled.div`
  .adv-search-brands-dropdown .dropdown-to-modal {
    padding-left: 0 !important;
    padding-right: 0;
    .m-dropdown-header {
      padding-left: ${toRem(24)};
      padding-right: ${toRem(24)};
    }
    .m-dropdown-action-bar {
      padding-left: ${toRem(24)} !important;
      padding-right: ${toRem(24)} !important;
    }
    .m-dropdown-children {
      padding-left: ${toRem(24)};
      padding-right: ${toRem(24)};
    }
  }
  .brand-filter__category_label_title {
    padding-right: ${toRem(16)} !important;
    @media ${baseVariables.mediaQuery.md} {
      padding-right: ${toRem(12)} !important;
    }
  }
  .brand-filter__category_label_btn :after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .brand-filter__category_label_btn {
    padding-left: ${toRem(16)} !important;
    font: ${baseVariables.font.fontWeightMedium};
    @media ${baseVariables.mediaQuery.md} {
      padding-left: ${toRem(12)} !important;
      font: ${baseVariables.font.fontWeightMedium};
    }
  }
  .flex-basis {
    min-width: ${toRem(290)};
    flex-basis: 1;
    @media ${baseVariables.mediaQuery.lg} {
      flex-basis: 0;
    }
  }
  .use-points-wrapper {
    display: flex;
    align-items: center;
    gap: ${toRem(8)};
  }
  .secondary-level-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    @media ${baseVariables.mediaQuery.lg} {
      padding-right: 0;
    }
  }
  .hide_secondary_form {
    display: none !important;
  }
  .icon-clear:before {
    font-size: ${toRem(24)};
  }
  .icon-arrow-down:before {
    height: ${toRem(16)};
  }
  .icon-arrow-up:before {
    height: ${toRem(16)};
  }
  .header-heading {
    margin-bottom: 0;
  }
  .icon-dropdown-down:before {
    line-height: ${toRem(16)};
    font-size: ${toRem(16)};
  }
  .search-form-wrapper {
    @media ${theme.mediaQuery.desktop} {
      box-shadow: 0px ${toRem(4)} ${toRem(20)} 0px rgba(0, 0, 0, 0.07);
      border-radius: ${toRem(16)};
    }
    label {
      margin-bottom: 0;
    }
  }
  .update-search-btn {
    width: 100%;
    height: ${toRem(52)};
    border-radius: ${toRem(50)};
  }
  .search-btn {
    height: ${toRem(52)};
    border-radius: ${toRem(52)};
  }
  .m-input-text-field {
    label,
    input {
      cursor: pointer;
    }
  }
  .brand-field {
    padding: ${toRem(26)} ${toRem(40)} ${toRem(10)} ${toRem(16)} !important;
    cursor: pointer;
    font-weight: ${baseVariables.font.fontWeightMedium} !important;
    text-overflow: ellipsis;
  }
  .m-input-text-field > label {
    font-size: ${theme.font.fontXs};
    line-height: ${toRem(12)};
  }

  &.sticky-search-container {
    position: fixed;
    width: 100%;
    top: 0 !important;
    left: 0;
    background: ${theme.color.white};
    margin-top: 0 !important;
    z-index: 150;
    padding: 0 ${toRem(16)} !important;
    @media ${theme.mediaQuery.desktop} {
      padding: 0 !important;
    }
    .custom-padding {
      box-shadow: none;
    }
  }
  &.search-container-top {
    padding-top: ${toRem(16)} !important;
    @media ${theme.mediaQuery.desktop} {
      padding-top: ${toRem(32)} !important;
    }
  }

  &.sticky-search-top {
    position: fixed;
    width: 100%;
    left: 0;
    background: ${theme.color.white};
    margin-top: 0 !important;
    z-index: 30;
    transition: top 0.4s ease 0s;
    padding: 0 ${toRem(16)} !important;
    @media ${theme.mediaQuery.desktop} {
      padding: 0 !important;
    }
    .custom-padding {
      box-shadow: none;
    }
  }

  &.search-wrapped {
    .search-form-wrapper {
      border-radius: ${toRem(8)};
      @media ${theme.mediaQuery.lg} {
        border-radius: ${toRem(16)};
      }
    }
  }

  &.sticky-search-wrapped {
    width: 100%;
    background: ${theme.color.white};
    margin-top: 0 !important;
    padding: 0 ${toRem(16)} !important;
    border-radius: 0;
    @media ${theme.mediaQuery.lg} {
      padding: 0 !important;
    }
    .search-form-wrapper {
      box-shadow: none;
    }
  }

  .icon-search {
    font-size: ${toRem(20)};
    top: ${toRem(1)};
    position: relative;
    margin-right: ${toRem(-1.4)};
    ::before {
      height: ${toRem(24)};
      width: ${toRem(24)};
    }
  }
  // .custom-padding {
  //   padding: ${toRem(20)};
  // }
`;

export const StyledSearchFormWrapper = styled.div<{ topOffset: string; isNonStickyVariant?: boolean }>`
  &.search-form-position {
    position: absolute;
    width: 100%;
    top: ${({ topOffset }) => topOffset};
  }
  @media ${baseVariables.mediaQuery.lg} {
    ${props => (props.isNonStickyVariant ? 'z-index: 48;' : 'z-index: 49;')}
  }
`;

export const StyledSearchFormMobileWrapperDiv = styled.div<{
  $isTabbedSearchForm?: boolean;
  isNonStickyVariant?: boolean;
}>`
  display: ${props => (props.$isTabbedSearchForm === true ? '' : 'flex')};
  box-shadow: ${props => (props.$isTabbedSearchForm !== true ? `0 ${toRem(8)} ${toRem(20)} 0 rgba(0, 0, 0, 0.1)` : '')};
  border-radius: ${toRem(8)};
  background: ${theme.color.alert10};
  ${props => (props.isNonStickyVariant ? 'z-index: 48;' : 'z-index: 49;')}
  position: relative;
  .hotels-form-error-messages {
    .message-link {
      text-decoration: underline;
    }
  }
  .adv-search-brand-field-dropdown {
    overflow-y: hidden !important;
    .m-dropdown-children {
      height: inherit;
      overflow-y: auto !important;
      scroll-margin-bottom: ${toRem(16)};
      padding-bottom: ${toRem(116)};
    }
    .m-dropdown-action-bar {
      border-radius: 0;
    }
  }
  @media ${theme.mediaQuery.tablet} {
    justify-content: space-evenly;
  }
  .m-input-text-field-no-border label {
    margin-bottom: ${toRem(4)};
  }
  .tabbed-search-form-errors {
    max-width: max-content;
    margin: 0;
  }
  .hotels-form-error-messages {
    max-width: fit-content;
    margin-left: 0;
    padding: 0 ${toRem(8)};
  }
  .mobile-wrapper-default {
    width: 100%;
  }

  .m-input-text-field-no-border input[type='text'] {
    font-size: ${toRem(18)} !important;
    line-height: ${toRem(20)} !important;
  }

  .mobile-homepage-button {
    z-index: 49;
    color: ${theme.color.neutral40};
    border-color: ${theme.color.neutral30};
    background: ${theme.color.alert10};
    max-height: ${toRem(40)};
    height: ${toRem(40)};
    .t-font-s {
      color: ${theme.color.neutral40};
    }
    &:first-child {
      border-right: ${toRem(1)} dashed ${theme.color['dark-gray-6']};
      padding-right: ${toRem(10)};
      height: ${toRem(40)};
    }
  }

  .mobile-homepage-date-field {
    z-index: 49;
    color: ${theme.color.neutral40};
    border-color: ${theme.color.neutral30};
    background: ${theme.color.alert10};
    max-height: ${toRem(40)};
    height: ${toRem(40)};
    padding-left: ${toRem(20)};
    .t-font-s {
      color: ${theme.color.neutral40};
    }
  }
  .mobile-view-font {
    font-size: ${toRem(18)} !important;
    color: ${theme.color.neutral40} !important;
  }
  .mobile-homepage-button:hover {
    background: ${theme.color.alert10};
    box-shadow: none;
    color: ${theme.color.neutral00};
  }
  .mobile-search-form {
    .m-dropdown-header {
      padding-left: 0;
      padding-right: 0;
      position: sticky;
      top: 0;
      background: ${baseVariables.color['base20']};
      z-index: 1;
      &:after {
        width: 100%;
      }
    }
  }

  .m-input-text-field {
    box-sizing: border-box;
    margin-bottom: ${props => (props.$isTabbedSearchForm !== true ? `${toRem(24)}` : '')};
  }

  .dropdown-to-modal {
    height: ${(props: any) => (props.$isTabbedSearchForm !== true ? `` : '80vh')};
    padding: ${toRem(24)};
    padding-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .m-dropdown-content {
      height: 78vh;
    }
  }
  .search-form-secondary-wrapper {
    border-bottom: ${toRem(1)} solid ${theme.color.neutral30};
  }
`;

export const StyledSearchFormDesktopWrapperDiv = styled.div`
  position:relative;
  .searchfrom-submit-btn {
    position: absolute;
    top: ${toRem(10)};
    right: ${toRem(24)};
    padding-left:${toRem(24)};
    z-index:1;
  }
  .cn-searchfrom-submit-btn{
    top: ${toRem(14)};
    width: 28%;
  }
  .tabbed-search-form-errors {
    max-width: max-content;
    margin-left: 0;
  }
  .hotels-form-error-messages {
    max-width: fit-content;
    margin-left: 0;
    .message-link {
      text-decoration: underline;
    }
  }
  .search-field {
    border: ${theme.border.borderWidth02} ${theme.border.borderStyleDefault} ${theme.color.neutral40};
    border-radius: ${theme.border.borderInset02};
  }
  .primary-wrapper{
    // height: ${toRem(92)};
    // padding: ${toRem(20)} ${toRem(32)};
    transform: rotate(0);
    position: relative;
    z-index: 1;
  }
  .availibility-reward-points{
    @media ${theme.mediaQuery.desktop}{
      position: absolute;
      left: 0;
      padding: 2rem;
      margin-top: 4rem;
    }
  }
  .available-secondary-column{
    @media ${theme.mediaQuery.desktop}{
      margin-bottom: 4rem !important;
    }
  }
  .cn-primary-wrapper{
      .col-lg-4{
        @media ${theme.mediaQuery.tablet} {
          &:nth-child(2) {
            flex: 0 0 33%;
            max-width: 100%;
          }
          &:nth-child(3) {
            flex: 1 0 25%;
          }
        }
        @media ${theme.mediaQuery.largeDesktop} {
        &:nth-child(2) {
          flex: 0 0 38%;
          max-width: 100%;
        }
      }
    }
  }
  .primary-tablet-container{
    height: unset;
    padding: unset;
  }
  .primary-container {
    box-shadow: 0px ${toRem(2)} ${toRem(10)} rgba(0, 0, 0, 0.07);
    border-top-right-radius: ${toRem(16)};
    border-top-left-radius: ${toRem(16)};
    margin-left: 0;
    margin-right: 0;
    padding-right: ${toRem(24)};
    padding-left: ${toRem(24)};
  }
  .dotted-divider {
    border-right: ${toRem(1)} dashed ${baseVariables.color.neutral30}};
  }
  .m-custom-select-block {
    display: flex;
  }
  .m-custom-select-block .icon-dropdown-down {
    margin-left: ${toRem(4)};
  }
  .search-form-secondary-wrapper {
    height: ${toRem(52)};
    white-space: nowrap;
  }
  .search-form-secondary-wrapper-tabbed {
    height: ${toRem(52)};
    padding:0;
    position: initial
  }
  .adv-brand-filter {
    .custom-scrollbar {
      ::-webkit-scrollbar {
        width: ${toRem(5)};
      }
      ::-webkit-scrollbar-track {
        background: ${theme.color.base20};
        border-radius: ${toRem(2)};
        margin: ${toRem(12)} 0;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${theme.color.neutral20};
        border-radius: ${toRem(2)};
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${theme.color.neutral40};
      }
    }
  }
  .adv-search-brands-dropdown {
    max-width: ${toRem(348)};
    width: ${toRem(348)};
    height: ${toRem(460)};
    max-height: ${toRem(460)};
    min-height: ${toRem(460)};
    overflow-y: visible;
    border-radius: ${toRem(14)};
    .adv-search-brand-field-dropdown {
      position: static;
      height: ${toRem(460)};
      max-height: ${toRem(460)};
      min-height: ${toRem(460)};
      .m-dropdown-action-bar {
        background: ${baseVariables.color.base20};
        padding-bottom: ${toRem(16)};
        padding-right:${toRem(9)};
      }
      .brand-filter__category_label_btn {
        .btn-label-container {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
`;
