import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledAvailabilityDiv = styled.div`
  ${rtl`
  padding-top: ${toRem(24)};
  .ada-hide{
    display: none;
  }
  .availability-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${toRem(0)};
    .show-unavailable-properties-checkbox-label {
      text-transform: none;
      letter-spacing: 0;
    }
    .checkbox-switch-wrapper {
      display: flex;
      align-items: center;

      &:active {
        outline: none !important;
      }
      .checkbox-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${theme.color['light-gray-6']};
        transition: 0.4s;
        border-radius: ${toRem(34)};
        border: ${toRem(1)} solid ${theme.color['light-gray-3']};
        &:before {
          position: absolute;
          content: '';
          height: ${toRem(28)};
          width: ${toRem(28)};
          left: ${toRem(0.5)};
          top: ${toRem(1)};
          background-color: ${theme.color['white']};
          transition: 0.4s;
          border-radius: ${toRem(34)};
          border: none;
        }
      }
      .checkbox-switch {
        display: inline-block;
        width: ${toRem(52)};
        height: ${toRem(32)};
        position: relative;
        margin-right: 0;

        &::before {
          border-color: transparent;
        }
      }
      .checkbox-switch + input {
        opacity: 0;
      }

      input[type='checkbox']:checked + .checkbox-slider {
        background-color: ${theme.color['black']};
        border-color: ${theme.color['light-gray-3']};
      }

      input[type='checkbox']:checked + .checkbox-slider:before {
        background-color: ${theme.color['light-gray-3']};
        transform: translateX(${toRem(19)});
        border-radius: 50%;
        left: ${toRem(2)};
      }
      &:focus,
      &:active {
        outline: ${toRem(1)} solid ${theme.color['black']};
        outline-offset: ${toRem(2)};
      }
    }
  }

  @media only ${theme.mediaQuery.allMobiles} {
    padding-top: ${toRem(16)};
    padding-bottom: ${toRem(16)};
  }
  `}
`;

export const StyledAvailabilityFilterHeaderContainerDiv = styled.div`
  ${rtl`
  margin-bottom: ${toRem(16)};
  color: ${theme.color['base-10']};
  `}
`;
