/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCurrentDateObject, getDateObj, getNextDateObject } from '@marriott/mi-ui-library';
import {
  SPECIAL_RATES,
  DATES,
  DESTINATION,
  ROOMS_AND_GUESTS,
  USE_POINTS,
  DESTINATION_HIDDEN_FIELDS,
  LATITUDE_AND_LONGITUDE,
  BRANDS,
  ERROR_MESSAGE,
  MEETINGS_DESTINATION,
  MEETINGS_DATES,
  EVENTS_DESTINATION_HIDDEN_FIELDS,
  EVENT_PURPOSE,
  GUEST_ROOMS,
  EVENT_SPACE,
} from '../../modules/store/store.constants';
import { JP_LOCALE, KR_LOCALE, MAX_NUMBER_OF_NIGHTS_ALLOWED } from '../constants/Calendar.constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDestinationText = (searchCriteria: any) => {
  const { destinationAddressMainText, destinationAddressSecondaryText, address, airportCode, poiName } = searchCriteria;
  if (address?.destination) {
    return address?.destination;
  } else if (destinationAddressMainText && destinationAddressSecondaryText) {
    return `${destinationAddressMainText}, ${destinationAddressSecondaryText}`;
  } else {
    return `${airportCode}, ${poiName}`;
  }
};

export const getMeetingsAndEventsDestinationText = (groupSearchCriteria: { address: any }) => {
  const { address } = groupSearchCriteria;
  if (address?.destination) {
    return address?.destination;
  } else if (address?.destinationAddressMainText && address?.destinationAddressSecondaryText) {
    return `${address.destinationAddressMainText}, ${address.destinationAddressSecondaryText}`;
  }
};

export const invalidDates = (search_date_check_in: string, search_date_check_out: string) => {
  // Function to check if a date string is valid
  const isValidDate = (dateString: any) => {
    return !isNaN(Date?.parse(dateString));
  };

  // Extract fromDate and toDate from the session
  const fromDate = search_date_check_in;
  const toDate = search_date_check_out;
  const bothDatesNullOrEmpty = (fromDate === null || fromDate === '') && (toDate === null || toDate === '');

  // Check if both fromDate and toDate are present in the URL
  if (fromDate !== null && toDate !== null) {
    // Check if the dates are invalid
    const fromDateInvalid = !isValidDate(fromDate);
    const toDateInvalid = !isValidDate(toDate);
    // Set a flag based on the validity of fromDate and toDate
    const invalidDatesFlag = fromDateInvalid || toDateInvalid;
    // Trigger the function only if there are invalid dates
    if (invalidDatesFlag) {
      return invalidDatesFlag;
    }
  } else if (bothDatesNullOrEmpty) {
    return true;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function initializeSearchForm(data: any, modelData: any): any {
  const { AriesSearch, AriesCommon, AriesGroupSearch } = data || {};
  let sessionObj = {};
  if (!AriesSearch && !AriesGroupSearch) {
    return sessionObj;
  }

  const currentDate = getCurrentDateObject();
  const nextDate = getNextDateObject(currentDate);
  // Read the group search form session data for Meetings and Events searchform
  if (AriesGroupSearch) {
    const { groupSearchCriteria } = AriesGroupSearch;
    const {
      address: meetingsAndEventsAddress,
      checkInDate: meetingsAndEventsCheckInDate,
      checkOutDate: meetingsAndEventsCheckOutDate,
      lengthOfStay: meetingsAndEventsLengthOfStay,
      isFlexibleDate: meetingsAndEventsIsFlexibleDate,
      eventSearchType,
      guestRoomCount,
      sizeLargestMeetingRoom,
    } = groupSearchCriteria;

    let meetingsAndEventsFlexible;
    let meetingsAndEventsFromDate;
    let meetingsAndEventsToDate;
    let meetingsAndEventsNumOfNights;

    /*
        If check-in and check-out dates are missing and flexibleDates flag is false,
        then it will be considered as 1 night flexible dates flow for current month
      */
    if (
      !meetingsAndEventsCheckInDate &&
      !meetingsAndEventsCheckOutDate &&
      meetingsAndEventsIsFlexibleDate === 'false'
    ) {
      meetingsAndEventsFlexible = true;
      meetingsAndEventsFromDate = currentDate;
      meetingsAndEventsToDate = nextDate;
      meetingsAndEventsNumOfNights = 1;
    } else {
      meetingsAndEventsFlexible = meetingsAndEventsIsFlexibleDate;
      meetingsAndEventsFromDate = getDateObj(meetingsAndEventsCheckInDate);
      meetingsAndEventsToDate = getDateObj(meetingsAndEventsCheckOutDate);
      meetingsAndEventsNumOfNights = meetingsAndEventsIsFlexibleDate
        ? meetingsAndEventsLengthOfStay > MAX_NUMBER_OF_NIGHTS_ALLOWED
          ? 1
          : meetingsAndEventsLengthOfStay
        : meetingsAndEventsLengthOfStay;
    }

    sessionObj = {
      [ERROR_MESSAGE]: {
        errorMessages: '',
      },
      [MEETINGS_DESTINATION]: {
        eventsDisplayText: getMeetingsAndEventsDestinationText(groupSearchCriteria),
        eventsDestinationAddressPlaceId: groupSearchCriteria?.destinationAddressPlaceId,
      },
      [MEETINGS_DATES]: {
        eventsFromDate: meetingsAndEventsFromDate,
        eventsToDate: meetingsAndEventsToDate,
        flexible: meetingsAndEventsFlexible,
        numberOfNights: meetingsAndEventsNumOfNights,
      },
      [EVENTS_DESTINATION_HIDDEN_FIELDS]: {
        latitude: meetingsAndEventsAddress?.latitude,
        longitude: meetingsAndEventsAddress?.longitude,
        destinationAddressMainText: meetingsAndEventsAddress?.destinationAddressMainText,
        destinationAddressSecondaryText: meetingsAndEventsAddress?.destinationAddressSecondaryText,
        city: meetingsAndEventsAddress?.city,
        state: meetingsAndEventsAddress?.stateProvince,
        country: meetingsAndEventsAddress?.country,
        destinationType: meetingsAndEventsAddress?.destinationType,
      },
      [EVENT_PURPOSE]: {
        purpose: eventSearchType,
      },
      [GUEST_ROOMS]: {
        noOfRooms: guestRoomCount,
      },
      [EVENT_SPACE]: {
        noOfAttendees: sizeLargestMeetingRoom,
      },
    };
  }

  if (AriesSearch) {
    let invalidDate: any = '';
    const search_date_check_in = AriesCommon?.search_date_check_in;
    const search_date_check_out = AriesCommon?.search_date_check_out;

    const { searchCriteria } = AriesSearch;
    const { styleclass } = modelData;
    let errorMessages: any = '';
    if (AriesSearch && AriesSearch?.errorMessages && styleclass) {
      errorMessages = AriesSearch?.errorMessages;
      invalidDate = invalidDates(search_date_check_in, search_date_check_out);
    }

    const rk_clusterCode = AriesCommon?.rk_clusterCode;
    const isRewardRedemption = AriesCommon?.isRewardRedemption;
    const search_isFlexibleDate = AriesCommon?.search_isFlexibleDate;
    const clustCode = AriesCommon?.clustCode;
    const res_num_adults = AriesCommon?.res_num_adults;
    const res_num_children = AriesCommon?.res_num_children;

    let fromDate;
    let toDate;
    let flexible;
    let numOfNights;

    if (search_isFlexibleDate === true || search_isFlexibleDate === 'true') {
      flexible = true;
      fromDate = search_date_check_in && getDateObj(new Date(search_date_check_in));
      toDate = search_date_check_out && getDateObj(new Date(search_date_check_out));
      numOfNights = AriesCommon?.lengthOfStay;
    } else if (
      search_date_check_in &&
      search_date_check_out &&
      (search_isFlexibleDate === false || search_isFlexibleDate === 'false')
    ) {
      if (invalidDate) {
        flexible = false;
        fromDate = null;
        toDate = null;
        numOfNights = null;
      } else {
        flexible = false;
        fromDate = search_date_check_in && getDateObj(new Date(search_date_check_in));
        toDate = search_date_check_out && getDateObj(new Date(search_date_check_out));
        numOfNights = AriesCommon?.lengthOfStay;
      }
    } else if (AriesSearch && AriesSearch?.errorMessages && invalidDate) {
      flexible = false;
      fromDate = null;
      toDate = null;
      numOfNights = null;
    }

    let numbRooms = '';
    const { roomListLabel } = modelData;
    const tempArr = roomListLabel?.split(', ');
    const roomCounts = tempArr?.filter((item: string) => {
      return item?.includes(AriesCommon?.numRooms);
    });
    if (AriesCommon?.numRooms) {
      if (AriesCommon?.numRooms === 4) {
        numbRooms = roomCounts?.length ? roomCounts[0] : '4-9';
      } else if (AriesCommon?.numRooms === 10) {
        numbRooms = roomCounts?.length ? roomCounts[0] : '10-25';
      } else if (AriesCommon?.numRooms === 26) {
        numbRooms = roomCounts?.length ? roomCounts[0] : '26+';
      } else {
        numbRooms = AriesCommon?.numRooms;
      }
    }
    let corporate_code = '';
    if (
      rk_clusterCode &&
      (rk_clusterCode === 'CORP' || rk_clusterCode === 'GOV' || rk_clusterCode === 'AAA' || rk_clusterCode === 'NONE')
    ) {
      corporate_code = rk_clusterCode.toLowerCase();
    } else {
      corporate_code = rk_clusterCode ? rk_clusterCode : 'none';
    }
    let rewardsRed = false;
    if (isRewardRedemption && isRewardRedemption === 'true') {
      rewardsRed = true;
    } else {
      rewardsRed = false;
    }

    // Check if searchCriteria is present in session document
    if (AriesSearch && AriesSearch?.errorMessages && AriesCommon) {
      let destination = AriesSearch?.search_keyword && AriesSearch?.search_keyword;
      let locationBlank = true;
      if (
        errorMessages?.submitSearchFormErrorMessages?.errorMessageKeys[0] ===
        'search-error-messages|location.blank.invalid'
      ) {
        destination = '';
        locationBlank = false;
      }
      if (!AriesSearch?.search_keyword) {
        if (locationBlank) {
          return (sessionObj = {
            ...sessionObj,
            [ERROR_MESSAGE]: {
              errorMessages: errorMessages,
            },
          });
        } else {
          return (sessionObj = {
            ...sessionObj,
            [ERROR_MESSAGE]: {
              errorMessages: errorMessages,
            },
            [DESTINATION]: {
              displayText: destination,
            },
            [DATES]: {
              lastDefaultDate: search_date_check_in,
              fromDate,
              toDate,
              flexible,
              search_date_check_in,
              search_date_check_out,
              lengthOfStay: numOfNights,
              numberOfNights: numOfNights,
            },
          });
        }
      } else if (
        AriesSearch &&
        AriesSearch?.errorMessages &&
        rk_clusterCode &&
        search_date_check_in &&
        res_num_adults &&
        AriesSearch?.search_keyword
      ) {
        sessionObj = {
          ...sessionObj,
          [ERROR_MESSAGE]: {
            errorMessages: errorMessages,
          },
          [DESTINATION]: {
            displayText: destination,
          },
          [DATES]: {
            lastDefaultDate: search_date_check_in,
            fromDate,
            toDate,
            flexible,
            search_date_check_in,
            search_date_check_out,
            lengthOfStay: numOfNights,
            numberOfNights: numOfNights,
          },
          [ROOMS_AND_GUESTS]: {
            numRooms: numbRooms,
            numAdultsPerRoom: res_num_adults,
            numChildrenPerRoom: res_num_children ? res_num_children : 0,
            childrenAges: AriesCommon?.childrenAges ? AriesCommon?.childrenAges : [],
          },
          [USE_POINTS]: {
            rewardsRedemption: rewardsRed,
          },
          [SPECIAL_RATES]: {
            clusterCode: rk_clusterCode?.toLocaleLowerCase(),
            specialRateCode: corporate_code,
            corporateCode: clustCode ? clustCode : '',
          },
          [BRANDS]: {
            marriottBrands: AriesCommon?.brandCodes ? AriesCommon?.brandCodes : '',
          },
        };
        if (!AriesGroupSearch) {
          sessionObj = {
            ...sessionObj,
            [MEETINGS_DESTINATION]: {
              eventsDisplayText: destination,
            },
            [MEETINGS_DATES]: {
              eventsLastDefaultDate: search_date_check_in,
              eventsFromDate: fromDate,
              eventsToDate: toDate,
              numberOfNights: numOfNights,
            },
          };
        }
        return sessionObj;
      } else if (AriesSearch && AriesSearch?.errorMessages) {
        return (sessionObj = {
          ...sessionObj,
          [ERROR_MESSAGE]: {
            errorMessages: errorMessages,
          },
        });
      } else {
        return sessionObj;
      }
    } else if (AriesSearch && !AriesSearch?.errorMessages && !AriesSearch?.searchCriteria) {
      return (sessionObj = {
        ...sessionObj,
        [ERROR_MESSAGE]: {
          errorMessages: '',
        },
      });
    }
    const {
      availabilityRequestVO,
      destinationAddressPlaceId,
      keywordSearchRequestVO,
      searchType,
      address,
      destinationAddressMainText,
      destinationAddressSecondaryText,
      searchRadius,
      brandCodes,
      propertyId,
    } = searchCriteria;
    const {
      checkInDate,
      checkOutDate,
      flexibleDate,
      lengthOfStay,
      numRooms,
      numAdultsPerRoom,
      numChildrenPerRoom,
      childrenAges,
      rewardsRedemption,
      clusterCode,
      groupCode,
    } = availabilityRequestVO;
    let { corporateCode } = availabilityRequestVO;
    let specialRateCode = clusterCode;
    /* istanbul ignore else */
    if (specialRateCode === 'AAA' || specialRateCode === 'GOV' || specialRateCode === 'NONE') {
      specialRateCode = specialRateCode?.toLocaleLowerCase();
    }
    if (specialRateCode?.toUpperCase() === 'GROUP' && groupCode) {
      specialRateCode = specialRateCode?.toLocaleLowerCase();
      corporateCode = groupCode;
    }
    let latitude;
    let longitude;

    /* istanbul ignore else */
    if (address?.latitude && address?.longitude) {
      latitude = address?.latitude;
      longitude = address?.longitude;
    }

    /*
        If check-in and check-out dates are missing and flexibleDates flag is false,
        then it will be considered as 1 night flexible dates flow for current month
      */
    if (!checkInDate && !checkOutDate && flexibleDate === false) {
      flexible = true;
      fromDate = currentDate;
      toDate = nextDate;
      numOfNights = 1;
    } else {
      flexible = flexibleDate;
      fromDate = getDateObj(checkInDate);
      toDate = getDateObj(checkOutDate);
      numOfNights = flexibleDate ? (lengthOfStay > MAX_NUMBER_OF_NIGHTS_ALLOWED ? 1 : lengthOfStay) : lengthOfStay;
    }

    if ((destinationAddressPlaceId || address?.destination) && !AriesSearch?.errorMessages) {
      sessionObj = {
        ...sessionObj,
        [DESTINATION]: {
          displayText: getDestinationText(searchCriteria),
          destinationAddressPlaceId: destinationAddressPlaceId,
        },
        [DATES]: {
          lastDefaultDate: checkInDate,
          fromDate,
          toDate,
          flexible,
          checkInDate,
          checkOutDate,
          lengthOfStay: numOfNights,
          numberOfNights: numOfNights,
        },
        [ROOMS_AND_GUESTS]: {
          numRooms: numRooms,
          numAdultsPerRoom: numAdultsPerRoom,
          numChildrenPerRoom: numChildrenPerRoom,
          childrenAges: childrenAges,
        },
        [DESTINATION_HIDDEN_FIELDS]: {
          latitude: latitude,
          longitude: longitude,
          destinationAddressMainText: destinationAddressMainText,
          destinationAddressSecondaryText: destinationAddressSecondaryText,
          city: address?.city,
          state: address?.stateProvince,
          country: address?.country,
          searchRadius: searchRadius,
          recentlyViewedPopertyCode: propertyId,
          destinationType: address?.destinationType,
        },
        [LATITUDE_AND_LONGITUDE]: {
          latitude: latitude,
          longitude: longitude,
        },
        [USE_POINTS]: {
          rewardsRedemption: rewardsRedemption,
        },
        [BRANDS]: {
          marriottBrands: brandCodes,
        },
        [SPECIAL_RATES]: {
          clusterCode: clusterCode,
          specialRateCode: specialRateCode,
          corporateCode: corporateCode,
        },
        [ERROR_MESSAGE]: {
          errorMessages: '',
        },
      };
    }

    if (searchType === 'Keyword' && !AriesSearch?.errorMessages) {
      sessionObj = {
        ...sessionObj,
        [DESTINATION]: {
          displayText: `${keywordSearchRequestVO?.keywords}`,
        },
        [DATES]: {
          flexibleDate: true,
        },
        [USE_POINTS]: {
          rewardsRedemption: rewardsRedemption,
        },
        [BRANDS]: {
          marriottBrands: brandCodes,
        },
        [SPECIAL_RATES]: {
          clusterCode: clusterCode,
          specialRateCode: specialRateCode,
          corporateCode: corporateCode,
        },
        [ERROR_MESSAGE]: {
          errorMessages: '',
        },
      };
    }
  }

  return sessionObj;
}

export function searchFormHelperFunction(
  state: Record<string, any>,
  fieldNames: Array<string>,
  fieldsData: Record<string, any>,
  reset?: boolean
): object {
  const updatedState = state;
  //to reset everything inside the given field , this will not reset the whole store but only reset the given fieldname
  if (reset) {
    fieldNames.forEach((name: string) => {
      updatedState[name] = {};
    });
  }
  fieldNames.forEach((name: string) => {
    updatedState[name] = { ...updatedState[name], ...fieldsData[name] };
  });
  return updatedState;
}

/* istanbul ignore next */
export const constructSearchformLabels = (model: any) => {
  if (model) {
    const isTabbedSearchForm =
      typeof model?.['styleclass'] === 'string' && model?.['styleclass'].toLowerCase() === 'phoenix' ? true : false;

    model['isTabbedSearchForm'] = isTabbedSearchForm;
    model['submitAction'] = model['submitAction'] ?? 'default';

    model['submitSpecificDateCta'] = model['submitSpecificDateCta'] ?? model['dateModuleSubmitCta'];
    model['flexibleDatesSubmitCta'] = model['flexibleDatesSubmitCta'] ?? model['flexibleSearchSubmitCTA'];
    model['resetSpecificDateCta'] = model['resetSpecificDateCta'] ?? model['dateModuleResetCta'];
    model['flexibleDatesResetCta'] = model['flexibleDatesResetCta'] ?? model['reset'];

    model['enableWeekendSelectorOnMobile'] = model['enableWeekendSelectorOnMobile'] ?? model['enableWeekendSelector'];
    model['dateModalHeader'] = model['dateModalHeader'] ?? model['stayDates'];
    model['destinationModalHeader'] = model['destinationModalHeader'] ?? model['placeholderTextMobile'];

    model['nameOfRecentlyViewedSection'] =
      model['nameOfRecentlyViewedSection'] ?? model['placeholderTextrecentlyViewedLabel'];
    model['nameOfRecentSearchesSection'] =
      model['nameOfRecentSearchesSection'] ?? model['placeholderTextRecentSearchesLabel'];
    model['nameOfPopularDestinationSection'] =
      model['nameOfPopularDestinationSection'] ?? model['trendingDestinationsLabel'];
    model['nightLabel'] = model['nightLabel'] ?? model['night'];
    model['night'] = model['night'] ?? model['nightLabel'];
    model['roomsAndGuestEyebrowText'] = model['roomsAndGuestEyebrowText'] ?? model['roomsAndGuestEybrowText'];
    model['placeholderTextMobile'] = model['placeholderTextMobile'] ?? model['expandedHelperTextMobile'];
    model['findHotelLabel'] = model['findHotelLabel'] ?? model['submitCTA'];
  }
};
//function to check if recent view and recent searches are available on localstorgae
export const isRecentViewAndSearchAvailable = (isServer: any, currentLocale: string) => {
  const getRecentSearchList: string | null = !isServer ? localStorage.getItem('miRecentSearch') : null;
  const recentSearchesAll: Array<Record<string, string>> = getRecentSearchList && JSON.parse(getRecentSearchList);
  //this function will fetch the last 3 recently searched destinations from localstorage
  const recentSearches: Array<Record<string, string>> = recentSearchesAll?.slice(-3);
  if (recentSearches && recentSearches.length > 0) {
    const nonPastDateSearches = recentSearches.filter(item => {
      //converting date to hours(0,0,0,0) to avoid time comparision , only dates are being considered
      return new Date(item['fromDate']).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    });
    if (nonPastDateSearches && nonPastDateSearches.length) {
      const nonFlexibleSearches = nonPastDateSearches;
      // added JP and KR locale condition as we want the condition to be false for these 2 locales here and show it on CityPickList
      if (nonFlexibleSearches?.length && currentLocale !== JP_LOCALE && currentLocale !== KR_LOCALE) {
        return true;
      }
    }
  }
  const getRecentViewList: string | null = !isServer ? localStorage.getItem('miRecentlyViewedProperties') : null;
  const recentlyViewedAll: Array<Record<string, string>> =
    getRecentViewList && JSON.parse(getRecentViewList)?.recentlyViewedProperties;
  //this function will fetch the last 3 recently viewed properties from localstorage
  const recentlyViewed: Array<Record<string, string>> = recentlyViewedAll?.slice(0, 3);
  // added JP and KR locale condition as we want the condition to be false for these 2 locales here and show it on CityPickList
  if (recentlyViewed && recentlyViewed.length > 0 && currentLocale !== JP_LOCALE && currentLocale !== KR_LOCALE) {
    return true;
  }
  return false;
};
