/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { eventUtil } from 'libs/mi-ui-library/src/utils/eventUtil';
import { useLazyQuery } from '@apollo/client';
import {
  phoenixLeisureDestinationPropertiesByGeolocation,
  phoenixLeisureDestinationPropertiesByLocation,
  phoenixLeisureSearchPropertiesByGeolocation,
  phoenixLeisureSearchPropertiesByLocation,
} from '@marriott/mi-leisure-graphql';
import { UXL_ERROR_POLICY } from 'libs/mi-offers-components/src/api/apiConstants';
import { getSearchResultQuery, getSortByVal, priceDimensionMapping } from '../utils/helper';
import { getDateFormatted } from '../utils/dateUtils';
import { PropertyCard } from '@marriott/mi-shop-components/src/molecules';
import { APPLICATION_BREAKPOINTS, useWindowSize } from '@marriott/mi-ui-library';
import { SkeletonOutletCardLoader } from 'libs/mi-rnb-components/src/organisms/SearchResults/SkeletonOutletCardLoader';
import { StyledPropertiesListWrapper } from './PropertiesListWrapper.styles';
import clsx from 'clsx';
import { getUrlParam, useGetBreakpoint } from '../../../../src/utils/CommonUtils';
import AlertNotification from 'libs/mi-ui-library/src/atoms/Alerts';
import { recentViewedPropertiesType } from './PropertiesListWrapper.types';
import {
  descriptionToDisplayOnPropertyCard,
  FEEDESCRIPTION1,
  FEEDESCRIPTION2,
  MANDATORYFEE_TITLE,
  MARRIOTT_CACHE_URL,
  outputInterpolation,
  outputQuality,
  PARAM_PAGENO,
  SURCHARGE_ORDINANCE_CODE,
  VIEW_RATE_LABEL,
} from '../../../constants/CommonConstants';
import { Pagination } from '@marriott/mi-ui-library';
import { imgDimensionType, propertyCardDataType, searchDataType } from '../OfferPropertiesList.types';
import { PropertiesListFilter } from '../PropertiesListFilter';
import { BrandProps, ControlsProps, availableBrandsType } from '../PropertiesListFilter/PropertiesListFilter.types';
import moment from 'moment';
import { processAcceptLang } from '../../../utils/CommonUtils';
import { EXTERNAL_CTA_TYPE, HOTEL_VIEW } from 'libs/mi-offers-components/src/constants/CommonConstants';
import { RECENT_SEARCH_LIST_LIMIT } from '@marriott/mi-leisure-components/constants';
import { SortByFilterComponent } from './sortByFilter';
import { usePersistentGlobalStore } from '@marriott/mi-store-utils';
import { constructJsonSchema } from '../../../../src/utils/generateJsonSchemaUtils';
import axios from 'axios';
import { getCalculatedDistance } from '@marriott/mi-shop-components';

// WEB-70169 - using this to excude the mock file from production build
const propertiesListUXLMockJson = async (isAuthorMode: boolean, isStateCountryOrSearch: boolean) => {
  if (process.env['NODE_ENV'] === 'development' || isAuthorMode) {
    const propertiesListUXLMockJson: any = await (isStateCountryOrSearch
      ? import('../__mock__/phoenixLeisureDestinationPropertiesByLocation.mock.json')
      : import('../__mock__/phoenixLeisureDestinationPropertiesByGeolocation.mock.json')); //Load mock model dynamically only for dev mode
    return propertiesListUXLMockJson;
  }
  return {};
};

export const PropertiesListWrapper: React.FC<any> = props => {
  const isMobileViewPort = useGetBreakpoint() === 'mobile';
  const { width } = useWindowSize();
  const { isAuthorMode, model, sessionData } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalData = usePersistentGlobalStore((state: any) => state.globalData);
  const ariesOffer = globalData?.sessionData?.cacheData?.data?.AriesOffer ?? {};
  // const searchQuery = sessionData?.cacheData?.data?.AriesCommon?.page_url_query_string ?? '';
  const destinationMetaData = props?.destinationMetaData ?? {};

  const cardsCount = model?.propertiesPerPage ? Number(model?.propertiesPerPage) : 8;
  const acceptLanguage = processAcceptLang(props?.acceptLanguage ?? '');
  const requestId = props?.requestId;
  const startDate = moment(new Date()).format('YYYY-MM-DD');
  const endDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
  // const defaultStartDate = getMPOFormattedDateNew(stayStartDate).currMPODate;
  const subdirectory = props?.subDirectoryVal ?? '';
  const clusterCode = props?.offersData?.responseObject?.edges[0].node.clusterCode;
  const { isStateEnabled, isCountryEnabled } = destinationMetaData ?? {};

  const Enable_DTT_queries = false;
  const propertiesQuery =
    isCountryEnabled || isStateEnabled
      ? Enable_DTT_queries
        ? phoenixLeisureSearchPropertiesByLocation
        : phoenixLeisureDestinationPropertiesByLocation
      : Enable_DTT_queries
      ? phoenixLeisureSearchPropertiesByGeolocation
      : phoenixLeisureDestinationPropertiesByGeolocation;
  const [onloadInput, setOnloadInput] = useState<string>('');
  const [dataLoaderFlag, setDataLoaderFlag] = useState<boolean>(true);
  const [updateCards, setUpdateCards] = useState<boolean>(true);
  const [pageCardCountInfo, setPageCardCountInfo] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [sortOptions, setSortOptions] = useState<{ sortByOptionTitle: string; sortByOptionCode: string }[]>([]);
  //sort
  const sortBySessionVal = ariesOffer?.['sortBy'];

  const [sortBy, setSortBy] = useState(
    getSortByVal(sortBySessionVal, { isStateEnabled: isStateEnabled, isCountryEnabled: isCountryEnabled })
  );
  // const [sortBy, setSortBy] = useState(isCountryEnabled || isStateEnabled ? 'CITY' : 'DISTANCE');
  //pagination
  let page = 1;
  let offset = 0;
  if (typeof window !== 'undefined') {
    const url = new URL(window?.location?.href, window?.location?.href); // Use the full URL
    const pageNo = url.searchParams.get(PARAM_PAGENO);
    page = parseInt(pageNo ? pageNo : '1');
    offset = (page - 1) * cardsCount;
  }
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [offsetValue, setOffsetValue] = useState<number>(offset);
  //const offsetValue = offset;
  const mandatoryFeeMessages = {
    title: model?.mandatoryFeeTitle ?? MANDATORYFEE_TITLE,
    feeDescription1: model?.feeDescription1 ?? FEEDESCRIPTION1,
    feeDescription2: model?.feeDescription2 ?? FEEDESCRIPTION2,
    viewRatesLabel: model?.viewRatesLabel ?? VIEW_RATE_LABEL,
  };

  const isStateOrCountryEnabled = isStateEnabled || isCountryEnabled;

  const AVAILABLE_RATE_STATUS = Enable_DTT_queries ? 'AvailableForSale' : 'available';

  const getImageSetup = (): imgDimensionType => {
    let imageDimensions = {
      height: 288,
      width: 512,
    };
    if (Number(width) < APPLICATION_BREAKPOINTS['viewportL']) {
      imageDimensions = {
        height: 240,
        width: 360,
      };
    }
    if (Number(width) > APPLICATION_BREAKPOINTS['viewportL'] || Number(width) < APPLICATION_BREAKPOINTS['viewportS']) {
      imageDimensions = {
        height: 222,
        width: 715,
      };
    }
    return imageDimensions;
  };
  const getImageProps = (): string => {
    const imageDimensions = getImageSetup();
    const imgProp =
      `?output-quality=${outputQuality}&interpolation=${outputInterpolation};*,*&downsize=` +
      imageDimensions.width +
      'px:*';
    return imgProp;
  };
  const imageProperties = getImageProps();
  //scroll to top of the list on pagination.
  const handleScrollOnPagination = (): void => {
    const targetSection = document.getElementById('filter-btns-wrapper')?.offsetTop;
    window.scrollTo({
      top: targetSection ? targetSection - 100 : 0,
      behavior: 'smooth',
    });
  };
  const onPageItemClick = (pageNumber: number): void => {
    if (!model.seoFriendlyPagination) {
      setDataLoaderFlag(true);
      const offset = (pageNumber - 1) * cardsCount;
      setCurrentPage(pageNumber);
      setOffsetValue(offset);
      handleScrollOnPagination();
    }
  };

  const [searchData, setSearchData] = useState<searchDataType>({
    startDate: startDate,
    endDate: endDate,
    childAges: [],
    numberInParty: 1,
    quantity: 1,
  });
  const [propertyCardData, setPropertyCardData] = useState<propertyCardDataType>({
    hotels: [],
    totalProperties: 0,
  });

  const [isNewFacetsLoading, setIsNewFacetsLoading] = useState<boolean>(false);
  let keepDefaultFilters = false;
  if (
    destinationMetaData?.amenities?.length ||
    destinationMetaData?.activities?.length ||
    destinationMetaData?.hotelType?.length
  ) {
    keepDefaultFilters = true;
  }
  //For dynamic filters
  const setDynamicFilterState = (
    keepDefaultFilters: boolean,
    destinationData: [] | undefined,
    sessionData: [] | undefined
  ): [] => {
    return keepDefaultFilters && destinationData?.length ? destinationData : sessionData?.length ? sessionData : [];
  };
  // brand filter state variables
  const [availableBrands, setAvailableBrands] = useState<availableBrandsType[]>([]); // brands which are cominng as facets from uxl & available for selection
  const [selectedBrands, setSelectedBrands] = useState<BrandProps[]>(ariesOffer?.['selectedBrands'] ?? []);
  // brands which are selected for filtering of list
  const sessionBrandFacetList =
    ariesOffer && ariesOffer?.['selectedBrands'] ? ariesOffer?.['selectedBrands']?.map((item: any) => item.code) : [];
  const [brandFacets, setBrandFacets] = useState<string[]>(sessionBrandFacetList?.length ? sessionBrandFacetList : []);
  const [dynamicBrand, setDynamicBrand] = useState<string[]>([]); //
  //amenties filter
  const [availabledAmenities, setAvailableAmenities] = useState<ControlsProps[]>([]);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>(ariesOffer?.['selectedAmenities'] ?? []);
  const sessionAmenitiesFacetList =
    ariesOffer && ariesOffer?.['selectedAmenities']
      ? ariesOffer?.['selectedAmenities']?.map((item: any) => item?.code)
      : [];
  const [amentiesFacets, setAmenitiesFacets] = useState<string[]>(
    keepDefaultFilters && destinationMetaData?.amenities?.length
      ? destinationMetaData?.amenities
      : sessionAmenitiesFacetList?.length
      ? sessionAmenitiesFacetList
      : []
  );
  //For Aminities
  const [dynamicAmenities, setDynamicAmenities] = useState<string[]>(
    setDynamicFilterState(keepDefaultFilters, destinationMetaData?.amenities, sessionAmenitiesFacetList)
  );
  //transportation filter
  const [availableTransportaion, setAvailableTransportaion] = useState<ControlsProps[]>([]);
  const [selectedTransportaion, setSelectedTransportaion] = useState<string[]>(
    ariesOffer?.['selectedTransportaion'] ?? []
  );
  const sessionTransportaionFacetList =
    ariesOffer && ariesOffer?.['selectedTransportaion']
      ? ariesOffer?.['selectedTransportaion'].map((item: any) => item?.code)
      : [];
  const [transportaionFacets, setTransportaionFacets] = useState<string[]>(
    sessionTransportaionFacetList.length ? sessionTransportaionFacetList : []
  );
  const [dynamicTransportation, setDynamicTransportation] = useState<string[]>([]);
  //hotel type filter
  const [availableHotelType, setAvailableHotelType] = useState<ControlsProps[]>([]);
  const [selectedHotelType, setSelectedHotelType] = useState<string[]>(ariesOffer?.['selectedHotelType'] ?? []);
  const sessionHotelTypeFacetList =
    ariesOffer && ariesOffer?.['selectedHotelType']
      ? ariesOffer?.['selectedHotelType'].map((item: any) => item?.code)
      : [];
  const [hotelTypeFacets, setHotelTypeFacets] = useState<string[]>(
    keepDefaultFilters && destinationMetaData?.hotelType?.length
      ? destinationMetaData?.hotelType
      : sessionHotelTypeFacetList.length
      ? sessionHotelTypeFacetList
      : []
  );
  //For hotelType
  const [dynamicHotelType, setDynamicHotelType] = useState<string[]>(
    setDynamicFilterState(keepDefaultFilters, destinationMetaData?.hotelType, sessionHotelTypeFacetList)
  );
  //events filter
  const [availablEvents, setAvailableEvents] = useState<ControlsProps[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<string[]>(ariesOffer?.['selectedEvents'] ?? []);
  const sessionEventsFaceList =
    ariesOffer && ariesOffer?.['selectedEvents'] ? ariesOffer?.['selectedEvents'].map((item: any) => item?.code) : [];
  const [eventsFacets, setEventsFacets] = useState<string[]>(sessionEventsFaceList.length ? sessionEventsFaceList : []);
  const [dynamicEvents, setDynamicEvents] = useState<string[]>([]);
  //activities filter
  const [availablActivities, setAvailableActivities] = useState<ControlsProps[]>([]);
  const [selectedActivities, setSelectedActivities] = useState<string[]>(ariesOffer?.['selectedActivities'] ?? []);
  const sessionActivitiesFacetList =
    ariesOffer && ariesOffer?.['selectedActivities']
      ? ariesOffer?.['selectedActivities'].map((item: any) => item?.code)
      : [];
  const [activitiesFacets, setActivitiesFacets] = useState<string[]>(
    keepDefaultFilters && destinationMetaData?.activities?.length
      ? destinationMetaData?.activities
      : sessionActivitiesFacetList.length
      ? sessionActivitiesFacetList
      : []
  );
  //For Activities
  const [dynamicActivities, setDynamicActivities] = useState<string[]>(
    setDynamicFilterState(keepDefaultFilters, destinationMetaData?.activities, sessionActivitiesFacetList)
  );
  //price filter
  const [availablPrices, setAvailablPrices] = useState<ControlsProps[]>([]);
  const [selectedPrices, setSelectedPrices] = useState<ControlsProps[]>(
    ariesOffer?.['selectedPrices']?.length
      ? ariesOffer?.['selectedPrices']
      : [
          {
            label: 'Any Price',
            disabled: false,
          },
        ]
  );
  const priceRange =
    ariesOffer && ariesOffer?.['selectedPrices'] ? ariesOffer?.['selectedPrices']?.[0]?.label : 'Any Price';
  const key = priceRange as keyof typeof priceDimensionMapping;
  const priceDimensionVal = priceDimensionMapping[key];

  const [priceDimension, setPriceDimension] = useState<any[]>(priceDimensionVal);
  const [dynamicPrice, setDynamicPrice] = useState<string[]>([]);
  // city
  const [availableCity, setAvailableCity] = useState<ControlsProps[]>([]);
  const [selectedCity, setSelectedCity] = useState<string[]>(ariesOffer?.['selectedCity'] ?? []);
  const sessionCitiesFacetList =
    ariesOffer && ariesOffer?.['selectedCity'] ? ariesOffer?.['selectedCity'].map((item: any) => item?.code) : [];
  const [cityFacets, setCityFacets] = useState<string[]>(sessionCitiesFacetList.length ? sessionCitiesFacetList : []);
  const [dynamicCity, setDynamicCity] = useState<string[]>([]);
  //state
  const [availablState, setAvailableState] = useState<ControlsProps[]>([]);
  const [selectedState, setSelectedState] = useState<string[]>(ariesOffer?.['selectedState'] ?? []);
  const sessionStatesFacetList =
    ariesOffer && ariesOffer?.['selectedState'] ? ariesOffer?.['selectedState'].map((item: any) => item?.code) : [];
  const [stateFacets, setStateFacets] = useState<string[]>(sessionStatesFacetList.length ? sessionStatesFacetList : []);
  const [dynamicState, setDynamicState] = useState<string[]>([]);
  //availability
  const [includeUnavailableProperties, setIncludeUnavailableProperties] = useState<boolean>(
    ariesOffer?.['includeUnavailableProperties'] ?? true
  );
  const [dynamicIncludeUnavailableProperties, setDynamicIncludeUnavailableProperties] = useState<boolean>(true);

  const paginationPanelLabel = {
    previous: model?.previous,
    next: model?.next,
  };
  //get onloadInput value
  useEffect(() => {
    setOnloadInput(getUrlParam());
  }, []);

  const trackingProperties = model?.trackingProperties;

  useEffect(() => {
    const inputVariable = getSearchResultQuery(
      searchData,
      destinationMetaData ?? {},
      offsetValue,
      updateCards ? brandFacets : dynamicBrand,
      cardsCount, //limit
      getSortByVal(sortBySessionVal, { isStateEnabled: isStateEnabled, isCountryEnabled: isCountryEnabled }) ??
        sortBy ??
        '',
      updateCards ? amentiesFacets : dynamicAmenities,
      updateCards ? transportaionFacets : dynamicTransportation,
      updateCards ? hotelTypeFacets : dynamicHotelType,
      updateCards ? eventsFacets : dynamicEvents,
      updateCards ? activitiesFacets : dynamicActivities,
      updateCards ? priceDimension : dynamicPrice,
      updateCards ? cityFacets : dynamicCity,
      updateCards ? stateFacets : dynamicState,
      updateCards ? includeUnavailableProperties : dynamicIncludeUnavailableProperties
    );
    const payLoad = {
      variables: {
        ...inputVariable,
      },
    };

    if (isAuthorMode) {
      (async () => {
        const getPropertiesListUXLMockJson = await propertiesListUXLMockJson(isAuthorMode, isStateOrCountryEnabled);
        const modifiedPropertyCardData = modifyPropertyCardData(getPropertiesListUXLMockJson);
        updateCards &&
          setPropertyCardData({
            hotels: modifiedPropertyCardData?.hotels,
            totalProperties: modifiedPropertyCardData.totalProperties,
          });

        setDataLoaderFlag(false);
        setPageCardCountInfo(getPageCardCountInfo(modifiedPropertyCardData?.totalProperties));
        modifiedPropertyCardData?.totalProperties === 0
          ? setErrorMessage(model?.offerParticipatingPropertiesError)
          : setErrorMessage('');
      })();
    } else {
      getPropertiesListdata(payLoad);
    }
    setOffsetValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchData,
    currentPage,
    brandFacets,
    sortBy,
    amentiesFacets,
    transportaionFacets,
    hotelTypeFacets,
    eventsFacets,
    activitiesFacets,
    priceDimension,
    cityFacets,
    stateFacets,
    includeUnavailableProperties,
    dynamicBrand,
    dynamicAmenities,
    dynamicTransportation,
    dynamicHotelType,
    dynamicEvents,
    dynamicActivities,
    dynamicPrice,
    dynamicCity,
    dynamicState,
    dynamicIncludeUnavailableProperties,
    sortBySessionVal,
  ]);
  useEffect(() => {
    const ariesOffer = globalData?.sessionData?.cacheData?.data?.AriesOffer ?? {};
    const sortBySessionVal = ariesOffer?.['sortBy'];
    setSortBy(getSortByVal(sortBySessionVal, { isStateEnabled: isStateEnabled, isCountryEnabled: isCountryEnabled }));
  }, [sortBy]);

  //on click of apply filter button
  const handleApplyFilter = (
    selectedBrandsList: any,
    selectedAmenitiesList: any,
    selectedTransportationList: any,
    selectedHotelTypeList: any,
    selectedEventsList: any,
    selectedActivitiesList: any,
    selectedPricesList: any,
    selectedCitiesList: any,
    selectedStatesList: any,
    includeUnavailableProperties: boolean
  ) => {
    setDataLoaderFlag(true);
    setUpdateCards(true);
    //add applied filters to session
    addFilterStateToSession(
      selectedBrandsList,
      selectedAmenitiesList,
      selectedTransportationList,
      selectedHotelTypeList,
      selectedEventsList,
      selectedActivitiesList,
      selectedPricesList,
      selectedCitiesList,
      selectedStatesList,
      includeUnavailableProperties
    );
    //brands
    setSelectedBrands(selectedBrandsList);
    const brandFacetList = selectedBrandsList.map((item: any) => item.code);
    setBrandFacets(brandFacetList);
    //amentities
    setSelectedAmenities(selectedAmenitiesList);
    const amenitiesFacetList = selectedAmenitiesList?.map((item: any) => item?.code);
    setAmenitiesFacets(amenitiesFacetList);
    //transportation
    setSelectedTransportaion(selectedTransportationList);
    const transportaionFacetList = selectedTransportationList?.map((item: any) => item?.code);
    setTransportaionFacets(transportaionFacetList);
    //hotel type
    setSelectedHotelType(selectedHotelTypeList);
    const hotelTypeFacetList = selectedHotelTypeList?.map((item: any) => item?.code);
    setHotelTypeFacets(hotelTypeFacetList);
    //events
    setSelectedEvents(selectedEventsList);
    const eventsFaceList = selectedEventsList?.map((item: any) => item?.code);
    setEventsFacets(eventsFaceList);
    //activities
    setSelectedActivities(selectedActivitiesList);
    const activitiesFacetList = selectedActivitiesList?.map((item: any) => item?.code);
    setActivitiesFacets(activitiesFacetList);
    //prices
    setSelectedPrices(selectedPricesList);
    const priceRange = selectedPricesList?.[0]?.label;
    const key = priceRange as keyof typeof priceDimensionMapping;
    const priceDimensionVal = priceDimensionMapping[key];
    setPriceDimension(priceDimensionVal);
    //city
    setSelectedCity(selectedCitiesList);
    const citiesFacetList = selectedCitiesList?.map((item: any) => item?.code);
    setCityFacets(citiesFacetList);
    //state
    setSelectedState(selectedStatesList);
    const statesFacetList = selectedStatesList?.map((item: any) => item?.code);
    setStateFacets(statesFacetList);
    //availablity
    setIncludeUnavailableProperties(includeUnavailableProperties);
    setCurrentPage(1);
  };
  const handleClearAllFilters = () => {
    setUpdateCards(true);
    keepDefaultFilters = false;
    setSelectedBrands([]);
    setSelectedAmenities([]);
    setSelectedTransportaion([]);
    setSelectedHotelType([]);
    setSelectedEvents([]);
    setSelectedPrices([
      {
        label: 'Any Price',
        disabled: false,
      },
    ]);
    setSelectedActivities([]);
    setSelectedCity([]);
    setSelectedState([]);
    setCurrentPage(1);
  };
  const handleDynamicBrands = (brands: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedBrands(brands);
    const brandFacetList = brands?.map((item: any) => item?.code);
    setDynamicBrand(brandFacetList);
  };
  const handleDynamicAmenities = (amenities: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedAmenities(amenities);
    const amenitiesFacetList = amenities?.map((item: any) => item?.code);
    setDynamicAmenities(amenitiesFacetList);
  };
  const handleDynamicTransportaion = (transportaions: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedTransportaion(transportaions);
    const transportaionFacetList = transportaions?.map((item: any) => item?.code);
    setDynamicTransportation(transportaionFacetList);
  };
  const handleDynamicHotelTypes = (hotelTypes: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedHotelType(hotelTypes);
    const hotelTypeFacetList = hotelTypes?.map((item: any) => item?.code);
    setDynamicHotelType(hotelTypeFacetList);
  };
  const handleDynamicActivities = (activities: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedActivities(activities);
    const activitiesFacetList = activities?.map((item: any) => item?.code);
    setDynamicActivities(activitiesFacetList);
  };
  const handleDynamicEvents = (brands: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedEvents(brands);
    const brandFacetList = brands?.map((item: any) => item?.code);
    setDynamicEvents(brandFacetList);
  };
  const handleDynamicPrices = (prices: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedPrices(prices);
    const priceRange = prices?.[0]?.label;
    const key = priceRange as keyof typeof priceDimensionMapping;
    const priceDimensionVal = priceDimensionMapping[key];
    setDynamicPrice(priceDimensionVal);
  };
  const handleDynamicCities = (cities: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedCity(cities);
    const cityFacetList = cities?.map((item: any) => item?.code);
    setDynamicCity(cityFacetList);
  };
  const handleDynamicStates = (states: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setSelectedState(states);
    const stateFacetList = states?.map((item: any) => item?.code);
    setDynamicState(stateFacetList);
  };
  const handleDynamicIncludeUnavailableProperties = (flag: any) => {
    setIsNewFacetsLoading(true);
    setUpdateCards(false);
    setIncludeUnavailableProperties(flag);
    setDynamicIncludeUnavailableProperties(flag);
  };
  //update session with selected filters
  const updateSessionObj = async (ariesOffer: any = {}) => {
    const sessionToken = sessionData?.sessionToken;
    try {
      const url = '/mi/phoenix-gateway/v1/updateSession';
      const headers = {
        Cookie: `sessionID=${sessionToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      };
      const payload = {
        AriesOffer: ariesOffer,
        remove: [],
      };
      await axios
        .post(url, payload, {
          headers: headers,
        })
        .then((res: any) => {
          return res?.status;
        });
    } catch (error) {
      console.log(`[DESTINATION] filters update session API Failed:, ${error}`); // eslint-disable-line no-console
    }
  };
  const addFilterStateToSession = (
    selectedBrandsList: any,
    selectedAmenitiesList: any,
    selectedTransportaionList: any,
    selectedHotelTypeList: any,
    selectedEventsList: any,
    selectedActivitiesList: any,
    selectedPricesList: any,
    selectedCityList: any,
    selectedStateList: any,
    includeUnavailableProperties: boolean
  ) => {
    let ariesOffer: any = sessionData?.cacheData?.data?.AriesOffer;
    ariesOffer = {
      ...ariesOffer,
      selectedBrands: selectedBrandsList,
      selectedAmenities: selectedAmenitiesList,
      selectedTransportaion: selectedTransportaionList,
      selectedHotelType: selectedHotelTypeList,
      selectedEvents: selectedEventsList,
      selectedActivities: selectedActivitiesList,
      selectedPrices: selectedPricesList,
      selectedCity: selectedCityList,
      selectedState: selectedStateList,
      includeUnavailableProperties: includeUnavailableProperties,
      sortBy: sortBy,
    };
    let updatedSessionData: any = sessionData;
    updatedSessionData = {
      ...updatedSessionData,
      cacheData: {
        ...updatedSessionData?.cacheData,
        data: {
          ...updatedSessionData?.cacheData?.data,
          AriesOffer: ariesOffer,
        },
      },
    };
    updateGlobalData('sessionData', updatedSessionData);
    updateSessionObj(ariesOffer);
  };

  // get properties list on search form update
  // Search Data Receive from Offers Search Form
  useEffect(() => {
    eventUtil.on('MPOSearchFormData', data => {
      setDataLoaderFlag(true);
      const searchFormData = data.searchFormData;
      setSearchData(searchFormData);
      setCurrentPage(1);
    });

    eventUtil.on('childAgeErrFlag', flag => {
      setErrorMessage(flag ? model?.childAgeEmptyError : '');
    });

    eventUtil.on('stayDateMissingErrFlag', flag => {
      setErrorMessage(flag ? model?.stayDateEmptyError : '');
    });

    eventUtil.remove('MPOSearchFormData', () => {
      ('');
    });
    eventUtil.remove('childAgeErrFlag', () => {
      ('');
    });
    eventUtil.remove('stayDateMissingErrFlag', () => {
      ('');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    eventUtil.on('DeepLinkUtil', data => {
      if (onloadInput) {
        setSearchData(data.searchFormData);
      }
    });
    eventUtil.remove('DeepLinkUtil', () => {
      ('');
    });
  }, [onloadInput]);

  const getPageCardCountInfo = (cardsTotalCount: number): string => {
    const total = Math.ceil(cardsTotalCount / cardsCount);
    const start = total === 1 || currentPage === 1 ? 1 : (currentPage - 1) * cardsCount + 1;
    const end = total === currentPage || currentPage === cardsTotalCount ? cardsTotalCount : currentPage * cardsCount;
    const countText = `${model?.showing} ${start}-${end} ${model?.of} ${cardsTotalCount} ${model?.hotel}`;
    return countText;
  };

  const [setDefaultSelectedFilters, updateSetDefaultSelectedFilters] = useState<boolean>(true);

  const processPropertiesList = (propertiesListdata: any) => {
    const modifiedPropertyCardData = modifyPropertyCardData(propertiesListdata);
    updateCards &&
      setPropertyCardData({
        hotels: modifiedPropertyCardData?.hotels,
        totalProperties: modifiedPropertyCardData.totalProperties,
      });
    // get available brands from UXL
    const facetObj = Enable_DTT_queries
      ? isStateOrCountryEnabled
        ? propertiesListdata?.search?.lowestAvailableRates?.searchByLocation?.facets ?? []
        : propertiesListdata?.search?.lowestAvailableRates?.searchByGeolocation?.facets ?? []
      : isStateOrCountryEnabled
      ? propertiesListdata?.searchLowestAvailableRatesByLocation?.facets ?? []
      : propertiesListdata?.searchLowestAvailableRatesByGeolocation?.facets ?? [];
    const propertiesFacetsBucket = facetObj;

    //brands
    const uxlAvailableBrandData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'BRANDS';
    });
    setAvailableBrands(uxlAvailableBrandData[0]?.buckets);
    //amenities
    const uxlAvailableAmenitiesData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'AMENITIES';
    });
    setAvailableAmenities(uxlAvailableAmenitiesData[0]?.buckets);
    const defaultSelectedAmenitities =
      destinationMetaData?.amenities?.length &&
      uxlAvailableAmenitiesData?.[0]?.buckets?.filter((item: any) => {
        return destinationMetaData?.amenities?.includes(item?.code);
      });
    keepDefaultFilters &&
      setDefaultSelectedFilters &&
      destinationMetaData?.amenities?.length &&
      setSelectedAmenities(defaultSelectedAmenitities);
    //transportation
    const uxlAvailableTransportationData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'TRANSPORTATION_TYPES';
    });
    setAvailableTransportaion(uxlAvailableTransportationData[0]?.buckets);
    //hotel type
    const uxlAvailableHotelTypeData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'PROPERTY_TYPES';
    });
    setAvailableHotelType(uxlAvailableHotelTypeData[0]?.buckets);
    const defaultSelectedHotelType =
      destinationMetaData?.hotelType?.length &&
      uxlAvailableHotelTypeData?.[0]?.buckets?.filter((item: any) => {
        return destinationMetaData?.hotelType?.includes(item?.code);
      });
    keepDefaultFilters &&
      setDefaultSelectedFilters &&
      destinationMetaData?.hotelType?.length &&
      setSelectedHotelType(defaultSelectedHotelType);
    //events
    const uxlAvailableEventsData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'MEETINGS_EVENTS';
    });
    setAvailableEvents(uxlAvailableEventsData[0]?.buckets);
    //activities
    const uxlAvailableActivitiesData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'ACTIVITIES';
    });
    setAvailableActivities(uxlAvailableActivitiesData[0]?.buckets);
    const defaultSelectedActivities =
      destinationMetaData?.activities?.length &&
      uxlAvailableActivitiesData?.[0]?.buckets?.filter((item: any) => {
        return destinationMetaData?.activities?.includes(item?.code);
      });
    keepDefaultFilters &&
      setDefaultSelectedFilters &&
      destinationMetaData?.activities?.length &&
      setSelectedActivities(defaultSelectedActivities);

    //add default selected filters to session
    keepDefaultFilters &&
      setDefaultSelectedFilters &&
      addFilterStateToSession(
        selectedBrands,
        destinationMetaData?.amenities?.length ? defaultSelectedAmenitities : selectedAmenities,
        selectedTransportaion,
        destinationMetaData?.hotelType?.length ? defaultSelectedHotelType : selectedHotelType,
        selectedEvents,
        destinationMetaData?.activities?.length ? defaultSelectedActivities : selectedActivities,
        selectedPrices,
        selectedCity,
        selectedState,
        includeUnavailableProperties
      );
    //price
    const uxlAvailablePricesData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'PRICE';
    });
    setAvailablPrices(uxlAvailablePricesData[0]?.buckets);
    //city
    const uxlAvailableCityData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'CITIES';
    });
    setAvailableCity(uxlAvailableCityData[0]?.buckets);
    //state
    const uxlAvailableStateData = propertiesFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'STATES';
    });
    // isNewFacetsLoading = false;
    setIsNewFacetsLoading(false);
    setAvailableState(uxlAvailableStateData[0]?.buckets);
    if (updateCards) {
      setDataLoaderFlag(false);
      setPageCardCountInfo(getPageCardCountInfo(modifiedPropertyCardData?.totalProperties));
      modifiedPropertyCardData?.totalProperties === 0
        ? setErrorMessage(model?.offerParticipatingPropertiesError)
        : setErrorMessage('');
    }
    updateSetDefaultSelectedFilters(false);
    getSortByOptions(isStateOrCountryEnabled, modifiedPropertyCardData?.totalProperties);
  };
  const handlePropertiesError = () => {
    // isNewFacetsLoading = false;
    setIsNewFacetsLoading(false);
    if (updateCards) {
      setPageCardCountInfo(getPageCardCountInfo(0));
      setPropertyCardData({
        hotels: [],
        totalProperties: 0,
      });
      setDataLoaderFlag(false);
      setErrorMessage(model?.offerParticipatingPropertiesError);
    }
  };

  //get properties list
  const [getPropertiesListdata, { data: _propertiesListdata }] = useLazyQuery(propertiesQuery, {
    fetchPolicy: 'network-only',
    errorPolicy: UXL_ERROR_POLICY,
    context: {
      headers: {
        'x-request-id': requestId,
        'accept-language': acceptLanguage,
      },
    },
    onCompleted: processPropertiesList,
    onError: handlePropertiesError,
  });

  //Code generate script for SEO
  const addJSONtoScript = (jsonLdData: any) => {
    if (document.getElementById('destinationproperty') === null) {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.id = 'destinationproperty';
      script.insertAdjacentText('beforeend', JSON.stringify(jsonLdData));
      // Append the script to the head
      document.head.appendChild(script);
    }
  };
  //modify card data helper functions
  const modifyPropertyCardData = (resp: any) => {
    const respObj = Enable_DTT_queries
      ? isStateOrCountryEnabled
        ? resp?.search?.lowestAvailableRates?.searchByLocation ?? {}
        : resp?.search?.lowestAvailableRates?.searchByGeolocation ?? {}
      : isStateOrCountryEnabled
      ? resp?.searchLowestAvailableRatesByLocation ?? {}
      : resp?.searchLowestAvailableRatesByGeolocation ?? {};

    const edgeObj = respObj?.edges ?? [];
    const totalProperties = respObj?.total ?? 0;
    const propList = edgeObj;
    //Code to generate JSON schema for SEO
    const jsonLdData = constructJsonSchema(propList, cardsCount);
    addJSONtoScript(jsonLdData);
    const hotels = propList.length
      ? propList.map((hotel: any) => {
          const {
            fullyRenovatedLabel,
            recentlyRenovatedLabel,
            newHotelLabel,
            adultsOnlyLabel,
            renovatedLobbyLabel,
            renovatedRoomsLabel,
          } = model || {};
          const { basicInformation } = hotel?.node?.property || {};
          const isFullyRenovated = basicInformation?.isFullyRenovated;
          const isRecentlyRenovated = basicInformation?.isRecentlyRenovated;
          const hasRenovatedRooms = basicInformation?.hasRenovatedRooms;
          const newProperty = basicInformation?.newProperty;
          const isAdultsOnly = basicInformation?.isAdultsOnly;
          const newLobby = basicInformation?.newLobby;
          let ribbonDetails = null;

          if (isFullyRenovated) {
            ribbonDetails = {
              ribbonText: fullyRenovatedLabel,
              ribbonClass: 'fully-renovated-ribbon',
            };
          } else if (isRecentlyRenovated) {
            ribbonDetails = {
              ribbonText: recentlyRenovatedLabel,
              ribbonClass: 'recently-renovated-ribbon',
            };
          } else if (newProperty) {
            ribbonDetails = {
              ribbonText: newHotelLabel,
              ribbonClass: 'new-hotel-ribbon ribbon',
            };
          } else if (isAdultsOnly) {
            ribbonDetails = {
              ribbonText: adultsOnlyLabel,
              ribbonClass: 'adult-hotel-ribbon',
            };
          } else if (newLobby) {
            ribbonDetails = {
              ribbonText: renovatedLobbyLabel,
              ribbonClass: 'renovated-lobby-ribbon',
            };
          } else if (hasRenovatedRooms) {
            ribbonDetails = {
              ribbonText: renovatedRoomsLabel,
              ribbonClass: 'renovated-rooms-ribbon',
            };
          }
          return {
            id: hotel?.node?.property?.id ?? '',
            sid: hotel?.node?.property?.basicInformation?.marshaBrandCode ?? '',
            brandCode: hotel?.node?.property?.basicInformation?.brand?.id ?? '',
            titleDetails: getTitle(hotel?.node?.property, hotel?.node?.property?.basicInformation?.brand?.id),
            reviewsDetails: getReviewDetails(
              hotel?.node?.property,
              hotel?.node?.property?.basicInformation?.brand?.id,
              hotel?.node?.distance
            ),
            description: getDescriptionText(hotel?.node?.property?.basicInformation?.descriptions),
            images: getPropertyImages(hotel?.node?.property),
            tertiaryLinkDetails: generateDetailsPageURL(
              hotel?.node?.property?.seoNickname ?? '',
              hotel?.node?.property?.basicInformation?.brand?.id
            ),
            footerLinkDetails: getFooterDetails(hotel, clusterCode),
            mandatoryFeeObj: {
              amountPlusMandatoryFee: getAmountPlusMandatoryFeeDetails(hotel),
              mandatoryFee: getMandatoryFeeDetails(hotel),
              mandatoryFeeDescription: getMandatoryFeeDescription(
                hotel?.node?.property?.basicInformation?.descriptions
              ),
              ...mandatoryFeeMessages,
            },
            brandDetails: {
              brandId: hotel?.node?.property?.basicInformation?.brand?.id,
              label: '',
              hasUniquePropertyLogo: hotel?.node?.property?.basicInformation?.hasUniquePropertyLogo,
            },
            ribbonDetails: ribbonDetails,
          };
        })
      : [];
    return {
      hotels: hotels,
      totalProperties: totalProperties,
    };
  };
  const getTitle = (property: any, brandId: string) => {
    const seoNickname = property?.seoNickname ?? '';
    const titleDetails = {
      title: property?.basicInformation?.name,
      titleAriaLabel: property?.basicInformation?.name,
      isTitleLink: true,
      titleClickCallback: () => {
        const detailsPage =
          brandId == 'RZ'
            ? `https://www.ritzcarlton.com/en/hotels/${seoNickname}/overview/`
            : (subdirectory ? `/${subdirectory}` : '/en-us') + `/hotels/${seoNickname}/overview/`;
        setRecentlyViewedProperties(property);
        window.open(`${detailsPage}`, '_blank');
      },
      titleLink:
        brandId == 'RZ'
          ? `https://www.ritzcarlton.com/en/hotels/${seoNickname}/overview/`
          : (subdirectory ? `/${subdirectory}` : '/en-us') + `/hotels/${seoNickname}/overview/`,
      titleClickTrackVal: `${model?.id}|${property?.id}|${EXTERNAL_CTA_TYPE}`,
    };
    return titleDetails;
  };
  const setRecentlyViewedProperties = (property: any) => {
    const name = property?.basicInformation?.name;
    const localViewed = localStorage.getItem('miRecentlyViewedProperties');
    const recentViewed: recentViewedPropertiesType = JSON.parse(
      localViewed
        ? localViewed
        : JSON.stringify({
            config: {
              maxCachedPropertiesLimit: '10',
              maxCachedPropertiesTab: '2',
              maxCachedPropertiesDesktop: '3',
              maxCachedPropertiesMobile: '2',
              maxCachedDaysLimit: '60',
            },
            recentlyViewedProperties: [],
          })
    );
    const isDuplicate =
      recentViewed?.recentlyViewedProperties?.length &&
      recentViewed.recentlyViewedProperties.find(item => item.name === name);

    if (!isDuplicate) {
      const obj = {
        name: name,
        address: name,
      };

      if (recentViewed && recentViewed?.recentlyViewedProperties.length >= 0) {
        if (recentViewed.recentlyViewedProperties.length >= RECENT_SEARCH_LIST_LIMIT) {
          recentViewed.recentlyViewedProperties.pop();
        }
        recentViewed.recentlyViewedProperties.unshift(obj);
      }

      localStorage.setItem('miRecentlyViewedProperties', JSON.stringify(recentViewed));
    }
  };
  const getReviewDetails = (property: any, brandId: string, distance: any) => {
    const seoNickname = property?.seoNickname;
    const rating = property?.reviews?.stars?.count ?? 0;
    const reviewCount = property?.reviews?.numberOfReviews?.count ?? 0;
    const reviewUrl =
      brandId == 'RZ'
        ? `https://www.ritzcarlton.com/en/hotels/${seoNickname}/overview/`
        : (subdirectory ? `/${subdirectory}` : '/en-us') + `/hotels/${seoNickname}/reviews/`;
    const distanceInMiles = getCalculatedDistance(distance, true);
    const reviewDetails = {
      ...(rating !== 0 && { reviewsAvg: rating }),
      ...(reviewCount !== 0 && { reviewsText: `(${reviewCount} ${model?.storefrontPropertyReviews})` }),
      reviewsLink: reviewUrl,
      reviewsLabel: '',
      reviewsClickTrackValue: `${model?.id}|${property?.id}-${model?.storefrontPropertyReviews}|${EXTERNAL_CTA_TYPE}`,
      milesText:
        distanceInMiles !== null && distanceInMiles !== undefined
          ? `${distanceInMiles} ${model?.unitFromDestinationLabel}`
          : '',
    };
    return reviewDetails;
  };

  const getDescriptionText = (descriptions: any) => {
    return descriptions?.find((desc: any) => desc?.type?.enumCode === descriptionToDisplayOnPropertyCard)?.text || '';
  };

  const getPropertyImages = (data: any) => {
    const photoGalleryObj = data.media.photoGallery;
    const propertyImageList: any = [];
    const propertyImageArray: any = [];
    let cauroselImages: any = [];
    const edgesList: any = [];

    if (photoGalleryObj) {
      Object.keys(photoGalleryObj).forEach((categoryKey: any) => {
        const propertyObject: any = [];
        if (categoryKey !== '__typename' && photoGalleryObj[categoryKey]) {
          propertyObject['categoryCode'] = categoryKey;
          propertyObject['photosObj'] = photoGalleryObj[categoryKey];
          propertyImageList.push(propertyObject);
        }
      });
    }
    if (data?.media?.primaryImage?.edges?.[0]?.node?.imageUrls) {
      const hotelViewObj: any = [];
      hotelViewObj['categoryCode'] = HOTEL_VIEW;
      hotelViewObj['photosObj'] = data?.media?.primaryImage;
      propertyImageList.unshift(hotelViewObj);
    }
    propertyImageList.map((edgeCollection: any) => {
      edgesList.push(edgeCollection?.photosObj?.edges);
      edgesList.map((photosList: any) => {
        photosList.map((imgNode: any) => {
          const wideImg = imgNode?.node?.imageUrls?.wideHorizontal;
          if (wideImg !== null && wideImg !== '') {
            propertyImageArray.push({
              defaultImageUrl: MARRIOTT_CACHE_URL + wideImg + imageProperties,
            });
            cauroselImages = Array.from(
              new Map(propertyImageArray.map((item: any) => [item.defaultImageUrl, item])).values()
            ).slice(0, 10);
          }
        });
      });
    });
    if (cauroselImages.length < 1) {
      const brandFallbackImageArray: any = {};
      brandFallbackImageArray['defaultImageUrl'] = data.basicInformation.brand.photos[0].content[0].url;
      cauroselImages.push(brandFallbackImageArray);
    }
    return cauroselImages;
  };
  const generateDetailsPageURL = (seoNickname: string, brandId: string) => {
    const detailsPage =
      brandId == 'RZ'
        ? `https://www.ritzcarlton.com/en/hotels/${seoNickname}/overview/`
        : (subdirectory ? `/${subdirectory}` : '/en-us') + `/hotels/${seoNickname}/overview/`;
    const footerlinks = {
      href: detailsPage,
      target: '_blank',
      custom_click_track_value: `${model?.id}|${model?.storefrontPropertyHotelDetails}|${EXTERNAL_CTA_TYPE}`,
      rel: 'noreferrer',
      className: 'm-link-tertiary-button outlet-resevation-link custom_click_track ',
      buttonCopy: `${model?.storefrontPropertyHotelDetails}`,
      isLink: true,
      onClick: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (): Window | null => window.open(`${detailsPage}`, '_self');
      },
    };
    return footerlinks;
  };

  const getRatesNode = (hotel: any) => {
    return hotel?.node?.rates?.[0];
  };

  const getAmountPlusMandatoryFeeDetails = (hotel: any) => {
    const rateObj = getRatesNode(hotel);
    const rateStatus = rateObj?.status?.code ?? 'unavailable';
    let mandatoryFee;
    const feeObj = Enable_DTT_queries
      ? rateObj?.rateModes?.lowestAverageRate?.amountPlusMandatoryFees
      : rateObj?.rateAmounts?.[0]?.amountPlusMandatoryFees?.origin;
    if (rateStatus === AVAILABLE_RATE_STATUS) {
      mandatoryFee = feeObj ?? {};
    }
    const amount = Enable_DTT_queries ? mandatoryFee?.amount : mandatoryFee?.value;
    const decimalPoint = Enable_DTT_queries ? mandatoryFee?.decimalPoint : mandatoryFee?.valueDecimalPoint;
    const totalPrice = mandatoryFee && amount ? Math.round(amount / Math.pow(10, decimalPoint as number)) : 0;
    return totalPrice;
  };
  const getMandatoryFeeDetails = (hotel: any) => {
    const rateObj = getRatesNode(hotel);
    const feeObj = Enable_DTT_queries
      ? rateObj?.rateModes?.lowestAverageRate?.mandatoryFees
      : rateObj?.rateAmounts?.[0]?.amountPlusMandatoryFees?.origin;
    const mandatoryFee = feeObj ?? {};
    return mandatoryFee;
  };
  const getMandatoryFeeDescription = (descriptions: any) => {
    const mandatoryFeeDescObj =
      descriptions.length > 0
        ? descriptions.filter((item: { type: { code: string } }) => {
            return item.type.code === SURCHARGE_ORDINANCE_CODE;
          })
        : [];
    return mandatoryFeeDescObj;
  };

  const getFooterDetails = (hotel: any, clusterCode: string) => {
    const dateFlag =
      acceptLanguage === 'it-IT' ||
      acceptLanguage === 'fr-FR' ||
      acceptLanguage === 'pt-BR' ||
      acceptLanguage === 'es-ES' ||
      acceptLanguage === 'en-GB'
        ? true
        : false;

    const ratePage =
      subdirectory +
      '/reservation/availabilitySearch.mi?' +
      `&numberOfRooms=${searchData?.quantity}&numberOfAdults=${
        Number(searchData?.numberInParty) - (searchData?.childAges?.length ?? 0)
      }&childrenCount=${searchData?.childAges?.length ?? ''}&childrenAges=${
        searchData?.childAges?.length ? searchData?.childAges.map((i: any) => (Number.isNaN(Number(i)) ? '0' : i)) : ''
      }` +
      `currency=${hotel?.node?.property?.basicInformation?.currency}` +
      '&useRewardsPoints=false&isRateCalendar=true&isSearch=true' +
      '&fromDate=' +
      getDateFormatted(moment(searchData?.startDate), dateFlag ? 'DD/MM/YYYY' : 'MM/DD/YYYY') +
      '&toDate=' +
      getDateFormatted(moment(searchData?.endDate), dateFlag ? 'DD/MM/YYYY' : 'MM/DD/YYYY') +
      `&clusterCode=${clusterCode === '0' ? 'none' : 'corp'}` +
      '&propertyCode=' +
      hotel?.node?.property?.id;

    const rateObj = getRatesNode(hotel);

    const rate = calculateRates(rateObj).price;

    const footerlinks = {
      href: ratePage,
      target: '_self',
      ratesClickTrackValue: `${model?.id}|${hotel?.node?.property?.id}-${model?.bookNow}|${EXTERNAL_CTA_TYPE}`,
      rel: 'noreferrer',
      className: 'm-button-s m-button-primary custom_click_track reserve-cta-button',
      text: `${model?.bookNow}`,
      isLink: false,
      hasPrice: rate ? true : false,
      priceAriaLabel: '',
      priceClickTrackValue: `${model?.id}|${hotel?.node?.property?.id}-${model?.storefrontPropertyPointsPerStay}|${EXTERNAL_CTA_TYPE}`,
      priceValue: rate ?? null,
      priceType: rate && `${hotel?.node?.property?.basicInformation?.currency} / ${model?.storefrontPropertyNight}`,
      unavailableRateText: model?.rateUnavailable,
    };
    return footerlinks;
  };
  const calculateRates = (LARNode: any) => {
    const rateStatus = LARNode?.status?.code ?? 'unavailable';
    let price = 0;
    if (rateStatus === AVAILABLE_RATE_STATUS) {
      let cashValue = 0;
      let decimalValue = 0;
      if (!Enable_DTT_queries) {
        cashValue =
          LARNode?.rateAmounts?.[0]?.amountPlusMandatoryFees?.origin?.value ??
          LARNode?.rateAmounts?.[0]?.origin?.origin?.value;

        decimalValue = LARNode?.rateAmounts?.[0]?.amountPlusMandatoryFees?.origin?.value
          ? LARNode?.rateAmounts?.[0]?.amountPlusMandatoryFees?.origin?.valueDecimalPoint
          : LARNode?.rateAmounts?.[0]?.origin?.origin?.value
          ? LARNode?.rateAmounts?.[0]?.origin?.origin?.valueDecimalPoint
          : 0;
      } else {
        cashValue =
          LARNode?.rateModes?.lowestAverageRate?.amountPlusMandatoryFees.amount ??
          LARNode?.rateModes?.lowestAverageRate?.amount.amount;

        decimalValue = LARNode?.rateModes?.lowestAverageRate?.amountPlusMandatoryFees?.amount
          ? LARNode?.rateModes?.lowestAverageRate?.amountPlusMandatoryFees?.decimalPoint
          : LARNode?.rateModes?.lowestAverageRate?.amount?.amount
          ? LARNode?.rateModes?.lowestAverageRate?.amount?.decimalPoint
          : 0;
      }
      price = Math.round(cashValue / Math.pow(10, decimalValue as number));
    }
    return {
      price: price,
    };
  };
  //sort options function
  const getSortByOptions = (isStateOrCountryEnabled: any, totalprop: any) => {
    // Common options
    const options = [
      { sortByOptionTitle: `${model?.priceLowToHighLabel}`, sortByOptionCode: 'PRICE' },
      { sortByOptionTitle: `${model?.cityLabel}`, sortByOptionCode: 'CITY' },
      { sortByOptionTitle: `${model?.storefrontPropertyBrandFilter}`, sortByOptionCode: 'BRAND' },
      { sortByOptionTitle: `${model?.guestRatingLabel}`, sortByOptionCode: 'GUEST_RATINGS' },
      { sortByOptionTitle: `${model?.noOfReviewsLabel}`, sortByOptionCode: 'NUM_REVIEWS' },
    ];

    // If isStateOrCountryEnabled, filter options based on totalProperties
    if (isStateOrCountryEnabled) {
      if (totalprop != null && totalprop <= 200) {
        setSortOptions(options); // Include all options if totalProperties is less than 200
      } else {
        const filteredOptions = options.filter(option => option.sortByOptionCode !== 'PRICE');
        setSortOptions(filteredOptions); // Exclude 'Price'
      }
    } else {
      const newOption = { sortByOptionTitle: `${model?.distanceLabel}`, sortByOptionCode: 'DISTANCE' };
      const updatedOptions: { sortByOptionTitle: string; sortByOptionCode: string }[] = [newOption, ...options];
      setSortOptions(updatedOptions);
    }
  };

  const handleSortBy = (event: any, sortOption: string): void => {
    setDataLoaderFlag(true);
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      //update session on sort
      let ariesOffer: any = sessionData?.cacheData?.data?.AriesOffer;
      ariesOffer = {
        ...ariesOffer,
        sortBy: sortOption,
      };
      let updatedSessionData: any = sessionData;
      updatedSessionData = {
        ...updatedSessionData,
        cacheData: {
          ...updatedSessionData?.cacheData,
          data: {
            ...updatedSessionData?.cacheData?.data,
            AriesOffer: ariesOffer,
          },
        },
      };
      updateGlobalData('sessionData', updatedSessionData);
      updateSessionObj(ariesOffer);
      setSortBy(sortOption);
      setCurrentPage(1);
    }
  };

  return (
    <StyledPropertiesListWrapper className={`${model?.styleclass}`}>
      <div className={`container offer-properties-list`}>
        <div>
          <PropertiesListFilter
            model={model}
            destinationMetaData={destinationMetaData}
            availableBrands={availableBrands}
            availabledAmenities={availabledAmenities}
            availableTransportaion={availableTransportaion}
            availableHotelType={availableHotelType}
            availablEvents={availablEvents}
            availablActivities={availablActivities}
            availablPrices={availablPrices}
            availableCity={availableCity}
            availablState={availablState}
            handleApplyFilter={handleApplyFilter}
            selectedBrands={selectedBrands}
            selectedAmenities={selectedAmenities}
            selectedTransportaion={selectedTransportaion}
            selectedHotelType={selectedHotelType}
            selectedEvents={selectedEvents}
            selectedActivities={selectedActivities}
            selectedPrices={selectedPrices}
            selectedCity={selectedCity}
            selectedState={selectedState}
            brandFacets={brandFacets}
            amentiesFacets={amentiesFacets}
            transportaionFacets={transportaionFacets}
            hotelTypeFacets={hotelTypeFacets}
            eventsFacets={eventsFacets}
            activitiesFacets={activitiesFacets}
            cityFacets={cityFacets}
            stateFacets={stateFacets}
            priceDimension={priceDimension}
            includeUnavailableProperties={includeUnavailableProperties}
            handleClearAllFilters={handleClearAllFilters}
            handleDynamicBrands={handleDynamicBrands}
            handleDynamicAmenities={handleDynamicAmenities}
            handleDynamicTransportaion={handleDynamicTransportaion}
            handleDynamicHotelTypes={handleDynamicHotelTypes}
            handleDynamicActivities={handleDynamicActivities}
            handleDynamicEvents={handleDynamicEvents}
            handleDynamicPrices={handleDynamicPrices}
            handleDynamicCities={handleDynamicCities}
            handleDynamicStates={handleDynamicStates}
            handleDynamicIncludeUnavailableProperties={handleDynamicIncludeUnavailableProperties}
            isNewFacetsLoading={isNewFacetsLoading}
          />
        </div>
        {dataLoaderFlag ? (
          <div className="pt-5 pb-5">
            <SkeletonOutletCardLoader />
          </div>
        ) : errorMessage ? (
          <AlertNotification errorMessage={errorMessage} className={'no-hotels-found alert'} />
        ) : (
          <>
            <div className="sort-by-wrapper mb-1">
              <div className="page-card-count">
                <p className={clsx(isMobileViewPort ? 't-font-s' : 't-subtitle-l', 'pb-1', 'mb-0')}>
                  {pageCardCountInfo}
                </p>
              </div>
              <SortByFilterComponent
                handleSortBy={handleSortBy}
                sortOptions={sortOptions}
                currSortByOption={sortBy}
                sortByLabel={model?.sortByLabel}
                destinationMetaData={destinationMetaData}
              />
            </div>
            {propertyCardData?.hotels?.length &&
              propertyCardData.hotels.map((hotel: any) => {
                return (
                  <PropertyCard
                    {...hotel}
                    isMBOP={true}
                    trackingProperties={trackingProperties}
                    addScrimToImage={true}
                    enablePropertyCarouselControl={true} //this flag used to match SERP property carousel control implementation of bullet points
                  />
                );
              })}
            <div className="pagination-container mt-5">
              <Pagination
                currentPage={currentPage}
                totalCount={propertyCardData.totalProperties ?? 0}
                pageSize={cardsCount}
                onPageChange={(page: number): void => onPageItemClick(page)}
                labels={paginationPanelLabel}
                seoFriendlyPagination={model.seoFriendlyPagination}
                queryParamName={PARAM_PAGENO}
              />
            </div>
          </>
        )}
      </div>
    </StyledPropertiesListWrapper>
  );
};
export default PropertiesListWrapper;
