/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CardCarouselContainer, CardLayeredVariations } from '@marriott/mi-ui-library';
import { useLazyQuery } from '@apollo/client';

import { CardCarouselResortsProps } from './CardCarouselResorts.types';
import { StyledContainer } from './CardResortsWrapper.styles';
import {
  IMAGE_ALT_TEXT,
  IMPRESSION_TRACK,
  MERCHANDISING_CATEGORY,
  NO_OF_CARDS_TABLET,
  RESORT_CAROUSEL_ERROR_POLICY,
  IMAGE_CACHE_DOMAIN,
  CARD_SQUARE,
  OFFER_CAUROSEL_DEFAULT_IMAGE,
} from '../../constants/ResortCardConstants';
import { CarouselUXLMockJson, CarouselUXLMockJsonDTT } from './__mock__/ResortCarouselUXL.mock';
import {
  phoenixResortsCarousel,
  phoenixLeisureSearchByKeyword,
  PhoenixSearchPropertiesByArea,
  phoenixLeisureSearchByLocation,
  phoenixStorefrontDestinationProperties,
  phoenixLeisureSearchByGeolocation,
} from '@marriott/mi-leisure-graphql';
import { SkeletonCardCarouselLoader } from './SkeletonCardCarouselLoader/index';
import { generateRandomNumber, getWCMModeFlagValue } from '@marriott/shared/mi-helper-utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { getCurrentDate, getTomorrowDate } from '@marriott/mi-offers-components';
import { getCtaUrl } from '@marriott/mi-leisure-components/utils';
// import { getCurrentDate, getTomorrowDate } from 'libs/mi-offers-components/src/utils/OfferUtils';
// import { getCurrentDate, getTomorrowDate } from '../../../../'//'../../../../mi-offers-components/src/utils/OfferUtils';

export const CardResortsWrapper: React.FC<CardCarouselResortsProps> = props => {
  const { model, acceptLanguage, isAuthorMode, requestId, subDirectoryContextPath } = props;
  const [resortResult, setResultResort] = useState<any>([]);
  const [cardCount, setCardCount] = useState(0);
  const [offset, setOffset] = useState<number>(0);
  const [queryComplete, setQueryCompleted] = useState<number>(0);
  const [keys, setKeys] = useState<any>([]);
  const Enable_DTT_queries = false;
  const [totalResortCount, _setTotalResortCount] = useState({
    totalCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const acceptLang = acceptLanguage?.split(',')[0];
  const openInaNewTab = model?.openInaNewTab ? JSON.parse(model.openInaNewTab) : false;
  const isServer = !(typeof window != 'undefined' && window.document);
  const startDate = getCurrentDate();
  const endDate = getTomorrowDate();
  const {
    latitude = '',
    longitude = '',
    stateCode = '',
    countryCode = '',
    isStateEnabled = false,
    isCountryEnabled = false,
    isPOI = false,
  } = model?.destinationMetaData ?? {};

  const { envVarsObject } = useClientEnvVarsStore();
  const cardLayeredVariation =
    model?.cardsType === CARD_SQUARE ? CardLayeredVariations.Square : CardLayeredVariations.Tall;
  const isWcmModeDisabledFlag = getWCMModeFlagValue();
  let resortKeys = '';
  const [baseRequestVariables, setBaseVariables] = useState<any>({});

  useEffect(() => {
    const {
      latitude = '',
      longitude = '',
      stateCode = '',
      countryCode = '',
      isStateEnabled = false,
      isCountryEnabled = false,
      isPOI = false,
    } = model?.destinationMetaData ?? {};
    const filters: any = [];
    if (model?.hotelType) {
      const hotelTypeArray = Array.isArray(model?.hotelType) ? model?.hotelType : [model?.hotelType];
      filters.push({
        type: 'PROPERTY_TYPES',
        dimensions: hotelTypeArray,
      });
      resortKeys = resortKeys.concat(hotelTypeArray.join(',')).concat(',');
    }
    if (model?.activities && model?.activities?.length > 0) {
      filters.push({
        type: 'ACTIVITIES',
        dimensions: model?.activities,
      });
      resortKeys = resortKeys.concat(model?.activities).concat(',');
    }
    if (model?.amenities && model?.amenities?.length > 0) {
      filters.push({
        type: 'AMENITIES',
        dimensions: model?.amenities,
      });
      resortKeys = resortKeys.concat(model?.amenities).concat(',');
    }
    if (model?.regions && model?.regions?.length > 0) {
      filters.push({
        type: 'LEISURE_REGIONS',
        dimensions: model?.regions,
      });
      resortKeys = resortKeys.concat(model?.regions);
    }
    if (model?.brands && model?.brands?.length > 0) {
      filters.push({
        type: 'BRANDS',
        dimensions: model?.brands,
      });
      resortKeys = resortKeys.concat(model?.brands);
    }
    if (isCountryEnabled) {
      filters.push({
        type: 'COUNTRIES',
        dimensions: [countryCode],
      });
    }
    if (isStateEnabled) {
      filters.push({
        type: 'STATES',
        dimensions: [stateCode],
      });
    }
    let updatedOffset;
    let offsetNumber = 0;
    if (typeof window !== 'undefined') {
      updatedOffset =
        localStorage?.getItem('filteredResortCount') != null ? localStorage.getItem('filteredResortCount') : '0';
      offsetNumber = updatedOffset != null ? parseInt(updatedOffset) : 0;
    }
    // const [baseRequestVariables, setBaseVariables] = useState<any>({});
    let partialRequestVariables: any = {};
    if (Enable_DTT_queries) {
      if (isPOI || (latitude && longitude && !isStateEnabled && !isCountryEnabled)) {
        partialRequestVariables = {
          limit: parseInt(model?.maxResortsCount),
          offset: 0,
          // version: 'V1',
          search: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            // options: {
            //   startDate: startDate,
            //   endDate: endDate,
            // },
            facets: {
              terms: filters,
            },
          },
          sort: {
            fields: [
              {
                field: 'DISTANCE',
                direction: 'ASC',
              },
            ],
          },
        };
      } else if (isCountryEnabled || isStateEnabled) {
        partialRequestVariables = {
          limit: parseInt(model?.maxResortsCount),
          offset: 0,
          // version: 'V1',
          search: {
            // keyword: '*:*',
            options: {
              numberInParty: 1,
              numberOfGuestRooms: 1,
            },
            facets: {
              terms: filters,
            },
          },
        };
      } else {
        partialRequestVariables = {
          limit: parseInt(model?.maxResortsCount),
          offset: offsetNumber, //offset,
          // version: 'V1',
          search: {
            keyword: '*:*',
            facets: {
              terms: filters,
            },
          },
        };
      }
    } else {
      if (isPOI || (latitude && longitude && !isStateEnabled && !isCountryEnabled)) {
        partialRequestVariables = {
          limit: parseInt(model?.maxResortsCount),
          offset: 0,
          version: 'V1',
          search: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            options: {
              startDate: startDate,
              endDate: endDate,
              includeUnavailableProperties: true,
            },
            facets: {
              terms: filters,
            },
          },
          sort: {
            fields: [
              {
                field: 'DISTANCE',
                direction: 'ASC',
              },
            ],
          },
        };
      } else if (isCountryEnabled || isStateEnabled) {
        partialRequestVariables = {
          limit: parseInt(model?.maxResortsCount),
          offset: 0,
          version: 'V1',
          search: {
            keyword: '*:*',
            facets: {
              terms: filters,
            },
          },
        };
      } else {
        partialRequestVariables = {
          limit: parseInt(model?.maxResortsCount),
          offset: offsetNumber, //offset,
          version: 'V1',
          search: {
            keyword: '*:*',
            facets: {
              terms: filters,
            },
          },
        };
      }
    }
    if (queryComplete === 0) {
      partialRequestVariables.offset = offsetNumber;
    }

    const resortObj: any = {};
    resortObj[resortKeys] = totalResortCount.totalCount;

    if (typeof window !== 'undefined' && totalResortCount.totalCount !== 0) {
      const resortObject: any = localStorage?.getItem('resortObject');
      const parsedResortObject: any = JSON.parse(resortObject);
      const newObj = {
        ...parsedResortObject,
        ...resortObj,
      };
      localStorage.setItem('resortObject', JSON.stringify(newObj));
    }
    setBaseVariables(partialRequestVariables);
  }, [model?.destinationMetaData]);

  useEffect(() => {
    Object.keys(baseRequestVariables)?.length &&
      getResortsData({
        variables: {
          ...baseRequestVariables,
        },
      });
  }, [baseRequestVariables]);

  const processDataOnComplete = (queryData: any) => {
    setQueryCompleted(1);
    setIsLoading(false);
    const mappedUxlData = Enable_DTT_queries ? processUxlDataDTT(queryData) : processUxlData(queryData);
    processleisureCarousel(mappedUxlData);
    if (isAuthorMode && !isWcmModeDisabledFlag) {
      setIsLoading(false);
      const mappedUxlData = Enable_DTT_queries
        ? processUxlDataDTT(CarouselUXLMockJsonDTT)
        : processUxlData(CarouselUXLMockJson);
      processleisureCarousel(mappedUxlData);
    }
  };
  const handleOnError = () => {
    setIsLoading(true);
  };

  const [getResortsData, { data: _queryData }] = useLazyQuery(
    (latitude && longitude && !isStateEnabled && !isCountryEnabled) || isPOI
      ? Enable_DTT_queries
        ? phoenixLeisureSearchByGeolocation
        : phoenixStorefrontDestinationProperties
      : isStateEnabled || isCountryEnabled
      ? Enable_DTT_queries
        ? phoenixLeisureSearchByLocation
        : PhoenixSearchPropertiesByArea
      : Enable_DTT_queries
      ? phoenixLeisureSearchByKeyword
      : phoenixResortsCarousel,
    {
      fetchPolicy: isServer ? 'network-only' : 'cache-first',
      errorPolicy: RESORT_CAROUSEL_ERROR_POLICY,
      onCompleted: processDataOnComplete,
      context: {
        headers: {
          'x-request-id': requestId,
          'accept-language': acceptLang,
          ...(envVarsObject?.['NEXT_PUBLIC_UAT_AUTH_TOKEN'] &&
            isWcmModeDisabledFlag && { Authorization: envVarsObject?.['NEXT_PUBLIC_UAT_AUTH_TOKEN'] }),
        },
      },
      onError: handleOnError,
    }
  );

  function generateOffsetNumber(): number {
    const cardLimit = parseInt(model?.maxResortsCount);
    let newOffsetCount = 0;
    if (typeof window !== 'undefined' && localStorage.getItem('resortObject') != null) {
      const resortObject: any = localStorage.getItem('resortObject');
      const parsedResortObject = JSON.parse(resortObject);
      const availableResortForKey = parsedResortObject[resortKeys];
      const maxLimitOffset = availableResortForKey - cardLimit;
      if (availableResortForKey && availableResortForKey !== undefined && availableResortForKey > cardLimit) {
        newOffsetCount = Math.floor(generateRandomNumber() * (maxLimitOffset - 1 + 1)) + 1;
      }
    }
    return newOffsetCount;
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage?.getItem('resortObject')?.length && totalResortCount) {
      setOffset(generateOffsetNumber());
    }
  }, [totalResortCount]);

  const processUxlData = (uxlData: any) => {
    // Initialize defaultResorts
    let defaultResorts = [];

    // Check if uxlData is defined, otherwise assign an empty array
    const response = uxlData || [];
    const offsetValue = generateOffsetNumber();
    localStorage.setItem('filteredResortCount', JSON.stringify(offsetValue));
    // Get the filtered resort count
    const filteredResortCount =
      (isPOI || (latitude && longitude && !isStateEnabled && !isCountryEnabled)
        ? response?.searchLowestAvailableRatesByGeolocation?.total
        : response?.searchPropertiesByKeyword?.total) ?? 0;
    totalResortCount.totalCount = filteredResortCount;

    //localStorage.setItem('filteredResortCount', JSON.stringify(filteredResortCount));
    const resortsDataMap =
      (isPOI || (latitude && longitude && !isStateEnabled && !isCountryEnabled)
        ? response?.searchLowestAvailableRatesByGeolocation?.edges
        : response?.searchPropertiesByKeyword?.edges) ?? [];
    // eslint-disable-next-line prefer-const, prettier/prettier
    if (isPOI || (latitude && longitude && !isStateEnabled && !isCountryEnabled)) {
      defaultResorts = resortsDataMap
        ?.filter((edge: any) => edge?.node?.property?.basicInformation?.bookable === true)
        ?.map((item: any) => {
          // eslint-disable-next-line array-callback-return
          let propertyImage =
            cardLayeredVariation === CardLayeredVariations.Square
              ? item?.node?.property?.media?.primaryImage?.edges[0]?.node?.imageUrls?.square
              : item?.node?.property?.media?.primaryImage?.edges[0]?.node?.imageUrls?.classicVertical;
          const ctaUrl = getCtaUrl(item?.node, subDirectoryContextPath);
          // If propertyImage is not available, try to get it from brand photos
          if (!propertyImage && item?.node?.property?.basicInformation?.brand?.photos?.length > 0) {
            propertyImage =
              item?.node?.property?.basicInformation?.brand?.photos[0]?.content[0]?.url ?? OFFER_CAUROSEL_DEFAULT_IMAGE;
          }
          return {
            image: propertyImage,
            header: item?.node?.property?.basicInformation?.name,
            propertyName: item?.node?.property?.basicInformation?.name,
            ctaUrl: ctaUrl,
            newProperty: item?.node?.property?.basicInformation?.newProperty,
            isFullyRenovated: item?.node?.property?.basicInformation?.isFullyRenovated,
            isRecentlyRenovated: item?.node?.property?.basicInformation?.isRecentlyRenovated,
            isAdultsOnly: item?.node?.property?.basicInformation?.isAdultsOnly,
            hasRenovatedRooms: item?.node?.property?.basicInformation?.hasRenovatedRooms,
            country: item?.node?.property?.contactInformation?.address?.country?.description, //mapping with description as label is coming null in data
          };
        });
      return { resortsData: shuffleResorts(defaultResorts) };
    } else {
      defaultResorts = resortsDataMap
        ?.filter((edge: any) => edge?.node?.basicInformation?.bookable === true)
        ?.map((item: any) => {
          // eslint-disable-next-line array-callback-return
          let propertyImage =
            cardLayeredVariation === CardLayeredVariations.Square
              ? item?.node?.media?.primaryImage?.edges[0]?.node?.imageUrls?.square
              : item?.node?.media?.primaryImage?.edges[0]?.node?.imageUrls?.classicVertical;
          const ctaUrl = getCtaUrl(item?.node, subDirectoryContextPath);
          // If propertyImage is not available, try to get it from brand photos
          if (!propertyImage && item?.node?.basicInformation?.brand?.photos?.length > 0) {
            propertyImage =
              item?.node?.basicInformation?.brand?.photos[0]?.content[0]?.url ?? OFFER_CAUROSEL_DEFAULT_IMAGE;
          }
          return {
            image: propertyImage,
            header: item?.node?.basicInformation?.name,
            propertyName: item?.node?.basicInformation?.name,
            ctaUrl: ctaUrl,
            newProperty: item?.node?.basicInformation?.newProperty,
            isFullyRenovated: item?.node?.basicInformation?.isFullyRenovated,
            isRecentlyRenovated: item?.node?.basicInformation?.isRecentlyRenovated,
            isAdultsOnly: item?.node?.basicInformation?.isAdultsOnly,
            hasRenovatedRooms: item?.node?.basicInformation?.hasRenovatedRooms,
            country: item?.node?.contactInformation?.address?.country?.description, //mapping with description as label is coming null in data
          };
        });
      return { resortsData: shuffleResorts(defaultResorts) };
    }
  };
  const processUxlDataDTT = (uxlData: any) => {
    // Initialize defaultResorts
    let defaultResorts = [];

    // Check if uxlData is defined, otherwise assign an empty array
    const response = uxlData || [];
    const offsetValue = generateOffsetNumber();
    localStorage.setItem('filteredResortCount', JSON.stringify(offsetValue));
    // Get the filtered resort count
    const filteredResortCount =
      (isPOI || (latitude && longitude && !isStateEnabled && !isCountryEnabled)
        ? response?.search?.properties?.searchByGeolocation?.total
        : isStateEnabled || isCountryEnabled
        ? response?.search?.properties?.searchByLocation?.total
        : response?.search?.properties?.searchByKeyword?.total) ?? 0;
    totalResortCount.totalCount = filteredResortCount;

    //localStorage.setItem('filteredResortCount', JSON.stringify(filteredResortCount));
    const resortsDataMap =
      (isPOI || (latitude && longitude && !isStateEnabled && !isCountryEnabled)
        ? response?.search?.properties?.searchByGeolocation?.edges
        : isStateEnabled || isCountryEnabled
        ? response?.search?.properties?.searchByLocation?.edges
        : response?.search?.properties?.searchByKeyword?.edges) ?? [];
    // eslint-disable-next-line prefer-const, prettier/prettier
    if (isPOI || (latitude && longitude && !isStateEnabled && !isCountryEnabled)) {
      defaultResorts = resortsDataMap
        ?.filter((edge: any) => edge?.node?.basicInformation?.bookable === true)
        ?.map((item: any) => {
          // eslint-disable-next-line array-callback-return
          let propertyImage =
            cardLayeredVariation === CardLayeredVariations.Square
              ? item?.node?.media?.primaryImage?.edges[0]?.node?.imageUrls?.square
              : item?.node?.media?.primaryImage?.edges[0]?.node?.imageUrls?.classicVertical;
          const ctaUrl = getCtaUrl(item?.node, subDirectoryContextPath);

          // If propertyImage is not available, try to get it from brand photos
          if (!propertyImage && item?.node?.basicInformation?.brand?.photos?.length > 0) {
            propertyImage =
              item?.node?.basicInformation?.brand?.photos[0]?.content[0]?.url ?? OFFER_CAUROSEL_DEFAULT_IMAGE;
          }
          return {
            image: propertyImage,
            header: item?.node?.basicInformation?.name,
            propertyName: item?.node?.basicInformation?.name,
            ctaUrl: ctaUrl,
            newProperty: item?.node?.basicInformation?.newProperty,
            isFullyRenovated: item?.node?.basicInformation?.isFullyRenovated,
            isRecentlyRenovated: item?.node?.basicInformation?.isRecentlyRenovated,
            isAdultsOnly: item?.node?.basicInformation?.isAdultsOnly,
            hasRenovatedRooms: item?.node?.basicInformation?.hasRenovatedRooms,
            country: item?.node?.contactInformation?.address?.country?.description, //mapping with description as label is coming null in data
          };
        });
      return { resortsData: shuffleResorts(defaultResorts) };
    } else {
      defaultResorts = resortsDataMap
        ?.filter((edge: any) => edge?.node?.basicInformation?.bookable === true)
        ?.map((item: any) => {
          // eslint-disable-next-line array-callback-return
          let propertyImage =
            cardLayeredVariation === CardLayeredVariations.Square
              ? item?.node?.media?.primaryImage?.edges[0]?.node?.imageUrls?.square
              : item?.node?.media?.primaryImage?.edges[0]?.node?.imageUrls?.classicVertical;
          const ctaUrl = getCtaUrl(item?.node, subDirectoryContextPath);
          // If propertyImage is not available, try to get it from brand photos
          if (!propertyImage && item?.node?.basicInformation?.brand?.photos?.length > 0) {
            propertyImage =
              item?.node?.basicInformation?.brand?.photos[0]?.content[0]?.url ?? OFFER_CAUROSEL_DEFAULT_IMAGE;
          }
          return {
            image: propertyImage,
            header: item?.node?.basicInformation?.name,
            propertyName: item?.node?.basicInformation?.name,
            ctaUrl: ctaUrl,
            newProperty: item?.node?.basicInformation?.newProperty,
            isFullyRenovated: item?.node?.basicInformation?.isFullyRenovated,
            isRecentlyRenovated: item?.node?.basicInformation?.isRecentlyRenovated,
            isAdultsOnly: item?.node?.basicInformation?.isAdultsOnly,
            hasRenovatedRooms: item?.node?.basicInformation?.hasRenovatedRooms,
            country: item?.node?.contactInformation?.address?.country?.description, //mapping with description as label is coming null in data
          };
        });
      return { resortsData: shuffleResorts(defaultResorts) };
    }
  };

  function shuffleResorts<T>(defaultResorts: T[]): T[] {
    const shuffledResort = defaultResorts ? [...defaultResorts] : [];
    for (let i = shuffledResort.length - 1; i > 0; i--) {
      const j = Math.floor(generateRandomNumber() * (i + 1));
      [shuffledResort[i], shuffledResort[j]] = [shuffledResort[j], shuffledResort[i]];
    }
    return shuffledResort;
  }

  const processleisureCarousel = (data: any) => {
    const count = data?.resortsData?.length;
    const cardKeys = Object?.keys(data?.resortsData);
    setCardCount(count);
    setKeys(cardKeys);
    const resortMap = data?.resortsData?.map((card: any, index: number) => {
      let imageUri = '';
      let imageUriLg = '';
      if (card?.image) {
        if (card?.image?.includes('/is/image')) {
          imageUri = !isAuthorMode ? `${card?.image}?wid=364&fit=constrain` : `${card?.image}`;
          imageUriLg = !isAuthorMode ? `${card?.image}?wid=800&fit=constrain` : `${card?.image}`;
        } else if (card?.image?.includes('marriottassets') || card?.image?.includes('/content/dam')) {
          imageUri = `${card.image}?downsize=365px:*`;
          imageUriLg = `${card.image}?downsize=800px:*`;
        }
        if (!imageUri.startsWith(IMAGE_CACHE_DOMAIN)) {
          imageUri = IMAGE_CACHE_DOMAIN + imageUri;
          imageUriLg = IMAGE_CACHE_DOMAIN + imageUriLg;
        }
      }
      const cardLocDetails = model?.trackingProperties?.location
        ? `${model.trackingProperties.location}-card-${index}`
        : `card-${index}`;
      const eyeBrowText = card?.country;
      const badgeText =
        card?.newProperty === true
          ? model?.newHotelLabel
          : card?.isFullyRenovated === true
          ? model?.fullyRenovatedLabel
          : card?.isRecentlyRenovated === true
          ? model?.recentlyRenovatedLabel
          : card?.isAdultsOnly === true
          ? model?.adultsOnlyLabel
          : card?.hasRenovatedRooms === true
          ? model?.renovatedRoomsLabel
          : null;

      return {
        ':items': {
          [`card-carousel-${index}`]: {
            trackingProperties: {
              atCCeVar48: null,
              additionalTrackingVariables: null,
              trackingDescription: card?.header,
              description: card?.header,
              clickTrack: model?.trackingProperties?.clickTrack ? true : false,
              impressionTrack: false,
              location: cardLocDetails,
              trackingContentPosition: cardLocDetails,
              merchandisingCategory: 'nonCobrand',
              impressionCount: false,
              trackingTag: model?.trackingProperties?.trackingTag,
              trackingResortType: null,
              cardLocation: model?.trackingProperties?.cardLocation,
              leftArrowDesc: model?.trackingProperties?.leftArrowDesc,
              rightArrowDesc: model?.trackingProperties?.rightArrowDesc,
            },
            eyebrow: eyeBrowText,
            styleclass: model?.cardTheme,
            headerTag: 't-subtitle-l',
            icon: '',
            impressionTrack: IMPRESSION_TRACK,
            cardLayeredVariations: cardLayeredVariation,
            badgeIcon: '',
            imgAltText: IMAGE_ALT_TEXT,
            clickTrack: model?.trackingProperties?.clickTrack ? true : false,
            fileReferenceImage: imageUriLg,
            header: card.header,
            fontSize: 'h3',
            ':type': 'mi-aem-common-spa/components/content/cardlayered',
            [`${cardLayeredVariation === CardLayeredVariations.Square ? 'dynamicMedia' : 'dynamicMediaTall'}`]: {
              altText: IMAGE_ALT_TEXT,
              assetPath: imageUriLg,
              dynamic: true,
              renditions: [
                {
                  renditionPath: imageUriLg,
                  mediaValue: '768px',
                  width: 0,
                  dynamic: true,
                  damPath: imageUriLg,
                  mediaQuery: 'min-width',
                  height: 0,
                },
                {
                  renditionPath: imageUri,
                  mediaValue: '576px',
                  width: 0,
                  dynamic: true,
                  damPath: imageUri,
                  mediaQuery: 'min-width',
                  height: 0,
                },
                {
                  renditionPath: imageUri,
                  mediaValue: '576px',
                  width: 0,
                  dynamic: true,
                  damPath: imageUri,
                  mediaQuery: 'max-width',
                  height: 0,
                },
              ],
              damPath: card?.image,
            },
            merchandisingCategory: MERCHANDISING_CATEGORY,
            impressionCount: IMPRESSION_TRACK,
            ctaLink: card?.ctaUrl,
            badgeText: badgeText,
            openInNewTab: true,
          },
        },
      };
    });
    setResultResort(resortMap);
  };

  const allowedComponents = [
    {
      path: '/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/cardlayered',
      title: 'Card Layered',
    },
  ];
  const trackingProperties = {
    trackingContentPosition: 'trackingContentPosition',
    trackingOfferType: 'trackingOfferType',
    atCCeVar48: 'atCCeVar48',
    trackingDescription: model?.trackingProperties?.trackingDescription,
    trackingTag: 'trackingTag',
    isCoBrand: false,
    clickTrack: model?.trackingProperties?.clickTrack,
    impressionTrack: true,
    impressionCount: true,
    impressionEventType: 'impressionEventType',
    merchandisingCategory: 'merchandisingCategory',
    additionalTrackingVariables: 'additionalTrackingVariables',
    location: model?.trackingProperties?.location,
    payloadType: 'payloadType',
    compName: 'compName',
    enableScrollingBehavior: model?.trackingProperties?.enableScrollingBehavior,
    cardLocation: model?.id ?? model?.trackingProperties?.cardLocation,
    leftArrowDesc: model?.trackingProperties?.leftArrowDesc,
    rightArrowDesc: model?.trackingProperties?.rightArrowDesc,
    description: model?.ctaLabel ?? model?.trackingProperties?.description,
  };

  return (
    <StyledContainer>
      <div>
        {!isLoading && resortResult?.length ? (
          <div className={clsx(cardCount > 0 ? '' : 'hideCarousel', 'container cardWrapper')}>
            <CardCarouselContainer
              headerText={model?.titleText}
              subHeaderText={model?.subTitleText}
              noOfCards={model?.noOfCards}
              ctaLabel={model?.ctaLabel}
              ctaLink={model?.ctaLink}
              ctaType={model?.ctaType}
              cardCount={cardCount}
              openInaNewTab={openInaNewTab}
              totalNumberOfCards={keys}
              trackingProperties={trackingProperties}
              styleclass={model?.styleclass}
              cqItems={resortResult}
              componentId={model?.componentId}
              pagePath={''}
              itemPath={''}
              wcmMode={model?.wcmMode}
              isAuthorMode={false}
              noOfCardsTablet={NO_OF_CARDS_TABLET}
              allowedComponents={allowedComponents}
              variation={model?.variation}
              isControlType2={model?.styleclass?.includes('phoenix-luxury') && true}
            />
          </div>
        ) : (
          <div>
            <SkeletonCardCarouselLoader cardType={cardLayeredVariation} />
          </div>
        )}
      </div>
    </StyledContainer>
  );
};
export default CardResortsWrapper;
