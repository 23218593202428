// Styles for Breadcrumb go here.
import styled from 'styled-components';

export const StyledBreadcrumb = styled.div`
  overflow: hidden;

  .cl__list-item:last-child {
    a {
      cursor: default;
      text-decoration: none;

      .icon-arrow-right {
        display: none;
      }
    }
  }
`;
