import { createContext, useContext, useEffect, useState } from 'react';
import { getDatalayerObject } from '../dataLayer/datalayerUtil';
import { useUserSessionStore, UserSessionState, UserSessionActions } from './user-session-store';
import { logger } from '../logger';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { GlobalPageProps, SessionData } from './types';
import { isEmpty } from '@marriott/shared/mi-helper-utils';
import { getMvpOffersData } from '../mvpOffers/mvpOffersUtils';

declare global {
  interface Window {
    adobeDataLayer: {
      [key: number]: {
        event: string;
        data: Record<string, unknown>;
      };
      version: number;
      push: (event: { event: string; data: Record<string, unknown> }) => void;
      getState: () => Record<string, unknown>;
      addEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
      removeEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
    };
  }
}

type UserSessionProvider = {
  pageProps: GlobalPageProps; // @fixme: this is way too much stuff to pass around
  includePiData?: boolean; // flag to check if PI data needs to be included in datalayer or not
  /* Children to render */
  children: React.ReactNode;
};

export type DataLayerEvent = {
  event: string;
  data: Record<string, unknown>;
};

export type MvpOffersData = {
  specialMessage?: string;
  offerCode?: string;
  eventInfo?: string;
  placementGroup?: string;
  rpcCode?: string;
  offerIdentifier?: string;
  source?: string;
};

export type UserSessionContextType = {
  session: SessionData;
  mvpOffers?: MvpOffersData;
  datalayer?: Record<string, unknown>;
  updateSession: (sessionData: SessionData) => void;
  updateDatalayer: (eventData: DataLayerEvent) => void;
  getDatalayer: () => Record<string, unknown> | null;
  syncedWithServer: boolean;
};

const UserSessionContext = createContext<UserSessionContextType>({
  session: {},
  updateSession: () => {},
  updateDatalayer: () => {},
  getDatalayer: () => null,
  syncedWithServer: false,
});

UserSessionContext.displayName = 'UserSessionContext';

/**
 * Provider to manage user session data and sync with server on initial load and updates.
 */
export function UserSessionProvider({
  pageProps,
  includePiData = false,
  children,
}: React.PropsWithChildren<UserSessionProvider>) {
  const { log } = logger({})('UserSessionProvider');
  const [datalayer, setDatalayer] = useState<Record<string, unknown>>();
  const [mvpOffers, setMvpOffers] = useState<MvpOffersData>();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const session = useUserSessionStore(state => state.session);
  const updateSessionStore = useUserSessionStore(state => state.updateSession);
  const syncedWithServer = useUserSessionStore(
    (state: UserSessionState & UserSessionActions) => state.syncedWithServer
  );

  const envVarsObject = useClientEnvVarsStore(state => state.envVarsObject);

  const updateDatalayer = (payload: DataLayerEvent) => {
    log.info('pushing to adobeDataLayer', payload);
    window.adobeDataLayer?.push(payload);
    log.info('updating the session datalayer', payload);
    setDatalayer(state => ({ ...state, ...payload.data }));
  };

  const getDatalayer = () => {
    return window.adobeDataLayer?.getState();
  };

  const updateSession = (sessionData: SessionData) => {
    updateDatalayer({
      event: 'dataLayerUpdated',
      data: getDatalayerObject(sessionData, pageProps, includePiData),
    });
    updateSessionStore(sessionData);
  };

  /**
   * Load datalayer data from window object if available.
   */
  useEffect(() => {
    // @todo: for backward compatibility support, should be removed once all apps are using web sdk
    if (!isEmpty(window.dataLayer)) {
      log.info('loading datalayer data from window', window.dataLayer);
      setDatalayer(window.dataLayer);
    }
  }, []);

  /**
   * Load MVP offers data from service or window object if available.
   */
  useEffect(() => {
    if (isEmpty(envVarsObject)) {
      return;
    }
    // @todo: for backward compatibility support, should be removed once all apps have enabled prequal call
    if (!isEmpty(window.mvpOffers)) {
      log.info('loading mvp offers data from window', window.mvpOffers);
      setMvpOffers(window.mvpOffers);
      setIsCompleted(true);
      return;
    }
    if (!envVarsObject['NEXT_PUBLIC_ENABLE_WEBSDK'] || String(pageProps?.model?.enableWebSDK) !== 'true') {
      log.info('web sdk is not enabled');
      setIsCompleted(true);
      return;
    }
    if (!envVarsObject['NEXT_PUBLIC_ENABLE_PREQUAL']) {
      log.info('prequal is not enabled');
      setIsCompleted(true);
      return;
    }
    // fetch mvp offers data from service
    (async () => {
      try {
        const { resolvedUrl, currentLocale } = pageProps;
        const mvpOffersData = await getMvpOffersData(resolvedUrl, currentLocale);
        log.info('loading mvp offers data from service', mvpOffersData);
        // @todo: for backward compatibility support, should be removed once all apps are using web sdk
        window.mvpOffers = mvpOffersData;
        setMvpOffers(mvpOffersData);
      } catch (error) {
        log.error('Error loading mvp offers data from service', error);
      } finally {
        setIsCompleted(true);
      }
    })();
  }, [JSON.stringify(envVarsObject)]);

  /**
   * Initialize the dataLayer with session data and mvpOffers data.
   */
  useEffect(() => {
    const initialize = async () => {
      try {
        log.info('UserSessionProvider init!');
        updateDatalayer({
          event: 'dataLayerLoaded',
          data: getDatalayerObject(session, pageProps, includePiData),
        });
      } catch (error) {
        log.error('Error updating dataLayer', error);
      }
    };
    const isEnableWebSDK = envVarsObject?.['NEXT_PUBLIC_ENABLE_WEBSDK'];
    if (isEnableWebSDK && syncedWithServer && isCompleted) {
      initialize();
    }
  }, [syncedWithServer, isCompleted]);

  const contextValue = {
    session,
    datalayer,
    mvpOffers,
    updateSession,
    updateDatalayer,
    getDatalayer,
    syncedWithServer,
  };
  return <UserSessionContext.Provider value={contextValue}>{children}</UserSessionContext.Provider>;
}

export function useSession() {
  const context = useContext(UserSessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a UserSessionProvider');
  }
  return context;
}
