// Imports for external libraries go here.
import React from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { DestinationOverviewProps } from './DestinationOverview.types';
import { StyledDestinationOverview } from './DestinationOverview.styles';
import { DestinationOverviewWrapper } from './DestinationOverviewWrapper';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const DestinationOverviewWrapperConfig = {
  emptyLabel: 'DestinationOverviewWrapper',
  isEmpty: false,
  resourceType: `mi-aem-leisure-spa/components/destinationOverview`,
};

export const LeisureDestinationOverviewWrapper: React.FC<DestinationOverviewProps> = props => {
  return <DestinationOverviewWrapper {...props} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DestinationOverview = (props: any) => {
  return (
    <EditableComponent config={DestinationOverviewWrapperConfig} {...props}>
      <StyledDestinationOverview>
        <LeisureDestinationOverviewWrapper {...props} />
      </StyledDestinationOverview>
    </EditableComponent>
  );
};
