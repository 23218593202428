import styled from 'styled-components';

export const StyledTag = styled.div`
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  a:has(span.m-link-action),
  a:has(span.m-link-tertiary-button-external) {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  span.m-link-action a,
  span.m-link-tertiary-button-external a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  .m-link-action {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    border-bottom-color: inherit;
    &:hover {
      font-weight: inherit;
    }
  }
  .m-link-tertiary-button-external {
    width: unset !important;
    overflow: visible !important;
    font-size: inherit;
  }
`;
