// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { StyledOverlayProps } from './PropertyCard';
import { theme } from '@marriott/mi-ui-library-shop';

export const StyledPropertyCard = styled.div<{ variation: string }>`
  background-color: ${baseVariables.color.base20};
  box-shadow: rgba(0, 0, 0, 0.07) 0 ${toRem(4)} ${toRem(20)} 0;
  border-radius: ${toRem(14)};
  overflow: hidden;
  height: ${props => (props?.variation === 'culinaryView' ? `${toRem(488)}` : `${toRem(416)};`)};

  .amount-display {
    &:dir(rtl) {
      display: flex;
      align-items: baseline;
      float: right;
      flex-direction: row;
    }
  }

  .property-card__details-container {
    width: 100%;
    &.verticalCardHeight {
      height: inherit;
    }
    &_footer_link {
      min-width: ${toRem(128)};
    }
    .hide-price {
      display: none;
    }
  }
  .icon-decorative {
    font-size: ${toRem(14)};
  }
  .property-card__img-container {
    width: 100%;
    height: ${props => (props?.variation === 'culinaryView' ? `${toRem(228)}` : ` ${toRem(221)}`)};
    position: relative;
    &--img {
      height: ${props => (props?.variation === 'culinaryView' ? `${toRem(228)}` : ` ${toRem(221)}`)};
      width: 100%;
    }
    &--ribbon {
      .ribbons-container {
        position: absolute;
        min-width: ${toRem(200)};
        .logo-container {
          font-size: ${toRem(18)};
        }
        .ribbon {
          cursor: default;
          z-index: 1;
          background-color: ${baseVariables.color['merch60']};
          border-radius: ${toRem(4)};
          position: absolute;
          left: ${toRem(17)};
          top: ${toRem(17)};
          padding: ${toRem(6)} ${toRem(4)} ${toRem(4)} ${toRem(6)};
          font-weight: ${baseVariables.font['fontWeightMedium']};
          letter-spacing: ${toRem(1.3)};
        }
      }
    }
    &--favorite {
      z-index: 1;
      cursor: pointer;
      height: ${toRem(32)};
      width: ${toRem(32)};
      border-radius: ${toRem(4)};
      position: absolute;
      right: ${toRem(15)};
      top: ${toRem(15)};
      background-color: rgba(28, 28, 28, 0.54);
    }
  }
  .property-card__img-container--logo {
    position: absolute;
    z-index: 2;
    bottom: ${toRem(16)};
    left: ${toRem(16)};
    width: ${toRem(124)};
    min-height: ${toRem(60)};
    background-color: transparent;
    border-radius: ${toRem(4)};
    .brand-logo-container {
      background: transparent;
    }
    .brand-logo-SH::before {
      font-size: ${toRem(35)};
    }
    .brand-logo-GE::before {
      font-size: ${toRem(39)};
    }
    .brand-logo-AR::before {
      font-size: ${toRem(45)};
    }
    .brand-logo-AL::before {
      font-size: ${toRem(39)};
    }
    .brand-logo-AK::before {
      font-size: ${toRem(35)};
    }
    .brand-logo-CY::before {
      font-size: ${toRem(15)};
    }
    .brand-logo-DE::before {
      font-size: ${toRem(46)};
    }
    .brand-logo-DS::before {
      font-size: ${toRem(11)};
    }
    .brand-logo-BG::before {
      font-size: ${toRem(24)};
    }
    .brand-logo-EB::before {
      font-size: ${toRem(16)};
    }
    .brand-logo-EL::before {
      font-size: ${toRem(40)};
    }
    .brand-logo-FI::before {
      font-size: ${toRem(19)};
    }
    .brand-logo-FP::before {
      font-size: ${toRem(33)};
    }
    .brand-logo-JW::before {
      font-size: ${toRem(37)};
    }
    .brand-logo-MD::before {
      font-size: ${toRem(16)};
    }
    .brand-logo-ER::before {
      font-size: ${toRem(40)};
    }
    .brand-logo-MI::before {
      font-size: ${toRem(33)};
    }
    .brand-logo-MV::before {
      font-size: ${toRem(39)};
    }
    .brand-logo-MC::before {
      font-size: ${toRem(33)};
    }
    .brand-logo-OX::before {
      font-size: ${toRem(27)};
    }
    .brand-logo-PR::before {
      font-size: ${toRem(40)};
    }
    .brand-logo-BR::before {
      font-size: ${toRem(42)};
    }
    .brand-logo-RI::before {
      font-size: ${toRem(20)};
    }
    .brand-logo-SI::before {
      font-size: ${toRem(40)};
    }
    .brand-logo-XR::before {
      font-size: ${toRem(38)};
    }
    .brand-logo-LC::before {
      font-size: ${toRem(43)};
    }
    .brand-logo-RZ::before {
      font-size: ${toRem(40)};
    }
    .brand-logo-TS::before {
      font-size: ${toRem(32)};
    }
    .brand-logo-TX::before {
      font-size: ${toRem(20)};
    }
    .brand-logo-WH::before {
      font-size: ${toRem(18)};
    }
    .brand-logo-WI::before {
      font-size: ${toRem(20)};
    }
    .brand-logo-XE::before {
      font-size: ${toRem(29)};
    }
    .brand-logo-XF::before {
      font-size: ${toRem(32)};
    }
    .brand-logo-SN::before {
      font-size: ${toRem(50)};
    }
    .brand-logo-RS::before {
      font-size: ${toRem(20)};
    }
  }

  @media ${baseVariables.mediaQuery.md} {
    height: ${props => (props?.variation === 'culinaryView' ? `${toRem(365)}` : `${toRem(288)}`)};
    .property-card__img-container,
    .property-card__img-container--img {
      width: ${props => (props?.variation === 'culinaryView' ? `${toRem(360)}` : `${toRem(320)}`)};
      height: ${props => (props?.variation === 'culinaryView' ? `${toRem(365)}` : `${toRem(288)}`)};
    }
    .property-card__img-container--scrim-component {
      width: ${toRem(344)};
    }
  }

  @media ${baseVariables.mediaQuery.lg} {
    height: ${props => (props?.variation === 'culinaryView' ? `${toRem(389)} !important` : `${toRem(338)}`)};
    .property-card__img-container,
    .property-card__img-container--img {
      width: ${props => (props?.variation === 'culinaryView' ? `${toRem(571)} !important` : `${toRem(504)}`)};
      height: ${props => (props?.variation === 'culinaryView' ? `${toRem(389)} !important` : `${toRem(338)}`)};
    }
    .property-card__img-container--scrim-component {
      width: ${toRem(364)};
    }
    .icon-decorative {
      font-size: ${toRem(18)};
      line-height: ${toRem(20)};
    }
  }

  @media ${baseVariables.mediaQuery.xl} {
    height: ${props => (props?.variation === 'culinaryView' ? `${toRem(370)}` : ` ${toRem(298)}`)};
    .property-card__img-container,
    .property-card__img-container--img {
      width: ${props => (props?.variation === 'culinaryView' ? `${toRem(658)} !important` : `${toRem(504)}`)};
      height: ${props => (props?.variation === 'culinaryView' ? `${toRem(370)}` : ` ${toRem(298)}`)};
    }
  }

  .property-card__img-container--scrim-component {
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 45%, rgba(0, 0, 0, 0.52) 100%);
    position: absolute;
    bottom: 0px;
    width: inherit;
    cursor: default;
    height: ${toRem(120)};
  }
  .property-card__details-container_top,
  .property-card__details-container_bottom {
    width: 100%;
  }
  .property-card__tertiary-link,
  .property-card__title button {
    background: transparent;
    color: ${baseVariables.color.neutral00};
  }
  .property-card__reviews-miles {
    color: ${baseVariables.color['neutral40']};
  }
  .ratings-container:hover {
    border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral40']};
    margin-bottom: ${toRem(-1)};
  }
  .separator {
    border-top: ${props =>
      props?.variation === 'culinaryView'
        ? `${toRem(1)} solid ${baseVariables.color['neutral40']}`
        : `${toRem(1)} solid ${baseVariables.color['neutral20']}`};
  }
  .glide {
    @media ${baseVariables.mediaQuery.mobile}, @media ${baseVariables.mediaQuery.desktop} {
      max-width: 100%;
    }

    @media ${baseVariables.mediaQuery.largeDesktop} {
      max-width: ${props => (props?.variation === 'culinaryView' ? `100%` : `${toRem(504)}`)};
    }
  }

  .glide-bullet-container {
    position: absolute;
    right: ${toRem(16)};
    bottom: ${toRem(8)};
    height: ${toRem(32)};
    min-width: ${toRem(94)};
    border-radius: ${toRem(22)};
    background-color: rgba(0, 0, 0, 0.33);
    justify-content: space-evenly;

    &.center {
      left: 0;
      right: 0;
      width: fit-content;
      margin: auto;
    }
  }

  .glide__bullet {
    border-radius: 50%;
    background-color: ${baseVariables.color['base20']};
    height: ${toRem(6)};
    width: ${toRem(6)};
    opacity: 0.34;
    outline: none;
    pointer-events: none;
    cursor: default;

    &--active {
      opacity: 1;
      height: ${toRem(8)};
      width: ${toRem(8)};
    }
  }

  .glide__arrow {
    position: absolute;
    top: 45%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.54);
    width: ${toRem(40)};
    height: ${toRem(40)};
    border-radius: 50%;
    border-color: transparent;
    font-size: ${toRem(24)};
    color: ${baseVariables.color['base20']};
    box-shadow: 0 ${toRem(4)} ${toRem(8)} 0 rgba(0, 0, 0, 0.1);
    &:active,
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .glide__arrows > .right-arrow {
    right: ${toRem(16)};
  }

  .glide__arrows > .left-arrow {
    left: ${toRem(16)};
  }

  .glide__arrows > .right-arrow,
  .glide__arrows > .left-arrow {
    .icon-arrow-left:before,
    .icon-arrow-right:before {
      position: absolute;
      top: 20%;
      left: 20%;
      right: 50%;
      bottom: 50%;
    }
  }

  .glide__slides {
    .glide__slide {
      width: 100%;
      list-style: none;
    }
  }

  .glide__track {
    position: relative;
    overflow: hidden;
  }

  .glide__arrow--disabled {
    opacity: unset;
  }

  .property-card__eyebrow {
    padding-top: ${props => (props?.variation === 'culinaryView' ? `${toRem(8)}` : `unset`)};
    @media ${baseVariables.mediaQuery.md} {
      padding-top: ${props => (props?.variation === 'culinaryView' ? `0` : `unset`)};
    }
  }

  .reserve-culinary-link {
    color: ${baseVariables.color.base20};
    margin-left: ${toRem(16)} !important;
    @media ${baseVariables.mediaQuery.md} {
      margin-left: ${toRem(48)} !important;
    }
  }

  .culinary-view-separator {
    margin-top: ${toRem(48)};
  }
  .mandatory-fee-section {
    font-size: ${toRem(12)};
    margin-top: ${toRem(-8)};
    line-height: ${toRem(16)};
    color: ${baseVariables?.color?.neutral40};
    padding-right: ${toRem(2)};
    .custom-info-list {
      color: ${baseVariables?.color?.neutral40};
    }
  }

  .custom-header-font h4 {
    font-size: ${toRem(22)};
    font-weight: ${theme.fonts['fontWeightMedium']};
    letter-spacing: 0;
    line-height: ${toRem(26)};
  }
  .custom-header-font {
    padding-top: ${toRem(32)};
    padding-bottom: ${toRem(32)};
  }
  .popup-close-button {
    background-color: ${theme.color['gallery']};
    font-size: large;
  }
  .custom-position {
    top: unset;
    margin: 0 auto;
  }
  .price-value {
    justify-content: end !important;
  }
  .includes-fee {
    display: flex;
    justify-content: flex-end;
  }
  .book-button {
    min-width: fit-content;
  }
  .tooltip-popup-wrapper {
    max-height: ${toRem(513)};
    min-height: ${toRem(290)};
  }
  .custom-info-map {
    font-size: ${toRem(12)};
    display: flex;
    justify-content: flex-end;
    padding: 0 !important;
    pointer-events: none;
    color: inherit;
  }
  .m-link-icon-button-info:after {
    padding-left: ${toRem(2.8)};
    padding-right: 0 !important;
  }
  .m-link-icon-button-info:hover {
    background-color: transparent !important ;
    cursor: pointer !important ;
  }

  .remove-info-icon: {
    :after {
      content: none !important;
    }
  }
  .custom-info-list {
    pointer-events: none;
    margin-left: ${toRem(-8)} !important;
  }
  .custom-info-list:after {
    pointer-events: all;
    height: ${toRem(13.33)};
    width: ${toRem(13.33)};
  }
  .custom-info-map:after {
    height: ${toRem(13.33)};
    width: ${toRem(13.33)};
    pointer-events: all;
  }
  .tooltip-main-wrapper {
    height: ${toRem(200)};
    font-size: ${toRem(16)};
    font-family: ${theme.fonts['fontFamilySwiss']};
    letter-spacing: 0;
    line-height: ${toRem(20)};
    color: ${theme.color['dark-gray-14']};
    font-weight: ${theme.fonts['fontWeightMedium']};
  }
  .tooltip-main-content {
    // margin:${toRem(15)} 0 0 0;
    font-size: ${toRem(12)};
    letter-spacing: 0;
    font-family: ${theme.fonts['fontFamilySwiss']};
    line-height: ${toRem(18)};
    font-weight: ${theme.fonts['fontWeightRegular']};
    padding-top: ${toRem(16)};
    @media only ${theme.mediaQuery.allTablets} {
      padding-top: ${toRem(12)};
    }
  }
  .custom-list {
    //padding-left:${toRem(10)};
    width: 100%;
    font-family: ${theme.fonts['fontFamilySwiss']};
    padding-right: ${toRem(30)};
    font-weight: ${theme.fonts['fontWeightRegular']};
  }
  .custom-ul-props {
    column-count: 1;
    margin-inline-start: ${toRem(-10)} !important;
    margin-inline-end: ${toRem(-30)} !important;
  }
  .mandatory-fee-section-list-view {
    font-size: ${toRem(12)};
    line-height: ${toRem(16)};
    color: ${baseVariables?.color?.neutral40};
  }
  .mandatory-fee-section-list-view-sm {
    font-size: ${toRem(10)};
    line-height: ${toRem(16)};
    color: ${baseVariables?.color?.neutral40};
  }
  .custom-info-map-sm {
    font-size: ${toRem(10)};
    display: flex;
    justify-content: flex-end;
    padding: 0 !important;
    color: inherit;
  }
  .tooltip-outer-container .tooltip-container {
    position: relative !important;
  }
  .view-rates-button-container {
    margin-left: ${toRem(24)};
  }
  .rate-container {
    justify-content: flex-end !important;
  }
  .property-card__details-container_desc {
    display: ${props => (props?.variation === 'culinaryView' ? '-webkit-box' : 'block')};
    -webkit-line-clamp: ${props => (props?.variation === 'culinaryView' ? '2' : 'unset')};
    -webkit-box-orient: ${props => (props?.variation === 'culinaryView' ? 'vertical' : 'unset')};
    overflow: ${props => (props?.variation === 'culinaryView' ? 'hidden' : 'unset')};

    @media ${baseVariables.mediaQuery.md} {
      -webkit-line-clamp: ${props => (props?.variation === 'culinaryView' ? '3 !important' : 'unset')};
    }
  }

  .property-card {
    &__skeleton {
      &__desc {
        width: ${toRem(280)};
        height: ${toRem(24)};
      }
      &__label {
        width: ${toRem(144)};
        height: ${toRem(24)};
      }
      &__btn {
        width: ${toRem(144)};
        height: ${toRem(34)};
      }
      &__img {
        width: ${toRem(504)};
        height: ${props => (props?.variation === 'culinaryView' ? `${toRem(228)}` : ` ${toRem(221)}`)};
      }
    }
  }

  &.mobile-card {
    max-width: ${toRem(400)};
    height: auto;

    .property-card__img-container {
      width: 100%;
    }
  }
`;

export const StyledOverlayContainer = styled.div<StyledOverlayProps>`
  position: relative;
  .overlay-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${props => (props.iconPath ? '100%' : '120%')};
    width: 100%;
    opacity: ${props => (props.iconPath ? '0.7' : 'initial')};
    background: ${props => (props.iconPath ? `${baseVariables.color.base10}` : 'none')};

    .icon-dining {
      font-size: ${toRem(24)};
      line-height: ${toRem(28)};
      border-bottom: ${toRem(5)} solid #ff8d6b;
      padding-bottom: ${toRem(12)};
      @media ${baseVariables.mediaQuery.md && baseVariables.mediaQuery.lg} {
        font-size: ${toRem(32)};
        line-height: ${toRem(36)};
        border-bottom: ${toRem(7)} solid #ff8d6b;
        padding-bottom: ${toRem(16)};
      }
    }
  }
`;
