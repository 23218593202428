import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { mediaQuery } from '../../../utils/styles';

export const StyledSearchAllFilters = styled.div`
  &.offer-filters {
    .brand-filter__category_label {
      span.t-label-xs {
        text-transform: uppercase;
        letter-spacing: ${toRem(1.3)};
      }
    }
    .filter-btns-wrapper {
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    .filter-btns-wrapper::-webkit-scrollbar {
      display: none;
    }
    .filter-option-button {
      font-weight: 500;
      margin: 0 ${toRem(8)} 0 0;
      border: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-radius: ${toRem(8)};
      background-color: ${baseVariables.color.base20};
      padding: ${toRem(8)} ${toRem(12)};
      width: max-content;
      display: flex;
      height: ${toRem(38)};
    }
    .all-filters.custom-scrollbar {
      padding: 0 ${toRem(16)} 0 ${toRem(32)};
    }
    .brand-logo-container {
      margin-right: ${toRem(12)};
    }
    .brand-filter.px-2 {
      padding: 0 !important;
    }
    .category-filter-modal {
      .brand-filter .brand-filter__title {
        display: none;
      }
      .all-filters.custom-scrollbar {
        padding: ${toRem(36)} ${toRem(16)} 0 ${toRem(32)};
      }
    }
    .icon-dining-filter {
      margin: 0 ${toRem(5)} 0 0;
    }
    .all-filter-badge {
      height: ${toRem(20)};
      border-radius: 50%;
      background-color: ${baseVariables.color.base10};
      display: flex;
      -webkit-box-pack: center;
      place-content: center;
      -webkit-box-align: center;
      align-items: center;
      text-align: center;
      color: ${baseVariables.color.base20};
      padding: ${toRem(5)};
      width: max-content;
      min-width: ${toRem(20)};
      margin-right: ${toRem(6)};
    }
    .all-filter-text {
      width: max-content;
    }
    .button.active {
      background-color: ${baseVariables.color.neutral30};
    }
    .loader-container {
      background: rgba(255, 255, 255, 0.7);
      z-index: 9998;
      width: 100%;
      position: absolute !important;
      top: 0;
      height: auto;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: ${toRem(14)};
    }
    .brand-logo-RS::before {
      font-size: ${toRem(23)};
      @media ${mediaQuery.mobileOnly} {
        font-size: ${toRem(17)};
      }
    }
  }
`;
